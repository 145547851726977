import React from "react";

import ConfigBox from "../../components/Dashboard/ConfigBox";
import { BackButton } from "../../components/AtomComponents";

import "../ConfigTenant/ConfigTenant.scss";


export function ConfigSchema() {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Schema sync";
  }, []);

  const UiBoxes = [
    {
      header: "Master DB",
      description:"Sync Schema in Master Database",
      redirect: "config-schema/master",
    },
    // {
    //   header: "Slave DB",
    //   description:"Sync Schema in Slave Database",
    //   redirect: "config-schema/slave",
    // },
  ];
  return (
    <div className="px-ConfigTenant grid-x">
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Schema Sync Tool</h3>
      </div>

      <div className="small-12 margin-top-3">
        {UiBoxes.map((Uibox) => {
          return (
            <ConfigBox
              header={Uibox.header}
              redirect={Uibox.redirect}
              description={Uibox.description}
              key={Uibox.header}
            />
          );
        })}
      </div>
    </div>
  );
}



export default ConfigSchema;
