import { GridApi, GridOptions } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import Progress from '../Progress/Progress';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from '../../_lib/utils';
import { format } from 'date-fns';
import './ReportGrid.scss';
import { TertiaryButton } from '../AtomComponents';
import { GetApp } from '@material-ui/icons';

const ReportGrid = ({ reports }: any) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      setGridAPI(params.api);
    }
  };
  useEffect(() => {
    if (gridAPI && !isEmpty(reports)) {
      const columnDefs = reports.settings.columnDefs;
      const requiredColumnDef = columnDefs.map((column: any) => {
        if (column.filter === 'agDateColumnFilter') {
          return {
            ...column,
            valueGetter: (params: any) => {
              const dateList = params.data[column.field].split('-');
              return `${dateList[2]}/${dateList[1]}/${dateList[0]}`;
            },
            valueFormatter: (params: any) => {
              return params.data[column.field];
            },
            filterParams: filterParams,
          };
        }
        if (column.valueFormatter) {
          return {
            ...column,
            valueFormatter: (params: any) => formatter(column.valueFormatter, column.field, params),
          };
        } else {
          return column;
        }
      });
      gridAPI.setColumnDefs(requiredColumnDef);
      gridAPI.setRowData(reports.body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, gridAPI]);

  let filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      let dateAsString = cellValue;
      if (dateAsString == null) return -1;
      let dateParts = dateAsString.split('/');
      let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      console.log(cellDate);
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    browserDatePicker: true
  };
  const gridOptions: GridOptions = {
    headerHeight: 32,
    defaultColDef: {
      flex: 1,
      resizable: true,
      minWidth: 120,
      wrapText: true,
      autoHeight: true,
      // menuTabs: [],
      cellStyle: {
        wordBreak: 'normal',
        height: '100%',
      },
      menuTabs: ['filterMenuTab'],
    },

    // getRowNodeId: function (data: any) {
    //   return data.tenantId;
    // },
    defaultExportParams: { fileName: `GMV_Report_${format(new Date(), 'MMddyyy')}` },
    getRowHeight: () => 54,
    animateRows: false,
    singleClickEdit: true,
    suppressAggFuncInHeader: true,
    stopEditingWhenGridLosesFocus: true,
    groupDefaultExpanded: -1,
    suppressMovableColumns: true,
    suppressCsvExport: true,
    suppressContextMenu: true,
    // grid scroll code
    debounceVerticalScrollbar: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    rowBuffer: 5,
    loadingOverlayComponentFramework: () => (
      <div className="px-grid-loader">
        <div className="px-circular-loader"></div>
        <h3 className="text-center margin-top-1">Loading...</h3>
      </div>
    ),
  };

  const formatter = (type: string, field: string, params: any) => {
    switch (type) {
      case 'numberFormatter':
        return numberFormatter(params.data[field]);
      case 'decimalFormatter':
        return numberFormatter(params.data[field], 2);
      default:
        return params.data[field];
    }
  };

  const numberFormatter = (data: number, decimalPoint?: number) => {
    if (typeof data !== 'string') {
      const curencyWith2DecPoint = data.toFixed(!decimalPoint ? 0 : decimalPoint);
      const formatted = curencyWith2DecPoint.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formatted;
    } else {
      return data;
    }
  };

  const onExport = () => {
    if (gridAPI) {
      gridAPI.exportDataAsExcel({
        fileName: `GMV_Report_${format(new Date(), 'MMddyyyy')}`,
        rowHeight: 15,
      });
    }
  };

  if (!reports || isEmpty(reports))
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );

  return (
    <div className="px-report-grid">
      <div className="export">
        <TertiaryButton className="margin-right-2" onClick={onExport}>
          <GetApp className="margin-right-1" />
          EXPORT
        </TertiaryButton>
      </div>
      <div className="ag-theme-alpine px-height-100vh margin-top-2" data-id="reportsTable">
        {!isEmpty(reports.body) ? (
          <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
        ) : (
          <>No data found</>
        )}
      </div>
    </div>
  );
};

export default ReportGrid;
