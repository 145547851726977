import React, { useEffect } from 'react';
import './AuthWrapper.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../redux/actions/userActions';
import useDataService from '../../hooks/useDataService';
import LoginFlow from './LoginFlow';
import { reactStringReplace } from '../../_lib/lib';

function Login(props: any) {
  // const [msgState, setMsgState] = React.useState<any>(null);
  const { openSnackBar } = useDataService();

  useEffect(() => {
    const customLoginLoadObj = JSON.parse(localStorage.getItem('customLoginLoadMessage') || '{}');

    if (customLoginLoadObj) {
      localStorage.removeItem('customLoginLoadMessage');

      if (Object.keys(customLoginLoadObj).length > 0) {
        if (customLoginLoadObj.messageType === 'string')
          openSnackBar(customLoginLoadObj.message, customLoginLoadObj.severity);
        else
          openSnackBar(
            reactStringReplace(
              customLoginLoadObj.message,
              (str: string, key: any) => <label key={key}>{str}</label>,
              (str: string, key: any) => (
                <a
                  key={key}
                  className="m-l-px-4 px-error-logout m-r-px-4"
                  href={props.configState.business_buyer_site}>
                  Buyers Website
                </a>
              )
            ),
            customLoginLoadObj.severity
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="px-c-Login grid-x align-center">
        <div className="cell small-12">
          <LoginFlow configState={props.configState} actions={props.actions} />
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    configState: state.configState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
