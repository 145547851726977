import { Divider, FormControlLabel, InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import {
  BackButton,
  PrimaryButton,
  PrimaryCheckbox,
  TextField,
} from "../../components/AtomComponents";
import useDataService from "../../hooks/useDataService";
import { eMessageType } from "../../types/IMessageType";
import { fetchData } from "../../_lib/lib";
import Progress from "../NewTenant/Progress/Progress";

const UpdateVersion = () => {
  const { openSnackBar } = useDataService();

  const [version, setVersionCode] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [isUpdateConfig, setIsUpdateConfig] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const update = async (type: "App" | "Config") => {
    const request = { body: {}, url: "" };
    if (type === "App") {
      request.body = { version:`v${version}`, config_update: isUpdateConfig };
      request.url = "update-app-version";
    } else {
      request.body = { tenant_id: tenantId };
      request.url = "update-config-version";
    }
    setIsLoading(true);
    const res: any = await fetchData("post", `code-deployment/${request.url}`, {
      body: request.body,
    });
    setIsLoading(false);

    if (!res.error) {
      reset(type);
      openSnackBar(`${type} updated success`, eMessageType.success);
    } else {
      openSnackBar(
        `${type} updated failed, Something went wrong`,
        eMessageType.error
      );
    }
  };

  const reset = (type: "App" | "Config") => {
    if (type === "App") {
      setIsUpdateConfig(false);
      setVersionCode("");
    } else {
      setTenantId("");
    }
  };

  return (
    <div className="px-ConfigTenant grid-y">
      {isLoading && <Progress />}
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Update Version</h3>
      </div>
      <div className="grid-x margin-left-2">
        <h4 className="small-12 margin-top-3 ">App Version</h4>
        <div className="cell small-3 medium-3  grid-y">
          <TextField
            label="Version Code"
            size="small"
            value={version}
            onChange={(e) => {
              setVersionCode(e.target.value);
            }}
            // placeholder="Version Code"
            className="margin-right-1"
            InputProps={{
              startAdornment: (
                <InputAdornment className="pointer-mouse" position="start">
                  v
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            className="margin-top-1"
            control={
              <PrimaryCheckbox
                checked={isUpdateConfig}
                onChange={() => setIsUpdateConfig(!isUpdateConfig)}
              />
            }
            label="Update config version"
          />
          <PrimaryButton
            disabled={!version.length}
            onClick={() => update("App")}
          >
            Update App
          </PrimaryButton>
        </div>
      </div>
      <Divider className="margin-top-2" />
      <div className="grid-x margin-left-2">
        <h4 className="small-12 margin-top-3 ">Config Version</h4>
        <div className="cell small-3 medium-3  grid-y">
          <TextField
            label="Tenant ID"
            size="small"
            value={tenantId}
            onChange={(e) => {
              setTenantId(e.target.value);
            }}
            placeholder="Tenant ID"
            className="margin-right-1 margin-bottom-1"
          />
          <PrimaryButton
            disabled={!tenantId.length}
            onClick={() => update("Config")}
          >
            Update Config
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UpdateVersion;
