export const FormFieldsConfig:any = {
  'Contact Email': [
    {
      label: 'Email of the person submitting this form * ',
      name: 'contactEmail',
      required: true,
      maxLength: 100,
    },
  ],
  Company: [
    {
      label: 'Offical Legal Name *',
      name: 'officialLegalName',
      required: true,
      maxLength: 100,
    },
    {
      label: 'DBA',
      name: 'dba',
      maxLength: 100,
    },
    {
      label: 'Jurisdiction of Organization *',
      name: 'jurisdictionOfOrganization',
      required: true,
      maxLength: 100,
    },
  ],
  'Authorized Signer': [
    {
      label: "Authorized Signer's Name *",
      name: 'signerName',
      required: true,
      maxLength: 100,
    },
    {
      label: "Authorized Signer's Title *",
      name: 'signerTitle',
      required: true,
      maxLength: 100,
    },
    {
      label: "Authorized Signer's Email *",
      name: 'signerEmail',
      required: true,
      maxLength: 100,
    },
    {
      label: "Authorized Signer's Phone",
      name: 'signerPhone',
      maxLength: 50,
      type:'phone'
    },
  ],
  'Accounts Payable': [
    {
      label: 'Accounts Payable Contact Name *',
      name: 'payableContactName',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Accounts Payable Email *',
      name: 'payableEmail',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Accounts Payable Phone',
      name: 'payablePhone',
      maxLength: 50,
      type:'phone'

    },
  ],
  'Billing Address': [
    {
      label: 'Billing Address 1 *',
      name: 'billingAddress1',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Billing Address 2',
      name: 'billingAddress2',
      maxLength: 100,
    },
    {
      label: 'Billing City *',
      name: 'billingCity',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Billing State / Province',
      name: 'billingState',
      required: true,
      maxLength: 50,
    },
    {
      label: 'Billing Country *',
      name: 'billingCountry',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Billing Zip/Postal *',
      name: 'billingZipcode',
      required: true,
      maxLength: 50,
    },
  ],
  'PhoneX Site Configuration Options': [
    {
      label: 'Business Name for display on Sites *',
      name: 'businessName',
      required: true,
      maxLength: 50,
      instruction: 'The business name that your buyers will see on your new PhoneX site.',
    },
    {
      label: 'Business Short Name for display on Sites (10 char max)',
      name: 'businessShortName',
      // required: true,
      maxLength: 10,
      instruction: 'An abbreviation to use when space is tight.',
    },
    {
      label: 'Price Listing Currency *',
      name: 'currency',
      required: true,
      maxLength: 10,
      instruction: 'All your prices will be displayed in this currency.',
    },
    {
      label: 'New URL to use for your ADMIN Site *  ',
      name: 'adminSiteUrl',
      required: true,
      maxLength: 100,
      instruction: 'Example: Admin.YourDomain.com',
    },
    {
      label: 'ADMIN Sign In User Email Address * ',
      name: 'adminUserEmail',
      required: true,
      maxLength: 100,
      instruction: 'The email address you will use  to sign in and to receive notifications',
    },
    {
      label: 'New URL to use for your BUYER Site  *  ',
      name: 'buyerSiteUrl',
      required: true,
      maxLength: 100,
      instruction: 'Example: Buy.YourDomain.com',
    },
    {
      label: 'BUYER Site Notifications sent FROM Email Address *  ',
      name: 'buyerFromEmail',
      required: true,
      maxLength: 100,
      instruction:
        'Must be on a domain you own and should be an email address you have an inbox for.',
    },
    {
      label: 'BUYER Site Language *',
      name: 'buyerSiteLanguage',
      required: true,
      maxLength: 20,
      instruction: 'The primary language of your buyers.',
    },
    {
      label: 'Logo Image File',
      name: 'logo',
      type: 'image',
      instruction:
        'Upload a large, high-quality image file, and we’ll size it for use on your PhoneX site',
    },
    {
      label: 'Favicon Image File ',
      name: 'favicon',

      type: 'image',
      instruction:
        'If you have a prepared favicon image you want to use on your new PhoneX website, upload it here.',
    },
    {
      label: 'URL Link to your existing Marketing or Business Website  ',
      name: 'existingBusinessWebsite',
      maxLength: 100,
      instruction: 'Let us know if you have an existing website for your marketing or business.',
    },
  ],
};
