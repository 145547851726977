import React, { useEffect, useState } from 'react';
import { FormStatus } from '../../types/IClientForm';
import { Dialog, DialogTitle, FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import {
  PrimaryRadio,
  TertiaryButton,
  PrimaryButton,
  PrimaryCheckbox,
  TextField,
} from '../AtomComponents';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import LoadingDialog from '../LoadingDialog/LoadingDialog';

const formStatusSetting: Record<FormStatus, { label: string; colorClass: string }> = {
  SUBMITTED: {
    label: 'Submitted',
    colorClass: 'badge_1',
  },
  COMPLETED: {
    label: 'Completed',
    colorClass: 'badge_2',
  },
  REJECTED: {
    label: 'Rejected',
    colorClass: 'rejected',
  },
};
interface IClientFormStatus {
  label: FormStatus;
  submittedId?: string;
}

const ClientFormStatus = ({ label, submittedId }: IClientFormStatus) => {
  const [currentlabel, setCurrentLabel] = useState<FormStatus>('SUBMITTED');
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { fetchUrl, openSnackBar } = useDataService();

  useEffect(() => {
    setCurrentLabel(label as FormStatus);
  }, [label]);

  const editStatus = (e: React.MouseEvent<HTMLElement>) => {
    if (!submittedId) {
      e.preventDefault();
    } else {
      setShowEditPopup(true);
    }
  };

  const updateFormStatus = async (update: {
    status: FormStatus;
    sendEmailNotification?: boolean;
    notes?: string;
  }) => {
    try {
      setLoading(true);
      await fetchUrl(
        'PUT',
        formatString(apiToUrlMap.updateProspectiveTenantStatus, {
          submittedId,
        }),
        {
          body: {
            ...update,
          },
        }
      );

      setShowEditPopup(false);
      setCurrentLabel(update.status);
      openSnackBar(`Status updated successfully `, eMessageType.success);
    } catch (e: any) {
      openSnackBar(e.message || `Unable to Update status`, eMessageType.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingDialog isDialogOpen={isLoading} />
      <span
        onClick={editStatus}
        className={`px-grid-chips px-background-${
          formStatusSetting[currentlabel] ? formStatusSetting[currentlabel].colorClass : 'primary'
        }`}>
        {formStatusSetting[currentlabel]?.label ? formStatusSetting[currentlabel].label : label}
      </span>
      {submittedId && (
        <EditClientFormStatusDailog
          open={showEditPopup}
          onClose={() => {
            setShowEditPopup(!showEditPopup);
          }}
          selected={
            formStatusSetting[currentlabel]?.label
              ? (formStatusSetting[currentlabel].label as FormStatus)
              : label
          }
          onSave={updateFormStatus}
        />
      )}
    </>
  );
};

export default ClientFormStatus;

interface IEditClientFormStatusDailog {
  open: boolean;
  onClose: () => void;
  selected: FormStatus;
  onSave: (update: { status: FormStatus; sendEmailNotification?: boolean; notes?: string }) => void;
}

export const EditClientFormStatusDailog = ({
  open,
  onClose,
  selected,
  onSave,
}: IEditClientFormStatusDailog) => {
  const [selectedValue, setSelectedValue] = useState<FormStatus>('' as FormStatus);

  useEffect(() => {
    setSelectedValue(selected.toUpperCase() as FormStatus);
  }, [selected]);

  const [sentEmail, setSendEmail] = useState({ sendEmailNotification: false, note: '' });

  const handleChange = (e: any) => {
    if (!!e.target.value) setSelectedValue(e.target.value);
  };

  const onClickSave = () => {
    if (selectedValue === 'REJECTED' && sentEmail.sendEmailNotification) {
      onSave({ status: selectedValue, ...sentEmail });
    } else {
      onSave({ status: selectedValue });
    }
  };

  const onDialogClose = () => {
    setSendEmail({ sendEmailNotification: false, note: '' });
    setSelectedValue(selected.toUpperCase() as FormStatus);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onDialogClose} className="px-clients invoice-dialog">
      <DialogTitle className="padding-left-1">Update Form Status</DialogTitle>
      <p className="padding-left-1 margin-0">
        <span className="faded_1 margin-right-1">Form: </span>New Client Account Details
      </p>
      <div className="content">
        <FormControl onChange={handleChange}>
          <RadioGroup defaultValue={selected?.toUpperCase()}>
            <FormControlLabel
              value="SUBMITTED"
              control={<PrimaryRadio />}
              label={<ClientFormStatus label="SUBMITTED" />}
            />

            <FormControlLabel
              value="COMPLETED"
              control={<PrimaryRadio />}
              label={<ClientFormStatus label="COMPLETED" />}
            />
            <FormControlLabel
              value="REJECTED"
              control={<PrimaryRadio />}
              label={<ClientFormStatus label="REJECTED" />}
            />
          </RadioGroup>
        </FormControl>
        {selectedValue === 'REJECTED' && (
          <>
            <p>
              <PrimaryCheckbox
                checked={sentEmail.sendEmailNotification}
                onChange={() =>
                  setSendEmail({
                    ...sentEmail,
                    sendEmailNotification: !sentEmail.sendEmailNotification,
                  })
                }
              />
              Send an Email Notification to the contact
            </p>
            {sentEmail.sendEmailNotification && (
              <TextField
                minRows={4}
                maxRows={15}
                style={{ width: '100%' }}
                multiline
                label="Add Note to Email Notification (optional)"
                onChange={(e) =>
                  setSendEmail({
                    ...sentEmail,
                    note: e.target.value,
                  })
                }
              />
            )}
          </>
        )}
      </div>
      <div className="text-right margin-right-2">
        <TertiaryButton onClick={onDialogClose} className="margin-right-1">
          Cancel
        </TertiaryButton>
        <PrimaryButton disabled={selectedValue === selected.toUpperCase()} onClick={onClickSave}>
          Save
        </PrimaryButton>
      </div>
    </Dialog>
  );
};
