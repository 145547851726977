import { FormControlLabel, RadioGroup, TextField } from '@material-ui/core';
import React from 'react';
import ClientAccount from './ClientAccount';
import { PrimaryButton, PrimaryRadio } from '../AtomComponents';
import { IFieldProps } from '../../types/IResellerInvite';

const RegistrationFields = ({
  userDetails,
  isError,
  onChangeWebsite,
  updateMultipleKeys,
  isDisable,
  isOptionsReadOnly,
}: IFieldProps) => {
  const radioOptions = {
    optionNo: {
      id: 'no',
      label: 'NOT YET, and I would like to accept this reseller invitation now',
    },
    optionYes: {
      id: 'yes',
      label: `YES, I’m a PhoneX client and want to activate ${userDetails?.pxnsTenantName} as a supplier through PhoneX Connect`,
    },
  };
  const optionSelected = userDetails?.optionSelected ?? '';

  // based on option change show the client account
  const onOptionChange = (e: any) =>
    updateMultipleKeys({
      optionSelected: e.target.value,
      isNotYetClient: e.target.value !== 'yes',
    });

  // Accepting invite
  const onAcceptInvitation = () => onChangeWebsite(null, 'acceptInvite');

  return (
    <div className="px-registration-fields margin-top-3">
      <h4 className="px-text-bold">Registration</h4>
      <div className="px-fields">
        <TextField
          label="Your Invitation Code"
          variant="outlined"
          type="text"
          value={userDetails.invitationCode}
          required
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Your Email Address"
          variant="outlined"
          value={userDetails.userEmail}
          className="margin-top-1"
          type="email"
          required
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <h5 className="px-text-bold margin-top-2 margin-bottom-0">
        Are you already a PhoneX client?
      </h5>
      <RadioGroup>
        {Object.values(radioOptions).map((options) => {
          return (
            <FormControlLabel
              key={options.id}
              value={options.id}
              onChange={onOptionChange}
              disabled={isOptionsReadOnly}
              control={<PrimaryRadio key={options.id} value={options.id} />}
              label={options.label}
            />
          );
        })}
      </RadioGroup>
      {!!optionSelected && (
        <>
          {optionSelected === 'yes' && (
            <h4 className="px-text-bold margin-top-2">PhoneX Client Account</h4>
          )}
          <div className="margin-top-1 px-details">
            <ClientAccount
              isNotFound={!userDetails.isPxTenant}
              isError={isError}
              optionSelected={optionSelected}
              onChangeWebsite={onChangeWebsite}
              userDetails={userDetails}
            />
          </div>
        </>
      )}
      <PrimaryButton
        className="px-submit-btn"
        disabled={isDisable || userDetails.isPxnr}
        onClick={onAcceptInvitation}>
        Accept Reseller Invitation from {userDetails?.pxnsTenantName}
      </PrimaryButton>
    </div>
  );
};

export default RegistrationFields;
