import { Alert } from "@material-ui/lab";
import React from "react";
import JSONInput from "react-json-editor-ajrm";
import { BackButton, PrimaryButton } from "../../components/AtomComponents";
import useDataService from "../../hooks/useDataService";
import { eMessageType } from "../../types/IMessageType";
import { fetchData } from "../../_lib/lib";
import { localeEn } from "../ConfigTenant/ConfigTenant";
import { Wait } from "./SchemaSync";
import { checkForSuccesInvocation } from "../../_lib/utils";
interface IProps {
  syncFor: string;
}

const HorizontalSync = ({ syncFor }: IProps) => {
  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);
  const { openSnackBar } = useDataService();

  const schemaSync = async () => {
    setProcessState(1);
    const res:any = await fetchData(
      "PATCH",
      `schema-sync${
        syncFor !== "master" ? "-slave" : ""
      }/horizontal-sync`,
      {}
    );
    if (!res.error) {
      try {
        checkForSuccesInvocation(res, setPreviewState, setProcessState)
      } catch (e: any) {
        openSnackBar(
          e.message || 'Something went wrong,Unable to check status',
          eMessageType.error
        );
      }
    } else {
      setPreviewState(null);
      setProcessState(0);
      openSnackBar("Horizontal Sync Failed", eMessageType.error);
    }
  };
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data margin-bottom-2">
        <BackButton />
        <h3 className="small-12 margin-bottom-1">Sync across Tenants</h3>
      </div>
      {processState === 0 && (
        <PrimaryButton onClick={schemaSync} >Sync</PrimaryButton>
      )}

      {processState === 1 && <Wait />}
      {processState === 2 && (
        <>
          <div className="margin-bottom-3">
            <JSONInput
              id={`${syncFor}-horizontal-jsonEditor`}
              theme="light_mitsuketa_tribute"
              locale={localeEn}
              placeholder={previewState}
              height="550px"
              width="100%"
              viewOnly={true}
              style={{ body: { fontSize: "16px" } }}
            />
          </div>
          <Alert severity="success" className="margin-bottom-3">
            Schema sync completed!
          </Alert>
          <PrimaryButton onClick={() => setProcessState(0)}>
            Restart
          </PrimaryButton>
        </>
      )}
    </>
  );
};

export default HorizontalSync;
