import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import { BackButton } from '../../components/AtomComponents';
import InvoiceAddress from '../../components/InvoiceDetails/InvoiceAddress';
import { InvoiceEditStatus } from '../../components/InvoiceDetails/InvoiceEditStatus';
import Progress from '../../components/Progress/Progress';
import useDataService from '../../hooks/useDataService';
import { IInvoiceDetails } from '../../types/IInvoice';

import './InvoiceDetail.scss';

import { eMessageType } from '../../types/IMessageType';
import InvoiceInfo from '../../components/InvoiceDetails/InvoiceInfo';
import InvoiceLineItems from '../../components/InvoiceDetails/InvoiceLineItems';
import InvoiceSubTotal from '../../components/InvoiceDetails/InvoiceSubTotal';
import { TertiaryButton } from '../../components/AtomComponents';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { GetApp } from '@material-ui/icons';
import { getMessageForPayment } from '../../_lib/utils';

const InvoiceDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Invoice Details';
    fetchInvoiceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { fetchUrl, openSnackBar, exportData } = useDataService();

  const pathnames = location.pathname.split('/');
  const invoiceNumber = pathnames[pathnames.length - 1].toString();

  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetails>();

  const fetchInvoiceDetails = async () => {
    try {
      const invoice: IInvoiceDetails = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.getInvoiceDetails, {
          invoiceNumber,
        })
      );
      setInvoiceDetails(invoice);
    } catch (e: any) {
      openSnackBar(
        e.message || `Unable to fetch details about ${invoiceNumber}`,
        eMessageType.error
      );
      history.goBack();
    }
  };

  const previewInvoice = async () => {
    setIsLoading(true);
    try {
      await exportData({
        url: formatString(apiToUrlMap.invoicePreview, { invoiceNumber }),
      });
    } catch (e) {
      openSnackBar('Unable to Preview,Try again after sometime', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const bulkRetryPayment = async (invoiceNumbers: string[]) => {
    try {
      setIsLoading(true);
      const res = await fetchUrl('POST', apiToUrlMap.bulkRetryPayment, {
        body: { invoiceNumbers },
      });
      await fetchInvoiceDetails();
      const { message, type } = getMessageForPayment(res);
      openSnackBar(message || 'Payment Successful', type);
    } catch (e: any) {
      openSnackBar(e.message || 'Payment Failed', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!invoiceDetails) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }
  return (
    <div className="px-invoice-details">
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="invoice-header">
        <div className="px-one-line-data">
          <BackButton />
          <h3>Invoice Details / {invoiceNumber}</h3>
        </div>

        <TertiaryButton onClick={previewInvoice} data-id="import-invoice">
          <GetApp className="margin-right-1" />
          Invoice
        </TertiaryButton>
      </div>

      <InvoiceEditStatus
        invoiceNumber={invoiceNumber}
        total={invoiceDetails.total}
        label={invoiceDetails.invoiceStatus}
        dueDate={invoiceDetails.dueDate}
        onPayment={bulkRetryPayment}
        canShowPayment={invoiceDetails.hasCardOnFile}
      />
      <InvoiceAddress
        billToDetails={invoiceDetails.billToDetails}
        shipToDetails={invoiceDetails.shipToDetails}
      />
      <InvoiceInfo {...invoiceDetails} />

      <InvoiceLineItems lineItems={invoiceDetails.lineItems} />
      <InvoiceSubTotal
        subTotal={invoiceDetails.subTotal}
        tax={invoiceDetails.tax}
        taxDetails={invoiceDetails.taxDetails}
        total={invoiceDetails.total}
      />
    </div>
  );
};

export default InvoiceDetail;
