import React, { createElement, useEffect, useState } from 'react';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import './index.scss';
import FilterDate from './FilterDate/FilterDate';
import FiltersRequired from './FiltersRequired/FiltersRequired';
import {
  IAdvanceFilterEventFn,
  IClearFiltersParams,
  ICloseFilterDialogParams,
  IFilterCountsState,
  IFilterDialogOpenFn,
  IFiltersApplied,
  IGetFilterOptionCount,
  IFilterSaveParams,
  IFilterOptions,
  IDialogInfo,
} from '../../types/IFilterEngine';
import { formatString } from '../../ApiMapping';
import FilterChip from './FilterChip';
import { checkNullOrUndefined, generateLabelKey } from '../../_lib/lib';
import FilterInvoiceAmount from './FilterInvoiceAmount/FilterInvoiceAmount';

interface IAllRequiresFilters {
  children?: any;
  filterCountState?: IFilterCountsState;
  chipsEvent: IAdvanceFilterEventFn;
  chipsDataState: any;
  requiredFilters: Array<string>;
  customFilters?: Array<string>;
  filtersApplied: IFiltersApplied;
  setFiltersApplied: (param: IFiltersApplied) => void;
  advanceFilters: any;
  resetFilter: any;
  history?: any;
}

let recentUpdatedFilter: string = '';

export interface IAllRequiredFilterDialog {
  // all the dialog options with selection
  presetOptionObj: IFilterOptions;
  // custom options object (could mould according to filter)
  customOptionObj: any;
  // dialog information
  title: string;
  attribute: string;
  // open dialog
  openDialog: (params: IDialogInfo) => void;
  // close dialog
  closeDialog: (params: ICloseFilterDialogParams) => void;
  // clear filter
  clearFilter: (params: IFilterSaveParams) => void;
  // save dialog
  saveFilter: (params: IFilterSaveParams) => void;
  // get option labels
  getLabel: (attribute: string, option: string) => string;
  // any other information if we need to pass to the filter
  filterState: any;
}

export default function AllRequiredFilters({
  filterCountState,
  chipsEvent,
  chipsDataState,
  requiredFilters,
  customFilters,
  filtersApplied,
  setFiltersApplied,
  advanceFilters,
  resetFilter,
  children,
  history,
}: IAllRequiresFilters) {
  // declare the component list
  const componentsList: any = {
    client: FiltersRequired,
    referrer: FiltersRequired,
    invoicedAmount: FilterInvoiceAmount,
    invoiceDate: FilterDate,
    dueDate: FilterDate,
    billingPeriodStartDate: FilterDate,
  };
  // open dialog
  const [dialogState, setDialogState] = useState<any>(null);
  // set dialog initial state to the required filter state
  // dialog initial state => dialog initial items selection state
  const [filterCounts, setFilterCounts] = useState<any>(null);
  // used for maintaining sequence of the chips visible on the screen
  const [filterUISequence, setFilterUISequence] = useState<Array<string>>(requiredFilters);

  // useEffect(() => {
  //   if (!filterCountState) return;
  //   const updatedFilterCounts: any = {};
  //   filterUISequence.forEach((val: string) => {
  //     if (filterCounts && recentUpdatedFilter === val) {
  //       updatedFilterCounts[val] = filterCounts[val];
  //     } else {
  //       updatedFilterCounts[val] = filterCountState.absolute[val] || 0;
  //     }
  //   });
  //   setFilterCounts(updatedFilterCounts);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterCountState?.filtered]);

  // get the lable for each chip [catefory, out of stock, customer]
  useEffect(() => {
    if (filtersApplied === undefined) {
      resetFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersApplied]);

  useEffect(() => {
    if (!history?.location.search) return;
    const searchParams = history.location.search.substr(1);
    const searchArr = searchParams.split('&');
    if (!searchArr.length) return;
    let lastFilterItem = undefined;
    for (let i = searchArr.length - 1; i >= 0; i--) {
      const item = searchArr[i];
      const filterApplied = item.split('=')[0];
      if (requiredFilters.includes(filterApplied)) {
        lastFilterItem = item;
        break;
      }
    }
    if (!lastFilterItem) return;
    const lastFilterApplied = lastFilterItem.split('=')[0];
    setFilterCounts({
      ...filterCounts,
      [lastFilterApplied]: filterCountState?.absolute[lastFilterApplied] || 0,
    });
    recentUpdatedFilter = lastFilterApplied;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabelForChips = (attr: string, chipsDataState: any) => {
    if (!filtersApplied || !attr || !filtersApplied[attr]) return;

    let attLabel = attr;
    const labelKey = generateLabelKey(attr);
    // Get spelled out label from JSON => it is the main label i.e., Category: or Customer:
    if (chipsDataState.attributeLabels && chipsDataState.attributeLabels.hasOwnProperty(attr)) {
      attLabel = chipsDataState.attributeLabels[attr];
    }

    if (['itemStatus', 'paymentStatus', 'client', 'warehouse', 'referrer'].includes(attr)) {
      const actualFiltersApplied = [];
      const selectedArr = Object.keys(filtersApplied[attr]);
      for (let index = 0; index < selectedArr.length; index++) {
        const val = selectedArr[index];
        if (
          filtersApplied[attr][val] &&
          val !== 'label' &&
          !checkNullOrUndefined(filterCountState?.absolute[attr]) &&
          !checkNullOrUndefined(filterCountState?.absolute[attr][val])
        ) {
          const filterOptionLabel =
            chipsDataState?.optionLabels &&
            chipsDataState?.optionLabels[labelKey] &&
            chipsDataState?.optionLabels[labelKey][val]
              ? chipsDataState?.optionLabels[labelKey][val]
              : val;
          actualFiltersApplied.push(filterOptionLabel);
        }
      }
      // remove false from the object
      if (
        !actualFiltersApplied.length ||
        actualFiltersApplied.length >= Object.keys(filterCountState?.absolute[attr]).length
      ) {
        attLabel += ': ALL';
      } else {
        attLabel += `: ${actualFiltersApplied.join(', ')}`;
      }
      return attLabel;
    }

    if (customFilters?.includes(attr) && chipsDataState[labelKey]?.chipLabel) {
      return formatString(chipsDataState[labelKey].chipLabel, {
        ...filtersApplied[attr],
      });
    }
  };

  // getting lower level filter labels from settings => if upper level filter is category, then lower level filter is given by this function
  const getOptionLabel = (attribute: string, option: string) => {
    let attLabel = option;
    let labelKey = generateLabelKey(attribute);

    if (
      chipsDataState?.optionLabels.hasOwnProperty(labelKey) &&
      chipsDataState.optionLabels[labelKey].hasOwnProperty(option)
    )
      attLabel = chipsDataState.optionLabels[labelKey][option];

    if (chipsDataState[labelKey]?.hasOwnProperty(option))
      attLabel = chipsDataState[labelKey][option];

    return attLabel;
  };

  // open filter dialog
  const openDialog: IFilterDialogOpenFn = (dialogInfo) => {
    setDialogState({ ...dialogInfo });
    if (!filterCounts?.hasOwnProperty(dialogInfo.attribute)) {
      // taking initial dialog state from the absolute filter count
      let dialogMenuFiltered = {};
      if (
        filterCountState &&
        filterCountState.filtered &&
        filterCountState.filtered[dialogInfo.attribute]
      )
        dialogMenuFiltered = { ...filterCountState.filtered[dialogInfo.attribute] };
      // setting initial state
      setFilterCounts({
        ...filterCounts,
        [dialogInfo.attribute]: dialogMenuFiltered,
      });
    }

    if (chipsEvent)
      chipsEvent({
        type: 'open-dialog',
      });
  };

  // cancel filter dialog
  const closeDialog = ({ attribute }: ICloseFilterDialogParams) => {
    updateFilterCounts(attribute);

    setDialogState(null);
    if (chipsEvent) {
      chipsEvent({
        type: 'cancel-dialog',
      });
    }
  };

  // save filter dialog
  const saveDialog = (params: IFilterSaveParams) => {
    //if there is no change in the current filter applied, make no change to the filter state.
    if (
      JSON.stringify(params.selectedFilters) === JSON.stringify(filtersApplied[params.attribute])
    ) {
      if (dialogState) setDialogState(null);
      return;
    }
    // if (
    //   params.attribute === '' ||
    //   params.attribute === 'dueDate' ||
    //   params.attribute === ''
    // ) {
    //   const { optionId, endDate, startDate } = params.selectedFilters;
    //   // console.log
    //   if (
    //     optionId === filtersApplied[params.attribute].optionId &&
    //     format(startDate, commonDateFormat) ===
    //       format(filtersApplied[params.attribute].startDate, commonDateFormat) &&
    //     format(endDate, commonDateFormat) ===
    //       format(filtersApplied[params.attribute].endDate, commonDateFormat)
    //   ) {
    //     if (dialogState) setDialogState(null);
    //     return;
    //   }
    // }
    if (
      recentUpdatedFilter !== 'invoiceDate' &&
      recentUpdatedFilter !== 'dueDate' &&
      recentUpdatedFilter !== 'billingPeriodStartDate'
    ) {
      recentUpdatedFilter = params.attribute;
    } else {
      recentUpdatedFilter = '';
    }

    updateFilterCounts(params.attribute);

    updateFilterState(params);

    if (chipsEvent) {
      chipsEvent({
        type: 'save-dialog',
        payload: params,
      });
    }
  };

  const updateFilterState = (params: IFilterSaveParams) => {
    const selectedFilterAppliedState = {
      ...params.selectedFilters,
    };

    const updatedFiltersApplied = {
      ...filtersApplied,
      [params.attribute]: selectedFilterAppliedState,
    };
    const filterUISeqSet = new Set<any>([...filterUISequence, params.attribute]);

    setFiltersApplied(updatedFiltersApplied);
    setFilterUISequence(Array.from(filterUISeqSet));

    if (dialogState) setDialogState(null);
  };

  const updateFilterCounts = (attr: string) => {
    //resetting filter count for other filters ezxept recentlty updated
    const updatedFilterCounts = { ...filterCounts };
    Object.keys(updatedFilterCounts).forEach((filter: string) => {
      if (filter !== recentUpdatedFilter) delete updatedFilterCounts[filter];
    });
    setFilterCounts(updatedFilterCounts);
  };

  const clearFilter = (params: IClearFiltersParams) => {
    if (recentUpdatedFilter === params.attribute) recentUpdatedFilter = '';
    // close dialog
    setDialogState(null);
    if (!filtersApplied[params.attribute]) return;
    if (filtersApplied[params.attribute].label === 'ALL') return;
    updateFilterCounts(params.attribute);
    updateFilterState({
      title: params.title,
      attribute: params.attribute,
      selectedFilters: {
        label: 'ALL',
      },
    });
    // add filter to the
    chipsEvent({
      type: 'save-dialog',
      payload: params,
    });
  };

  const getFilterOptionsCount: IGetFilterOptionCount = (attribute, attributeVal) => {
    return filterCounts[attribute][attributeVal] || 0;
  };

  // creating the dialog component
  const DialogComponent = () => {
    try {
      const isComponentAvailable =
        dialogState?.attribute && componentsList.hasOwnProperty(dialogState.attribute);
      const dialogComponentName = isComponentAvailable ? dialogState.attribute : 'generic';
      if (isComponentAvailable) {
        // all items available
        let presetOptionObj = filterCountState?.absolute[dialogState.attribute];
        const filterLabel: any = {};
        if (presetOptionObj && dialogComponentName !== 'category') {
          Object.keys(presetOptionObj).forEach((option) => {
            const label = getOptionLabel(dialogState.attribute, option);
            const count = getFilterOptionsCount(dialogState.attribute, option);
            if (dialogComponentName === 'warehouse') {
              filterLabel[option] = `${option} - ${label} (${count})`;
            } else {
              filterLabel[option] = `${label} (${count})`;
            }
          });
        }

        let customOptionObj: any = {};
        if (
          dialogState.attribute === 'invoiceDate' ||
          dialogState.attribute === 'dueDate' ||
          dialogState.attribute === 'billingPeriodStartDate' ||
          dialogState.attribute === 'invoicedAmount'
        ) {
          customOptionObj = filtersApplied[dialogState.attribute];
        } else {
          const selectedArr = Object.keys(filtersApplied[dialogState.attribute]);
          for (let index = 0; index < selectedArr.length; index++) {
            const val = selectedArr[index];
            if (
              filtersApplied[dialogState.attribute][val] &&
              val !== 'label' &&
              !checkNullOrUndefined(filterCountState?.absolute[dialogState.attribute][val])
            ) {
              customOptionObj[val] = true;
            }
          }
        }

        const dialogParam: IAllRequiredFilterDialog = {
          presetOptionObj: presetOptionObj,
          customOptionObj: customOptionObj,
          title: getOptionLabel('attribute', dialogState?.attribute || ''),
          attribute: dialogState.attribute,
          filterState: {
            filterUISequence,
            filterLabel: filterLabel,
            isRequired: requiredFilters.indexOf(dialogState.attribute) > -1,
            isApplied: true,
          },
          openDialog: openDialog,
          getLabel: getOptionLabel,
          clearFilter: clearFilter,
          closeDialog: closeDialog,
          saveFilter: saveDialog,
        };

        return createElement(componentsList[dialogComponentName], dialogParam, children);
      }
    } catch (error: any) {
      console.error(error.message, ':occured while creating dialog');
    }
    return <>Component not found! #Err 0001</>;
  };

  const clearAllFilters = () => {
    setFilterCounts(undefined);
    setFilterUISequence(requiredFilters);
    resetFilter();
    if (chipsEvent) {
      chipsEvent({
        type: 'clear',
      });
    }
  };

  const ChipsBar = () => {
    return (
      <>
        <div className="px-advance-chip-filters">
          {filterUISequence.map((mainAttr: any) => {
            let chipLabel: string = getLabelForChips(mainAttr, chipsDataState) || '';
            const isRequiredFilter = requiredFilters.includes(mainAttr);
            if (isRequiredFilter && !componentsList[mainAttr]) return null;
            let isFilterOn =
              isRequiredFilter && chipLabel.split(':')[1]?.trim().toLowerCase() === 'all';
            return (
              <FilterChip
                label={
                  chipLabel.length > 24 &&
                  (mainAttr === 'warehouse' || mainAttr === 'client' || mainAttr === 'referrer')
                    ? `${chipLabel.substring(0, 24)}...`
                    : chipLabel
                }
                attribute={mainAttr}
                openDialog={openDialog}
                key={`chip-${mainAttr}`}
                isFilterOn={isFilterOn}
                isRequiredFilter={isRequiredFilter}
              />
            );
          })}
          <TertiaryButton
            data-id="clearFilter"
            className="margin-bottom-1"
            onClick={clearAllFilters}>
            CLEAR
          </TertiaryButton>
        </div>
        {!!dialogState ? <DialogComponent /> : null}
      </>
    );
  };

  return <>{!!chipsDataState ? <ChipsBar /> : null}</>;
}
