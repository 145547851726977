import { Dialog, DialogTitle, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { isBefore, isToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import apiToUrlMap from '../../ApiMapping';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { changeTimeZone } from '../../_lib/lib';
import { PrimaryButton, PrimaryRadio, TertiaryButton } from '../AtomComponents';

import InvoiceStatus from '../ClientDetails/InvoiceStatus';
import LoadingDialog from '../LoadingDialog/LoadingDialog';

interface IInvoiceEditStatus {
  label: string;
  invoiceNumber: string;
  total: number;
  dueDate: Date;
  onPayment: (invoiceNumber: string[]) => void;
  canShowPayment: 0 | 1;
}

export const InvoiceEditStatus = ({
  label,
  invoiceNumber,
  total,
  dueDate,
  onPayment,
  canShowPayment,
}: IInvoiceEditStatus) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { fetchUrl, openSnackBar } = useDataService();
  const [isLoading, setLoading] = useState(false);

  const [currentlabel, setCurrentlabel] = useState<string>('');

  useEffect(() => {
    setCurrentlabel(label);
  }, [label]);

  const showPopUp = (label: string) => {
    setShowEditPopup(true);
  };
  const updateInvoiceStatus = async (status: string) => {
    try {
      setLoading(true);
      await fetchUrl('POST', apiToUrlMap.updateInvoiceStatus, {
        body: {
          invoiceNumbers: [invoiceNumber],
          status,
        },
      });
      setCurrentlabel(status);
      setLoading(false);
      setShowEditPopup(false);
      openSnackBar(`Status updated successfully for ${invoiceNumber}`, eMessageType.success);
    } catch (e: any) {
      setLoading(false);
      openSnackBar(
        e.message || `Unable to Update status about ${invoiceNumber}`,
        eMessageType.error
      );
    }
  };

  return (
    <div className="px-clients margin-left-1 padding-bottom-1">
      <LoadingDialog isDialogOpen={isLoading} />
      <span className="margin-right-3">Status :</span>
      <InvoiceStatus
        dataId="invoice-status"
        label={currentlabel}
        isEditable={total > 0}
        onClick={showPopUp}
      />
      {canShowPayment && currentlabel === 'OVERDUE' ? (
        <PrimaryButton className="margin-left-2" onClick={() => onPayment([invoiceNumber])}>
          <AccountBalanceIcon className="margin-right-1" /> Make Payment By Card Now
        </PrimaryButton>
      ) : (
        <></>
      )}
      

      <InvoiceEditDialog
        open={showEditPopup}
        onClose={() => {
          setShowEditPopup(!showEditPopup);
        }}
        selected={currentlabel}
        onSave={updateInvoiceStatus}
        dueDate={dueDate}
      />
    </div>
  );
};

interface IInvoiceEditDialog {
  open: boolean;
  onClose: () => void;
  selected: string;
  onSave: (value: string) => void;
  dueDate: Date;
}

export const InvoiceEditDialog = ({
  open,
  onClose,
  selected,
  onSave,
  dueDate,
}: IInvoiceEditDialog) => {
  const [selectedValue, setSelectedValue] = useState(selected);

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const onClickSave = () => {
    onSave(!!selectedValue ? selectedValue : selected);
  };

  const checkIsOverdue = () => {
    const dueDateInEST = changeTimeZone(dueDate);
    const todayInEST = changeTimeZone(new Date());
    return isBefore(dueDateInEST, todayInEST) || isToday(dueDateInEST);
  };

  const paymentOptions = [
    checkIsOverdue() ? 'OVERDUE' : 'AWAITING_PAYMENT',
    'PAID',
    'WRITTEN_OFF',
    'VOIDED',
  ];

  const renderPaymentOption = () => {
    return (
      <>
        {paymentOptions.map((status: string) => (
          <FormControlLabel
            value={status}
            control={<PrimaryRadio disabled={selected === status} />}
            label={<InvoiceStatus label={status} />}
          />
        ))}
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} className="px-clients invoice-dialog">
      <DialogTitle>Edit Invoice Status</DialogTitle>
      <div className="content">
        <FormControl onChange={handleChange}>
          <RadioGroup defaultValue={selected}>{renderPaymentOption()}</RadioGroup>
        </FormControl>
      </div>
      <div className="text-right margin-right-2">
        <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
        <PrimaryButton onClick={onClickSave}>Save</PrimaryButton>
      </div>
    </Dialog>
  );
};
