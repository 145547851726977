import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IFilterDialog, IInvoiceAmountFilterState } from '../../../types/IFilterEngine';
import { checkNullOrUndefined } from '../../../_lib/lib';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';

const FilterInvoiceAmount = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
  getLabel,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<IInvoiceAmountFilterState>({
    start: 0,
    end: 1000,
  });

  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    console.log(customOptionObj);
    if (customOptionObj) {
      setFilterOptions({ ...customOptionObj });
    }
  }, [customOptionObj]);

  const handleChange = (e: any) => {
    setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const errObj: any = {};
    let start = +filterOptions?.start,
      end = +filterOptions?.end;
    if (filterOptions.start) {
      const stringStart = `${filterOptions.start}`;
      if (isNaN(start) || stringStart.includes('e')) errObj.start = 'Must be a valid number';
    }

    if (checkNullOrUndefined(filterOptions?.start)) {
      start = 0.01;
    }

    if (filterOptions.end) {
      const stringEnd = `${filterOptions.end}`;
      if (isNaN(end) || stringEnd.includes('e')) errObj.end = 'Must be a valid number';
    }

    if (checkNullOrUndefined(filterOptions?.end)) {
      end = 5;
    }

    if (start > end) {
      errObj.start = `From must be smaller than To`;
      errObj.end = 'To must be greater than From'
    }
    

    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;
    let label: string;
    if (start && end) {
      label = `$${start} to $${end}`;
    } else {
      label = 'ALL';
    }

    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        start,
        end,
        label,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12">Show the invoice that are...</div>
          <div className="grid-x cell small-12 padding-1 px-one-line-data">
            <TextField
              label="From"
              name="start"
              className="cell small-5 px-helper-text-margin-top-1"
              value={checkNullOrUndefined(filterOptions.start) ? '' : filterOptions.start}
              error={!!errorObj.start}
              helperText={errorObj.start || ''}
              onChange={handleChange}
              InputProps={{
                startAdornment: '$',
              }}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="cell small-1 text-center">—</div>
            <TextField
              label="To"
              name="end"
              className="cell small-5 px-helper-text-margin-top-1"
              value={checkNullOrUndefined(filterOptions.end) ? '' : filterOptions.end}
              error={!!errorObj.end}
              helperText={errorObj.end || ''}
              onChange={handleChange}
              InputProps={{
                startAdornment: '$',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterInvoiceAmount;

export const invoicedAmountFilter = (item: any, filtersApplied: any) => {
  const { invoicedAmount } = item;

  if (typeof invoicedAmount !== 'number') {
    return false;
  }
  const filteredInvoicedAmount: any = filtersApplied.invoicedAmount;

  if (
    filteredInvoicedAmount['start'] > invoicedAmount ||
    filteredInvoicedAmount['end'] < invoicedAmount
  ) {
    return false;
  }

  return true;
};
