import React from "react";
import { BackButton } from "../../components/AtomComponents";
import { releaseAutomation } from "./ConfigDeployment";
import { ConfigField } from "./ConfigField";

function VersionToRelease() {
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12 margin-bottom-1">Deploy to Release Environment</h3>
      </div>

      <ConfigField
        releaseAutomation={releaseAutomation}
        endpoint="versioned-to-release"
        type="release"
      />
    </>
  );
}

export default VersionToRelease;
