import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";


export function WorkflowSteps(props: any) {
  return (
    <>
      <div className="small-12">
        <Stepper alternativeLabel activeStep={props.processState - 1}>
          {props.steps.steps.map((label: string) => (
            <Step key={label}>
              <StepLabel>{props.steps[label].title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
}

export default WorkflowSteps;
