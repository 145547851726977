import React, { useEffect, useState } from 'react';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { IReports } from '../../types/IReports';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import ReportGrid from '../../components/Reports/ReportGrid';
import Progress from '../../components/Progress/Progress';

const Reports = () => {
  const { fetchUrl, openSnackBar } = useDataService();
  const [reportsTabState, setReportsTabState] = useState<IReports[]>();
  const [tabValue, setTabValue] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [reports, setReports] = useState({});

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Reports';
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportsTabState && location.search) {
      serachParamsbasedTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getReports = async () => {
    try {
      setLoading(true);
      const reports = await fetchUrl('GET', apiToUrlMap.reports);
      if (reports.length) {
        setReportsTabState(reports);
        handleChange({}, reports[0].id - 1);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      openSnackBar('Something went wrong', eMessageType.error);
      history.goBack();
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event: any, newValue: number) => {
    history.push({ search: `?tab=${newValue}` });
  };

  const serachParamsbasedTab = () => {
    const search = new URLSearchParams(location.search);
    const tabIdFromSearch = search.get('tab');
    if (tabIdFromSearch && reportsTabState && reportsTabState?.length >= +tabIdFromSearch) {
      getReportsById(+tabIdFromSearch);
      setTabValue(+tabIdFromSearch);
    } else {
      history.push({ search: '?tab=0' });
      setTabValue(0);
    }
  };

  const getReportsById = async (id: number) => {
    try {
      setReports({});
      const reports = await fetchUrl(
        'get',
        formatString(apiToUrlMap.reportsById, {
          id: id + 1,
        })
      );

      setReports(reports);
    } catch (e) {
      openSnackBar('Something went wrong', eMessageType.error);
      history.goBack();
    }
  };
  if (loading) return <Progress />;

  return (
    <div className="px-reports">
      <h3>Reports</h3>
      {reportsTabState && (
        <>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="client details tab"
            className="margin-top-1 margin-bottom-1">
            {reportsTabState.map((tab: IReports) => (
              <Tab label={tab.name} key={tab.id} />
            ))}
          </Tabs>
          {reports && <ReportGrid reports={reports} />}
        </>
      )}
    </div>
  );
};

export default Reports;
