import React, { useState } from 'react';
import { BackButton, PrimaryButton, TextField } from '../../components/AtomComponents';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { eMessageType } from '../../types/IMessageType';
import useDataService from '../../hooks/useDataService';

import apiToUrlMap from '../../ApiMapping';
import { trimValue } from '../../_lib/lib';

const PxnrSync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [source_tenant_id, setSourceTenantId] = useState('');
  const [destination_tenant_id, setDestinationTenantId] = useState('');

  const { openSnackBar, fetchUrl } = useDataService();

  const syncTenant = async () => {
    try {
      setIsLoading(true);
      await fetchUrl('POST', apiToUrlMap.pxnrSync, {
        body: {
          ...trimValue({
            source_tenant_id,
            destination_tenant_id,
          }),
        },
      });
      openSnackBar('Tenant sync successfull', eMessageType.success);
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong,Unable to sync', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-pxnr-sync">
      <div className="cell small-6 medium-6 px-one-line-data margin-bottom-2">
        <LoadingDialog isDialogOpen={isLoading} />
        <BackButton />
        <h3 className="small-12">Pxnr Sync</h3>
      </div>
      <div className="grid-y">
        <TextField
          label="Source Tenant ID"
          value={source_tenant_id}
          onChange={(e) => {
            setSourceTenantId(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
           style={{ width: '40%', minWidth: '200px' }}
        />
        <TextField
          label="Destination Tenant ID"
          value={destination_tenant_id}
          onChange={(e) => {
            setDestinationTenantId(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
           style={{ width: '40%', minWidth: '200px' }}
        />
        <PrimaryButton
          className="margin-bottom-2"
          disabled={!!!source_tenant_id || !!!destination_tenant_id}
          onClick={syncTenant}
          style={{ width: '10%', minWidth: '100px' }}>
          Sync
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PxnrSync;
