import React, { useState } from 'react';
import { BackButton, PrimaryButton, TextField } from '../../components/AtomComponents';
import useDataService from '../../hooks/useDataService';
import { trimValue } from '../../_lib/lib';
import { eMessageType } from '../../types/IMessageType';
import Progress from '../../components/Progress/Progress';

const SyncSettings = () => {
  const [source_env, setSourceEnv] = useState('');
  const [destination_env, setDestinationEnv] = useState('');
  const [source_tenant_id, setSourceTenantId] = useState('');
  const [destination_tenant_id, setDestinationTenantId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackBar, fetchUrl } = useDataService();

  const syncSetting = async () => {
    try {
      setIsLoading(true);
      await fetchUrl('post', '/setting-sync', {
        body: {
          ...trimValue({
            source_env,
            destination_env,
            source_tenant_id,
            destination_tenant_id,
          }),
        },
      });
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const disableSync = () => {
    return (
      !source_env.length ||
      !destination_env.length ||
      !source_tenant_id.length ||
      !destination_tenant_id.length
    );
  };

  if (isLoading) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }

  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Sync Settings</h3>
      </div>
      <div className="small-6 margin-top-3 grid-y">
        <TextField
          label="Source Tenant ID"
          value={source_tenant_id}
          onChange={(e) => {
            setSourceTenantId(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
          style={{ width: '50%' }}
        />
        <TextField
          label="Destination Tenant ID"
          value={destination_tenant_id}
          onChange={(e) => {
            setDestinationTenantId(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
          style={{ width: '50%' }}
        />
        <TextField
          label="Source Env"
          value={source_env}
          onChange={(e) => {
            setSourceEnv(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
          style={{ width: '50%' }}
        />
        <TextField
          label="Destination Env"
          value={destination_env}
          onChange={(e) => {
            setDestinationEnv(e.target.value);
          }}
          className="margin-right-1 margin-bottom-2"
          style={{ width: '50%' }}
        />

        <PrimaryButton disabled={disableSync()} onClick={syncSetting} style={{ width: '10%' }}>
          Sync
        </PrimaryButton>
      </div>
    </>
  );
};

export default SyncSettings;
