import React from 'react';

import { IInvoiceAddress } from '../../types/IInvoice';

interface IInvoiceAddressProps {
  billToDetails: IInvoiceAddress;
  shipToDetails: IInvoiceAddress;
}

const InvoiceAddress = ({ billToDetails, shipToDetails }: IInvoiceAddressProps) => {
  return (
    <div className="address-container margin-left-1">
      <Address {...billToDetails} label="Bill To" />
      <Address {...shipToDetails} label="Ship To" />
    </div>
  );
};

export default InvoiceAddress;

interface IAddress extends IInvoiceAddress {
  label: string;
}

const Address = ({
  address1,
  address2,
  state,
  country,
  zipcode,
  label,
  city,
  dba,
  officialLegalName,
}: IAddress) => {
  return (
    <div>
      <h4 className="bold" data-id={label}>
        {label}
      </h4>
      {!!officialLegalName && (
        <span data-id={`${label}-officalLegalName`}>{officialLegalName}</span>
      )}
      {dba && <span data-id={`${label}-dba`}>{dba}</span>}
      <span data-id={`${label}-address1`}>{address1}</span>
      {address2 && <span data-id={`${label}-address2`}>{address2}</span>}
      <span data-id={`${label}-cityCountryZip`}>{`${city || ''}${city ? ',' : ''} ${state || ''}${
        state ? ',' : ''
      } ${country}${country ? ',' : ''}${zipcode}`}</span>
    </div>
  );
};
