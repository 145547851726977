import * as types from '../actions/actionTypes';
import { logOutTheUser } from '../../_lib/lib';

export const preFilterMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === types.USER_SET) {
    const { isRoleTenant } = action.user;
    if (isRoleTenant) {
      next(action);
    } else {
      logOutTheUser(
        'You have logged into wrong website. Kindly login into {website}',
        'error',
        'template'
      );
    }
  } else {
    next(action);
  }
};
