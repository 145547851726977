import React, { useState } from 'react';
import { BackButton, PrimaryButton, TextField } from '../../components/AtomComponents';
import apiToUrlMap from '../../ApiMapping';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { LinearProgress } from '@material-ui/core';
import JSONInput from 'react-json-editor-ajrm';
import { localeEn } from '../ConfigTenant/ConfigTenant';
import { checkForSuccesInvocation } from '../../_lib/utils';

const AuctionOnboarding = () => {
  const [tenantId, setTenantId] = useState('');
  const [tenantSystemEmail, setTenantSystemEmail] = useState('');
  const { openSnackBar, fetchUrl } = useDataService();

  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);

  const onOnboard = async () => {
    try {
      setProcessState(1);
      startAuctionOnboarding().then(async (data: any) => {
        if (!data.error) {
          try {
            checkForSuccesInvocation(data, setPreviewState, setProcessState);
          } catch (e: any) {
            openSnackBar(
              e.message || 'Something went wrong,Unable to check status',
              eMessageType.error
            );
          }
        } else {
          openSnackBar('Something went wrong', eMessageType.error);
          restart();
        }
      });
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong', eMessageType.error);
    }
  };

  const Wait = () => {
    return (
      <div className="grid-x px-config-wait">
        <div className="small-12">
          <h5>Processing. Please wait...</h5>
          <h6>This process can take 30 seconds or more.</h6>
        </div>
        <div className="small-12">
          <LinearProgress />
        </div>
      </div>
    );
  };

  const restart = () => {
    setProcessState(0);
    setTenantId('');
    setPreviewState(null);
  };

  const startAuctionOnboarding = async () => {
    return await fetchUrl('post', apiToUrlMap.auctionOnboarding, {
      body: {
        tenant_id: tenantId,
        tenant_system_email: tenantSystemEmail,
      },
    });
  };

  return (
    <div className="grid-y">
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Auction Onboarding</h3>
      </div>
      {processState === 0 && (
        <>
          <div className="cell small-6 medium-6 margin-top-1">
            <TextField
              label="Tenant ID"
              size="medium"
              value={tenantId || ''}
              onChange={(e) => setTenantId(e.target.value)}
              placeholder="Tenant ID"
              className="margin-left-1 margin-bottom-2"
              name="Tenant ID"
              key="Tenant ID"
              inputProps={{ maxLength: 60 }}
            />
            <TextField
              label="Tenant System Email"
              size="medium"
              value={tenantSystemEmail || ''}
              onChange={(e) => setTenantSystemEmail(e.target.value)}
              placeholder="Tenant System Email"
              className="margin-left-1 margin-bottom-2"
              name="tenantSystemEmail"
              key="tenantSystemEmail"
              inputProps={{ maxLength: 60 }}
            />
            <PrimaryButton
              className="margin-left-1 margin-top-1 small-6 medium-6"
              disabled={!tenantId.trim().length || !tenantSystemEmail.trim().length}
              onClick={onOnboard}>
              Onboard
            </PrimaryButton>
          </div>
        </>
      )}
      {processState === 1 && <Wait />}
      {processState === 2 && (
        <>
          <div className="margin-bottom-3">
            <JSONInput
              id={'manual-jsonEditor'}
              theme="light_mitsuketa_tribute"
              locale={localeEn}
              placeholder={previewState}
              height="550px"
              width="100%"
              viewOnly={true}
              style={{ body: { fontSize: '16px' } }}
            />
          </div>
          <div className="cell small-6 medium-6 margin-top-1">
            <PrimaryButton onClick={restart}>Restart</PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AuctionOnboarding;
