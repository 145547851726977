import { FormControlLabel } from '@material-ui/core'
import React from 'react'
import { PrimaryCheckbox } from '../../../components/AtomComponents'

export const Resources = (props:any) => {
  return (
    <div className="grid-y">
        {Object.keys(props.resources).length ? (
          Object.keys(props.resources).map((resource) => (
            <div key={resource}>
              {typeof props.resources[resource] === "string" ? (
                resource !== "tenant_id" && (
                  <>
                    <span className="margin-right-2">
                      {resource.toUpperCase()}
                    </span>
                    <FormControlLabel
                      control={
                        <PrimaryCheckbox
                          name={resource}
                          checked={!!props.tenantDataState[resource]}
                          onChange={(e) =>
                            props.updateDecommissionResource(e, props.resources[resource])
                          }
                        />
                      }
                      label={props.resources[resource]}
                    />
                  </>
                )
              ) : (
                <></>
              )}
              {typeof props.resources[resource] === "object" &&
              props.resources[resource].length ? (
                <>
                  <span className="margin-right-2">
                    {resource.toUpperCase()}
                  </span>
                  {props.resources[resource].map((data: string) => (
                    <>
                      <FormControlLabel
                        key={data}
                        control={
                          <PrimaryCheckbox
                            name={resource}
                            checked={
                              !!props.tenantDataState[resource]?.includes(data)
                            }
                            onChange={(e) =>
                              props.updateDecommissionResource(e, data, true)
                            }
                          />
                        }
                        label={data}
                      />
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
  )
}
