import React from "react";
import JSONInput from "react-json-editor-ajrm";
import {
  BackButton,
  PrimaryButton,
  TextField,
} from "../../components/AtomComponents";
import useDataService from "../../hooks/useDataService";
import { eMessageType } from "../../types/IMessageType";
import { fetchData } from "../../_lib/lib";
import { localeEn } from "../ConfigTenant/ConfigTenant";
import { Wait } from "../SchemaSync/SchemaSync";
import "../ConfigTenant/ConfigTenant.scss";
import { Box } from "@material-ui/core";

export const BuyerDecrypt = () => {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Buyer Encrypt";
  }, []);
  const [encrypted, setEncrypted] = React.useState("");

  const [tenantId, setTenantId] = React.useState("");
  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);
  const { openSnackBar } = useDataService();

  const decrypt = async () => {
    setProcessState(1);
    const res: any = await fetchData(
      "post",
      `buyer/decrypt`,
      {
        body: {
          tenant_id: tenantId,
          encrypted,
        },
      },
      false,
      "https://74s3piukt0.execute-api.us-east-1.amazonaws.com/test"
    );
    if (!res.error) {
      setPreviewState(res);
      setTimeout(() => {
        setProcessState(2);
      }, 1);
      openSnackBar("Decryption completed", eMessageType.success);
    } else {
      restart();
      openSnackBar(
        res.message ? res.message : "Something went wrong",
        eMessageType.error
      );
    }
  };

  const restart = () => {
    setProcessState(0);
    setPreviewState(null);
  };
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Buyer Decrypt</h3>
      </div>
      <Box>
        {processState === 0 && (
          <div className="small-12 margin-top-3">
            <TextField
              label="Encrypted Buyer Name"
              size="small"
              value={encrypted}
              onChange={(e) => {
                setEncrypted(e.target.value);
              }}
              placeholder="Encrypted Buyer Name"
              className="margin-right-1"
            />

            <TextField
              label="Tenant ID"
              size="small"
              value={tenantId}
              onChange={(e) => {
                setTenantId(e.target.value);
              }}
              placeholder="Tenant ID"
              className="margin-right-1"
            />
            <PrimaryButton
              disabled={!encrypted.length || !tenantId.length}
              onClick={decrypt}
            >
              Decrypt
            </PrimaryButton>
          </div>
        )}
        {processState === 1 && <Wait />}
        {processState === 2 && (
          <JSONInput
            id="jsonEditor"
            theme="light_mitsuketa_tribute"
            locale={localeEn}
            placeholder={previewState}
            height="550px"
            width="100%"
            viewOnly={true}
            style={{ body: { fontSize: "16px" } }}
          />
        )}
      </Box>
    </>
  );
};
