import { useState, useEffect, useRef } from 'react';
import {
  filterLocalStorageName,
  IAdvanceFilterEngineRes,
  IAdvanceFilterEventFn,
  IAdvanceFilterFnObj,
  IAdvanceFilterHookParams,
  IFilterCountsState,
  IFiltersApplied,
  ISaveFilters,
} from '../types/IFilterEngine';
import { advanceFilterEngine } from '../_lib/lib';

let isSearchUpdatedRecently: boolean = false;

function useAdvanceFilter({
  requiredFilters,
  updateTabCount,
  outOfStockField,
  advanceFilter,
  chipsEvent,
  pxGridRef,
  viewName,
  settings,
  items,
}: IAdvanceFilterHookParams) {
  const [itemsState, setItemsState] = useState<any>(null);
  const [filterCountState, setFilterCountState] = useState<IFilterCountsState>();
  const [searchTerm, setSearchTerm] = useState('');
  const [tmpSearchTerm, setTmpSearchTerm] = useState('');
  const [filtersApplied, setFiltersApplied] = useState<IFiltersApplied>();
  const filtersAppliedRef = useRef(filtersApplied);
  const [advanceFilterState, setAdvanceFilterState] = useState<IAdvanceFilterFnObj>({});

  useEffect(() => {
    filtersAppliedRef.current = filtersApplied;
  }, [filtersApplied]);

  const event: IAdvanceFilterEventFn = ({ type, payload }) => {
    switch (type) {
      case 'clear': {
        if (pxGridRef?.current?.showLoadingOverlay) pxGridRef.current.showLoadingOverlay();
        setSearchTerm('');
        setTmpSearchTerm('');
        break;
      }
      case 'save-dialog': {
        if (payload?.attribute === 'buyerFilter') {
          // refresh buyer filter
        }
        if (pxGridRef?.current?.showLoadingOverlay) pxGridRef.current.showLoadingOverlay();
        break;
      }
      case 'delete-chip': {
        if (pxGridRef?.current?.showLoadingOverlay) pxGridRef.current.showLoadingOverlay();
        break;
      }
      case 'tab-change': {
        if (pxGridRef?.current?.showLoadingOverlay) pxGridRef.current.showLoadingOverlay();
        break;
      }
      case 'toggle': {
        if (pxGridRef?.current?.showLoadingOverlay) pxGridRef.current.showLoadingOverlay();
        break;
      }
    }

    if (chipsEvent) {
      chipsEvent({ type, payload });
    }
  };

  const resetFilter = () => {
    if (!requiredFilters) return;
    setInitialFilters(getSanitizedFiltersApplied(requiredFilters), advanceFilter);
    setSearchTerm('');
    setTmpSearchTerm('');
    setItemsState(null);
  };

  const setReqFilterAndResetRest = (filter: string, value: string) => {
    const reqFilter = getSanitizedFiltersApplied(requiredFilters);
    const advFilter = advanceFilter;
    if (reqFilter[filter]) reqFilter[filter] = value;
    setInitialFilters(reqFilter, advFilter);
    const searchStr = filter === 'search' ? value : '';
    setSearchTerm(searchStr);
    setTmpSearchTerm(searchStr);
    setItemsState(null);
  };

  const setInitialFilters = (initialChips: any, additionalFilters: any) => {
    setFiltersApplied(Object.assign({}, initialChips));
    setAdvanceFilterState(additionalFilters);
  };

  // INITIAL STATE
  useEffect(() => {
    // Run one time
    if (settings && !filtersApplied && requiredFilters && advanceFilter) {
      const savedFilters = JSON.parse(localStorage.getItem(filterLocalStorageName) || '{}');
      let filters: ISaveFilters = getSanitizedFiltersApplied(requiredFilters);
      if (savedFilters[viewName]) {
        filters = savedFilters[viewName].filtersApplied;
      }
      setInitialFilters(filters, advanceFilter);
      // change the required filter to object
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersApplied, requiredFilters, advanceFilter]);

  // FILTER
  useEffect(() => {
    // If stock and chipsInUse are available, allow filtering
    if (items && filtersApplied) {
      setTimeout(() => {
        if (
          filterCountState &&
          filtersApplied[outOfStockField] &&
          Object.keys(filterCountState.absolute[outOfStockField]).length === 0
        )
          return;
        performance.mark('filteringData:start');
        advanceFilterEngine({
          advanceFilters: advanceFilterState,
          searchTermState: searchTerm?.trim(),
          itemsList: items,
          outOfStockField,
          view: viewName,
          filtersApplied,
          settings,
        }).then((res: IAdvanceFilterEngineRes) => {
          if (pxGridRef?.current?.hideLoadingOverlay && res.items?.length === itemsState?.length)
            pxGridRef.current.hideLoadingOverlay();

          if (
            res.searchTerm &&
            res.searchTerm?.trim()?.toLowerCase() !== searchTerm?.trim()?.toLowerCase()
          )
            setTmpSearchTerm(res.searchTerm);

          if (!res.items?.length && pxGridRef?.current?.showNoRowsOverlay)
            pxGridRef.current.showNoRowsOverlay();
          setItemsState(res.items.slice(0));
          if (updateTabCount) updateTabCount(res.tabStats);
          const updatedFilterCounts = {
            ...res.filterCounts,
          };
          if (!!searchTerm) {
            updatedFilterCounts.itemSearched =
              +(filterCountState?.itemSearched || 0) + (isSearchUpdatedRecently ? 1 : 0);
            isSearchUpdatedRecently = false;
          }
          setFilterCountState(updatedFilterCounts);
          performance.mark('filteringData:end');
          setTimeout(() => {
            console.log(
              performance.measure('filteringData', 'filteringData:start', 'filteringData:end')
            );
          }, 0);
        });
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, filtersApplied, searchTerm]);

  useEffect(() => {
    isSearchUpdatedRecently = true;
  }, [searchTerm]);

  return {
    // search terms
    tmpSearchTerm,
    setTmpSearchTerm,
    searchTerm,
    setSearchTerm,
    // filters applied state
    setFiltersApplied,
    filtersApplied,
    filtersAppliedRef,
    // advance filters
    setAdvanceFilter: setAdvanceFilterState,
    advanceFilters: advanceFilterState,
    // reset filter function
    resetFilter,
    setReqFilterAndResetRest,
    // items
    itemsState,
    setItemsState,
    chipsEvent: event,
    filterCountState,
  };
}

const getSanitizedFiltersApplied = (requiredFilters: any) => {
  const sanitizedRequiredFilter: any = {};
  Object.keys(requiredFilters).forEach((val) => {
    let res: any = requiredFilters[val];
    if (Array.isArray(requiredFilters[val])) {
      res = {};
      requiredFilters[val].forEach((resVal: string) => {
        res[resVal] = true;
      });
    }
    sanitizedRequiredFilter[val] = res;
  });
  return sanitizedRequiredFilter;
};

export default useAdvanceFilter;
