import React from "react";
import { PrimaryButton, TextField } from "../../../components/AtomComponents";

export function Start(props: any) {
  const handleChange = (e: any) => {
    props.setTenantDataState({
      ...props.tenantDataState,
      [e.target.name]: e.target.value,
    });
    return true;
  };

  return (
    <>
      <div className="small-12">
        <h4>Fill out detail to start decommission</h4>
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Tenant ID"
          size="small"
          className="textField"
          name="tenant_id"
          helperText="Ex.: T01"
          value={props.tenantDataState?.tenant_id}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 margin-top-3 margin-bottom-3">
        <PrimaryButton onClick={props.startProcess}>
          Start decommission flow
        </PrimaryButton>
      </div>
    </>
  );
}

export default Start;
