export interface IFilterOptions {
  [key: string]: boolean;
}

export type priceOfOfferClearingFilterStrategy = 'belowList' | 'aboveLow' | 'belowLow';
export type typeOfOfferClearingFilterStrategy = 'percent' | 'cost';
export interface IOfferRangeFilterState {
  start: number;
  end: number;
  type: typeOfOfferClearingFilterStrategy;
  price?: priceOfOfferClearingFilterStrategy;
}
export interface IDateFilterStrategy {
  startDate: string;
  endDate: string;
}

export interface IOfferRangeFilterStategy {
  start: number;
  end: number;
  type: typeOfOfferClearingFilterStrategy;
  price: priceOfOfferClearingFilterStrategy;
  priceSelected: 'low' | 'list';
  priceType: 'below' | 'above';
}
export interface ITopOffersFilterStategy {
  qty: number;
}

export interface IItemsAvailabilityFilterStategy {
  qty: string | number;
  type: 'below' | 'above';
}

export interface IAddedToStockFilterStategy {
  qty: string | number;
  type: 'atLeast' | 'noMoreThan';
  strQty?: string;
  symbol?: string;
}

export interface IDaysToSellFilterStategy {
  from: number;
  to: number;
}

export interface IFiltersApplied {
  [key: string]:
    | {
        [key: string]: boolean;
      }
    | any;
}

export type IFilterDialogOpenFn = (info: IDialogInfo) => void;
export interface IFilterSaveParams {
  title: string;
  attribute: string;
  selectedFilters: {
    [key: string]: any;
  };
  checkedFilters?: number;
}

export interface IClearFiltersParams {
  title: string;
  attribute: string;
}

export interface ICloseFilterDialogParams {
  attribute: string;
}

export interface IDeletePreviousFilter {
  attribute: string;
  updatedFiltersApplied: IFiltersApplied;
  filterUISeqSet: Set<string>;
  updatedFilterCounts: any;
}

export type IDeletePreviousFilterFn = (params: IDeletePreviousFilter) => void;

export type IGetFilterOptionCount = (attribute: string, option: any) => number;
export type IGetFilterOptionLabel = (attribute: string, option: any) => string;

export interface IDialogInfo {
  title: string;
  attribute: any;
  from?: string;
}

export type INewFilterTabType = 'preset' | 'custom';

export interface IFilterCount {
  total: number;
  checked: number;
}

export type IAdvanceFilterFn = (items: any, filtersApplied: IFiltersApplied) => boolean;

export interface IAdvanceFilterFnObj {
  [key: string]: IAdvanceFilterFn;
}
export interface IFilterDialog {
  // all the dialog options with selection
  presetOptionObj: IFilterOptions;
  // custom options object (could mould according to filter)
  customOptionObj: any;
  // dialog information
  title: string;
  attribute: string;
  dialogTitle?: string;
  // open dialog
  openDialog: (params: IDialogInfo) => void;
  // close dialog
  closeDialog: (params: ICloseFilterDialogParams) => void;
  // clear filter
  clearFilter: (params: IClearFiltersParams) => void;
  // save dialog
  saveFilter: (params: IFilterSaveParams) => void;
  // get count and label
  getCount: IGetFilterOptionCount;
  getLabel: IGetFilterOptionLabel;
  // set advance filter
  setAdvanceFilter: (fn: IAdvanceFilterFn, attribute: string) => void;
  // any other information if we need to pass to the filter
  filterState: any;
}

export interface IAdvanceFilterEventParams {
  type: string;
  attr?: any;
  payload?: any;
}

export type IAdvanceFilterEventFn = (params: IAdvanceFilterEventParams) => void;

export interface IAdvanceFilterHookParams {
  requiredFilters: {
    [key: string]: any | Array<string>;
  } | null;
  updateTabCount?: (countObj: any) => void;
  advanceFilter: IAdvanceFilterFnObj;
  pxGridRef: any;
  outOfStockField:
    | 'totalQuantityOnHand'
    | 'totalQuantityAvailable'
    | 'quantityAvailable'
    | 'currentAvailableQuantity'
    | 'invalidKey';
  viewName: IScreenNames;
  settings: any;
  items: any;
  chipsEvent?: IAdvanceFilterEventFn;
}

export interface IFiltersUI {
  children?: any;
  excludeOutOfStock?: any;
  filterCountState?: IFilterCountsState;
  chipsEvent: IAdvanceFilterEventFn;
  chipsDataState: any;
  requiredFilters: Array<string>;
  optionalFilters?: Array<string>;
  customFilters?: Array<string>;
  filtersApplied: IFiltersApplied;
  setFiltersApplied: (param: IFiltersApplied) => void;
  advanceFilters: any;
  setAdvanceFilter: any;
  resetFilter: any;
}

export interface IAdvanceFilterEngineParams {
  itemsList: Array<any>;
  searchTermState?: string | null;
  filtersApplied: IFiltersApplied;
  outOfStockField: string;
  advanceFilters: any;
  settings: any;
  view: any;
}

export interface IFilterCountsState {
  absolute: any;
  filtered: any;
  searchedAbsolute: any;
  includeOutOfStock: boolean;
  itemSearched: number;
}

export interface IAdvanceFilterEngineRes {
  items: Array<any>;
  filterCounts: IFilterCountsState;
  itemProperties: any;
  tabStats: any;
  searchTerm?: string;
}

export type IAdvanceFilterEngine = (
  params: IAdvanceFilterEngineParams
) => Promise<IAdvanceFilterEngineRes>;

export interface filterCounts {
  chipCount: any; // tracks all the currently selected filter count
  filteredCount: any; // tracks all currently filtered data count
  absolute: any; // absolute count of all the data there exists
}

export interface ISaveFilterOptions {
  searchText: string;
  filtersApplied: IFiltersApplied;
}

export type IScreenNames =
  | 'pricing'
  | 'channel'
  | 'inventory'
  | 'offerClearing'
  | 'orders'
  | 'ordersAdd'
  | 'clients'
  | 'invoice'
  | 'clientForms';

export type ISaveFilters = {
  [screenName in IScreenNames]: ISaveFilterOptions;
};

export const filterLocalStorageName: Readonly<string> = 'filtersApplied';
export const allTrueKey: Readonly<string> = 'allTrue';
export const ordersFilters: Array<string> = [
  'salesRepId',
  'paymentStatus',
  'createDate',
  'updateDate',
  'fulfillmentStatus',
  'warehouse',
];

// class SaveFilterOptions {
//   public options: ISaveFilterOptions;
//   constructor(options: ISaveFilterOptions){
//     this.options = options;
//   }
// }
export const invoiceFilter: Array<string> = [
  'client',
  'referrer',
  'invoiceDate',
  'invoiceStatus',
  'dueDate',
  'billingPeriodStartDate',
  'invoicedAmount',
];

export interface IInvoiceAmountFilterState {
  start: number;
  end: number;
}