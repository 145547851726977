import React from 'react';
import { PrimaryButton, PrimaryIconButton, WavingHandIcon } from '../AtomComponents';
import { TextField } from '@material-ui/core';
import { FrontHand } from '../../_lib/icons';
import { connect } from 'react-redux';
import HtmlContent from '../AtomComponents/UnSanitizedShadowDom';

interface IClientAccountProps {
  isNotFound: boolean;
  isError: boolean;
  onChangeWebsite: (value: string | null, actionToDo: string) => void;
  optionSelected: string;
  userDetails: any;
  configState: any;
}

const ClientAccount = ({
  isNotFound,
  isError,
  onChangeWebsite,
  optionSelected,
  userDetails,
  configState,
}: IClientAccountProps) => {
  if (optionSelected === 'no') return <NotYetClient userDetails={userDetails} />;

  if (optionSelected === 'yes' && isNotFound)
    return (
      <ClientAccountNotFound
        isError={isError}
        onChangeWebsite={onChangeWebsite}
        userDetails={userDetails}
        configState={configState}
      />
    );

  return <FoundClient userDetails={userDetails} />;
};

const NotYetClient = ({ userDetails }: any) => {
  return (
    <div className="padding-2">
      <HtmlContent htmlString={userDetails?.textFields.acceptanceHtmlTemplateNonPXClient} />
    </div>
  );
};

const FoundClient = ({ userDetails }: any) => {
  return (
    <div className="found-client">
      <HtmlContent htmlString={userDetails?.textFields.acceptanceHtmlTemplatePXClient} />
    </div>
  );
};

const ClientAccountNotFound = ({
  isError,
  onChangeWebsite,
  configState,
}: Omit<IClientAccountProps, 'isNotFound' | 'optionSelected'>) => {
  const onWebsiteUrlChange = (e: any) => onChangeWebsite(e.target.value, 'updateUrl');
  const onValidateUrl = () => onChangeWebsite(null, 'locateUrl');
  const onSubmitForm = (event: any) => {
    event.preventDefault();
    onValidateUrl();
  };

  return (
    <div className="client-not-found">
      <div className="grid-x align-middle">
        <PrimaryIconButton>
          <WavingHandIcon className="padding-0" />
        </PrimaryIconButton>
        <h6 className="px-text-bold margin-0">Let’s Locate Your PhoneX Client Account</h6>
      </div>
      <div className="margin-left-1">
        <p className="px-text-description">
          Copy the URL of your PhoneX website from your browser's address bar and paste it into the
          field below.
        </p>
        {isError && (
          <div className="show-icon-text error-message">
            <FrontHand
              style={{
                color: configState['CSS::px_color_badge_1::phonex_theme_variant'] || '#BE5700',
              }}
            />
            <p className="margin-0">
              Sorry, the URL that you’ve entered does not match a PhoneX Client website. Please
              double-check and try again.
            </p>
          </div>
        )}
        <form className="section-center" onSubmit={onSubmitForm}>
          <TextField
            variant="outlined"
            label="Your PhoneX Website URL"
            onChange={onWebsiteUrlChange}
            error={isError}
          />
          <PrimaryButton type="submit">This is My PhoneX Website URL</PrimaryButton>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    configState: state.configState,
  };
};
export default connect(mapStateToProps)(ClientAccount);
