import React from "react";
import Button from "@material-ui/core/Button";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/AtomComponents";

export function Resume(props: any) {
  const copyClipboard = () => {
    const el: any = document.getElementById("cnameTable");
    const body: any = document.body;
    if (document.createRange && window.getSelection) {
      const range = document.createRange();
      const sel = window.getSelection();
      if (sel) {
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
        document.execCommand("copy");
      }
    } else if (body.createTextRange) {
      const range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    const sel: any = window.getSelection();
    if (sel) {
      if (sel.removeAllRanges) {
        sel.removeAllRanges();
      } else if (sel.empty) {
        sel.empty();
      }
    }
  };

  return (
    <>
      <div className="small-12 margin-bottom-3">
        <h3>{props.statusState.hasOwnProperty('type') && <>{props.statusState.type}</>}</h3>
      </div>

      <div className="small-12 margin-bottom-3">
        <SecondaryButton onClick={copyClipboard}>
          Copy to Clipboard
        </SecondaryButton>
      </div>

      {props.statusState &&
        props.statusState.hasOwnProperty('data') &&
        props.statusState.data.hasOwnProperty('records') && (
          <main id="cnameTable">
            {Object.keys(props.statusState.data.records).map((key: string) => {
              return (
                <React.Fragment key={key}>
                  <div className="small-12 margin-bottom-3">
                    <h4 className="faded">
                      <span className="uppercase">{key}</span> - ID:{' '}
                      {props.statusState.data.records[key].id}
                    </h4>
                    <table className="small-12 px-workflow-table">
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Type</td>
                          <td>Value</td>
                        </tr>
                      </thead>
                      <tbody>
                        {props.statusState &&
                          props.statusState.data.records[key].records &&
                          props.statusState.data.records[key].records.map((rec: any) => {
                            return (
                              <tr key={rec.Value}>
                                <td>{rec.Name}</td>
                                <td>{rec.Type}</td>
                                <td>{rec.Value}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {Array.isArray(props.statusState.data.records[key]?.['records_dr']) &&
                    props.statusState.data.records[key]['records_dr'].length > 0 && (
                      <div className="small-12 margin-bottom-3">
                        <h4 className="faded">
                          <span className="uppercase">{key}</span> DR - ID:{' '}
                          {props.statusState.data.records[key].id}
                        </h4>
                        <table className="small-12 px-workflow-table">
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Type</td>
                              <td>Value</td>
                            </tr>
                          </thead>
                          <tbody>
                            {props.statusState.data.records[key]['records_dr'].map((rec: any) => {
                              return (
                                <tr key={rec.Value}>
                                  <td>{rec.Name}</td>
                                  <td>{rec.Type}</td>
                                  <td>{rec.Value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                </React.Fragment>
              );
            })}
          </main>
        )}

      <div className="small-12 margin-top-1">
        <PrimaryButton onClick={props.resumeProcess}>Resume</PrimaryButton>
      </div>
      {props.processState !== 1 && (
        <div className="small-12">
          <Button
            variant="contained"
            className="margin-top-3"
            onClick={props.restartProcess}
          >
            Go to the beginning
          </Button>
        </div>
      )}
    </>
  );
}

export default Resume;
