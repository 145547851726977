import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ClientsStatus from '../../components/Clients/ClientsStatus';
import SearchField from '../../components/SearchField/SearchField';
import Progress from '../../components/Progress/Progress';

import useDataService from '../../hooks/useDataService';
import useAdvanceFilter from '../../hooks/useAdvanceFilter';

import apiToUrlMap from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { IClients } from '../../types/IClients';

import { isEmpty } from '../../_lib/utils';
import { dataFormatting } from '../../_lib/lib';

import './Clients.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clientFilterSet } from '../../redux/actions/clientsAction';
import { GetApp } from '@material-ui/icons';
import { TertiaryButton } from '../../components/AtomComponents';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);
const Clients = () => {
  const [clientList, setClientList] = useState();
  const pxGridRef = useRef<any>();
  const history = useHistory();

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [totalAwaitingPayment, setTotalsAwaitingPayment] = useState(0);
  const { fetchUrl, openSnackBar, exportData } = useDataService();
  const defaultFilter = useSelector((state: any) => state.clientSortState);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Clients';
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClients = async () => {
    try {
      const clients = await fetchUrl('get', apiToUrlMap.getClients);
      setClientList(clients);
    } catch (e) {
      openSnackBar('Something went wrong', eMessageType.error);
    }
  };

  const handleRowClicked = (params: RowClickedEvent) => {
    params.event?.preventDefault();
    // prevent going if something is selected which means the user is trying to select
    if (document.getSelection()?.toString()) return;
    history.push({
      pathname: `/clients/${params.data.tenantId}`,
      search: '?tab=0',
    });
  };

  const { tmpSearchTerm, setTmpSearchTerm, setSearchTerm, itemsState } = useAdvanceFilter({
    requiredFilters: [],
    advanceFilter: {},
    outOfStockField: 'invalidKey',
    pxGridRef,
    settings: {},
    items: clientList,
    viewName: 'clients',
  });

  //#region >> grid col def and options >>
  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      setGridAPI(params.api);
    }
  };

  useEffect(() => {
    if (!history.location.search) return;
    // decode the string
    // const decodedFilters: any = {};
    let filtersFromURL = new URLSearchParams(history.location.search);
    if (!filtersFromURL.toString()) return;
    filtersFromURL.forEach((value: string, key: string) => {
      if (key === 'search') {
        const searchText = !!value
          ? value.split(':')[1]
            ? value.split(':')[1]
            : value.split(':')[0]
          : '';
        setTmpSearchTerm(searchText);
        setSearchTerm(searchText);
        return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!!defaultFilter.search) return;
    setTmpSearchTerm(defaultFilter.search);
    setSearchTerm(defaultFilter.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // create query
    let queryObject: any = {};
    if (tmpSearchTerm) {
      queryObject['search'] = tmpSearchTerm;
    }
    let queryString = new URLSearchParams(queryObject).toString();
    if ((!queryString && !history.location.search) || '?' + queryString === history.location.search)
      return;
    dispatch(clientFilterSet({ search: tmpSearchTerm }));
    history.push({
      search: queryString,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpSearchTerm]);

  useEffect(() => {
    if (gridAPI && itemsState) {
      let columnDefs: ColDef[] | ColGroupDef[] = [
        {
          headerName: 'Client Name',
          field: 'clientName',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return !!params.value ? params.value : '--';
          },
        },
        {
          headerName: 'Official Legal Name',
          field: 'officialLegalName',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return !!params.value ? params.value : '--';
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          cellRendererFramework: (params: {
            value: 'Active' | 'Trial Period' | 'Deactivated' | 'Onboarding';
            data: IClients;
          }) => {
            return <ClientsStatus label={params.value} dataId="client-status" />;
          },
        },

        {
          headerName: '$ Awaiting Payment',
          field: 'awaitingPayment',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return dataFormatting('currency', params.value);
          },
        },
        {
          headerName: 'Days Awaiting Payment',
          field: 'hoursAwaitingPayment',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return dataFormatting('hours', params.value);
          },
          width: 140,
        },
        {
          headerName: 'Effective Date',
          field: 'tenantSignedDate',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'Trial Expire Date',
          field: 'freeTrialEnd',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'Country',
          field: 'billingCountry',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return !!params.value ? params.value : '--';
          },
        },
        {
          headerName: 'State',
          field: 'billingState',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return !!params.value ? params.value : '--';
          },
        },
        {
          headerName: 'Currency',
          field: 'currency',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return !!params.value ? `${params.value} ${params.data.currencyCode}` : '--';
          },
        },
      ];
      if (!isEmpty(defaultFilter) && defaultFilter.sort?.length) {
        columnDefs = columnDefs.map((column: ColDef) => {
          if (column.field === defaultFilter.sort[0].colId)
            return { ...column, sort: defaultFilter.sort[0].sort };
          return column;
        });
      }
      gridAPI.setColumnDefs(columnDefs);
      gridAPI.setRowData(itemsState);
      setTotalsAwaitingPayment(0);
      itemsState.forEach((clients: IClients) => {
        setTotalsAwaitingPayment((prev) => prev + clients.awaitingPayment);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState, gridAPI, defaultFilter?.sort]);

  const gridOptions: GridOptions = {
    headerHeight: 32,
    defaultColDef: {
      flex: 1,
      resizable: true,
      minWidth: 150,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      cellStyle: {
        wordBreak: 'normal',
        height: '100%',
      },
    },
    getRowNodeId: function (data: IClients) {
      return data.tenantId;
    },
    getRowHeight: () => 54,
    onRowClicked: handleRowClicked,
    animateRows: false,
    singleClickEdit: true,
    suppressAggFuncInHeader: true,
    stopEditingWhenGridLosesFocus: true,
    groupDefaultExpanded: -1,
    suppressMovableColumns: true,
    suppressContextMenu: true,
    // grid scroll code
    debounceVerticalScrollbar: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    rowBuffer: 5,
    onSortChanged(event) {
      dispatch(clientFilterSet({ sort: event.api.getSortModel() }));
    },
    loadingOverlayComponentFramework: () => (
      <div className="px-grid-loader">
        <div className="px-circular-loader"></div>
        <h3 className="text-center margin-top-1">Loading...</h3>
      </div>
    ),
  };

  const downloadClients = async () => {
    try {
      setLoading(true);
      await exportData({
        url: apiToUrlMap.downloadClient,
        method: 'GET',
      });
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable download invoices',
        eMessageType.error
      );
    } finally {
      setLoading(false);
    }
  };

  if (!itemsState || isEmpty(clientList))
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
    
  return (
    <div className="px-clients">
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="client-header">
        <h1 className="margin-top-2 margin-bottom-2">Clients</h1>
        <TertiaryButton onClick={downloadClients} className="export-button">
          <GetApp className="margin-right-1" />
          Export
        </TertiaryButton>
      </div>
      <h4>
        <span className="text-faded">Total Awaiting Payments:</span>
        <span className="margin-left-3 bold">
          {dataFormatting('currency', totalAwaitingPayment)}
        </span>
      </h4>
      <div className="margin-top-1">
        <SearchField
          tmpSearchTermState={tmpSearchTerm}
          setTmpSearchTermState={setTmpSearchTerm}
          setSearchTermState={setSearchTerm}
          placeholder="Search"
        />
      </div>
      <div className="ag-theme-alpine px-height-100vh margin-top-2" data-id="clientTable">
        <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
      </div>
    </div>
  );
};

export default Clients;
