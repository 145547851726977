import React, { useEffect, useRef, useState } from 'react';

import SearchField from '../../components/SearchField/SearchField';

import useDataService from '../../hooks/useDataService';
import useAdvanceFilter from '../../hooks/useAdvanceFilter';

import apiToUrlMap from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { IInvoice, IInvoiceStatusInfo } from '../../types/IInvoice';

import InvoiceGrid from '../../components/Invoice/InvoiceGrid';
import StatusFilter, { statuses } from '../../components/Invoice/StatusFilter';
import { Divider } from '@material-ui/core';
import {
  billingPeriodStartDateFilter,
  commonDateFormat,
  dueDateFilter,
  getDateFilterLabel,
  getFilteredDates,
  invoiceDateFilter,
} from '../../components/Filters/FilterDate/FilterDate';
import AllRequiredFilters from '../../components/Filters/AllRequiredFilters';
import { generateLabelKey } from '../../_lib/lib';
import { invoiceFilter as invoiceFilterArr } from '../../types/IFilterEngine';
import { invoicedAmountFilter } from '../../components/Filters/FilterInvoiceAmount/FilterInvoiceAmount';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { GetApp } from '@material-ui/icons';
import { TertiaryButton } from '../../components/AtomComponents';
import './Invoices.scss';
import { getMessageForPayment } from '../../_lib/utils';

const Invoice = () => {
  const pxGridRef = useRef<any>();

  const [invoice, setInvoice] = useState<IInvoice[]>([]);
  const { fetchUrl, openSnackBar, exportData } = useDataService();
  const history = useHistory();

  const [statusFilterInfo, setStatusFilterInfo] = useState<IInvoiceStatusInfo>();
  const [requiredFilters, setRequiredFilters] = useState<any>();
  const [invoiceFilters, setInvoiceFilters] = useState<any>();

  const [settings, setSettings] = useState<any>();

  const [isLoading, setLoading] = useState(false);

  const getResetFilterObject = () => {
    return {
      client: { label: 'ALL' },
      referrer: { label: 'ALL' },
      invoiceDate: { label: 'ALL' },
      invoiceStatus: { label: 'ALL' },
      dueDate: { label: 'ALL' },
      billingPeriodStartDate: { label: 'ALL' },
      invoicedAmount: { label: 'ALL' },
    };
  };

  const intialFilterState = () => {
    return {
      ...getResetFilterObject(),
      invoiceStatus: { AWAITING_PAYMENT: true, OVERDUE: true },
    };
  };

  useEffect(() => {
    if (!requiredFilters && settings?.invoiceStatus && invoice) {
      setRequiredFilters(intialFilterState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, settings?.invoiceStatus]);

  const getInvoices = async () => {
    try {
      setLoading(true);
      const invoices = await fetchUrl('get', apiToUrlMap.getAllInvoices);

      setInvoice(invoices);
    } catch (e) {
      openSnackBar('Something went wrong', eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusCount = () => {
    const statusCount = {
      OVERDUE: { count: 0, pending: 0 },
      AWAITING_PAYMENT: { count: 0, pending: 0 },
      PAID: { count: 0, pending: 0 },
      WRITTEN_OFF: { count: 0, pending: 0 },
      VOIDED: { count: 0, pending: 0 },
      PAYMENT_FAILED: { count: 0, pending: 0 },
    };
    const client = new Set<string>();
    const referrer = new Set<string>();
    if (invoice)
      invoice.forEach((invoice: IInvoice) => {
        client.add(invoice.client);
        referrer.add(invoice.referrer);
        if (Object.keys(statusCount).includes(invoice.invoiceStatus)) {
          statusCount[invoice.invoiceStatus].count += 1;
          statusCount[invoice.invoiceStatus].pending =
            statusCount[invoice.invoiceStatus].pending + invoice.amountOwed;
        }
        if (invoice.cardPaymentStatus === 'FAILURE') {
          statusCount['PAYMENT_FAILED'].count += 1;
          statusCount['PAYMENT_FAILED'].pending += invoice.amountOwed;
        }
      });
    setSettings({
      ...settings,
      invoiceStatus: Object.keys(statusCount),
      client: Array.from(client),
      referrer: Array.from(referrer),
    });
    setStatusFilterInfo(statusCount);
  };

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Invoices';
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    tmpSearchTerm,
    setTmpSearchTerm,
    setSearchTerm,
    itemsState,
    setItemsState,
    filtersApplied,
    setFiltersApplied,
    advanceFilters,
    chipsEvent,
    filterCountState,
  } = useAdvanceFilter({
    requiredFilters: requiredFilters ? requiredFilters : null,
    advanceFilter: {
      dueDate: dueDateFilter,
      invoiceDate: invoiceDateFilter,
      billingPeriodStartDate: billingPeriodStartDateFilter,
      client: (item, filtersApplied) => {
        if (!filtersApplied.client || filtersApplied.client.label === 'ALL') return true;
        return filtersApplied.client[item.client];
      },
      referrer: (item, filtersApplied) => {
        if (!filtersApplied.referrer || filtersApplied.referrer.label === 'ALL') return true;
        if (!item.referrer) return filtersApplied.referrer['(Blanks)'];
        return filtersApplied.referrer[item.referrer];
      },
      invoiceStatus: (item, filtersApplied) => {
        if (!filtersApplied.invoiceStatus || filtersApplied.invoiceStatus.label === 'ALL')
          return true;
        if (filtersApplied.invoiceStatus.PAYMENT_FAILED) {
          if (item.cardPaymentStatus === 'FAILURE') return true;
        }
        return !!filtersApplied.invoiceStatus[item.invoiceStatus];
      },

      invoicedAmount: invoicedAmountFilter,
    },
    outOfStockField: 'invalidKey',
    pxGridRef,
    settings: settings,
    items: invoice,
    viewName: 'invoice',
  });

  useEffect(() => {
    setFiltersApplied(invoiceFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceFilters]);

  useEffect(() => {
    setInvoiceFilters(requiredFilters);
  }, [requiredFilters]);

  useEffect(() => {
    getStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    if (!invoiceFilters) return;
    // create query
    let queryObject: any = {};
    if (tmpSearchTerm) {
      queryObject['search'] = tmpSearchTerm;
    }

    Object.keys(invoiceFilters).forEach((val) => {
      const selectedVals = Object.keys(invoiceFilters[val]).filter(
        (filterKey) => invoiceFilters[val][filterKey] && filterKey !== 'label'
      );
      if (selectedVals.length) {
        if (val === 'dueDate' || val === 'invoiceDate' || val === 'billingPeriodStartDate') {
          queryObject[val] = `${
            invoiceFilters[val].startDate?.toLocaleString('en-US').split(',')[0]
          },${invoiceFilters[val].endDate?.toLocaleString('en-US').split(',')[0]}`;
        } else if (val === 'invoicedAmount') {
          queryObject[val] = `${invoiceFilters[val].start},${invoiceFilters[val].end}`;
        } else queryObject[val] = `${selectedVals.join(',')}`;
      }
    });
    let queryString = new URLSearchParams(queryObject).toString();
    if ((!queryString && !history.location.search) || '?' + queryString === history.location.search)
      return;
    history.push({
      search: queryString,
    });
    if (Object.keys(invoiceFilters).length) setFiltersApplied(invoiceFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceFilters, tmpSearchTerm]);

  useEffect(() => {
    if (!history.location.search) return;
    if (filtersApplied) return;
    // decode the string
    const decodedFilters: any = {};
    let filtersFromURL = new URLSearchParams(history.location.search);
    if (!filtersFromURL.toString()) return;
    filtersFromURL.forEach((value: string, key: string) => {
      if (key === 'search') {
        const searchText = !!value
          ? value.split(':')[1]
            ? value.split(':')[1]
            : value.split(':')[0]
          : '';
        setTimeout(() => {
          setTmpSearchTerm(searchText);
          setSearchTerm(searchText);
        }, 0);

        return;
      }

      decodedFilters[key] = {};
      value.split(',').forEach((val, index) => {
        if (key === 'invoiceDate' || key === 'dueDate' || key === 'billingPeriodStartDate') {
          switch (index) {
            case 0:
              decodedFilters[key].startDate = val;
              break;
            case 1:
              decodedFilters[key].endDate = val;
              break;
            case 2:
              decodedFilters[key].optionId = val;
          }
        } else if (key === 'invoicedAmount') {
          switch (index) {
            case 0:
              decodedFilters[key].start = val;
              break;
            case 1:
              decodedFilters[key].end = val;

              if (decodedFilters[key].start && decodedFilters[key].end) {
                decodedFilters[
                  key
                ].label = `$${decodedFilters[key].start} to $${decodedFilters[key].end}`;
              } else {
                decodedFilters[key].label = 'ALL';
              }
              break;
          }
        } else decodedFilters[key][val] = true;
      });
    });

    if (decodedFilters.invoiceDate)
      decodedFilters.invoiceDate = sanitizeDateFilter(decodedFilters.invoiceDate);
    if (decodedFilters.dueDate) decodedFilters.dueDate = sanitizeDateFilter(decodedFilters.dueDate);
    if (decodedFilters.billingPeriodStartDate)
      decodedFilters.billingPeriodStartDate = sanitizeDateFilter(
        decodedFilters.billingPeriodStartDate
      );
    setRequiredFilters({
      ...getResetFilterObject(),
      ...decodedFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const setInvoiceStatusFilter = (invoiceStatusApplied: any) => {
    const invoiceSelectedFilter = { ...invoiceStatusApplied };
    delete invoiceSelectedFilter.label;
    const selectedFilterLen = Object.keys(invoiceSelectedFilter).length;
    if (
      (invoiceStatusApplied.label === 'ALL' && selectedFilterLen === 0) ||
      statuses.length === selectedFilterLen
    )
      setInvoiceFilters({
        ...invoiceFilters,
        invoiceStatus: {
          label: 'ALL',
        },
      });
    else
      setInvoiceFilters({
        ...invoiceFilters,
        invoiceStatus: invoiceSelectedFilter,
      });
  };

  const resetOrderFilters = () => {
    setInvoiceFilters(getResetFilterObject());
    setSearchTerm('');
    setTmpSearchTerm('');
  };

  const sanitizeDateFilter = (decodedDateFilters: any) => {
    let dateLabel = 'Infinity';
    const dates = getFilteredDates(decodedDateFilters);
    decodedDateFilters.startDate = dates.startDate;
    decodedDateFilters.endDate = dates.endDate;
    if (decodedDateFilters.startDate && decodedDateFilters.endDate)
      dateLabel = getDateFilterLabel(
        format(decodedDateFilters.startDate, commonDateFormat),
        format(decodedDateFilters.endDate, commonDateFormat),
        decodedDateFilters.optionId
      );

    if (dateLabel === 'Infinity') {
      dateLabel = 'ALL';
      decodedDateFilters.startDate = undefined;
      decodedDateFilters.endDate = undefined;
      decodedDateFilters.optionId = undefined;
    }

    decodedDateFilters.label = dateLabel;
    return decodedDateFilters;
  };

  const generateFilterLabel = (str: string) => ({
    chipLabel: `${str}:  {label}`,
  });

  const bulkPaidItems = async (invoiceNumbers: string[], status?: string) => {
    try {
      setLoading(true);
      await fetchUrl('POST', apiToUrlMap.updateInvoiceStatus, {
        body: {
          invoiceNumbers: invoiceNumbers,
          status: status ? status : 'PAID',
        },
      });
      setItemsState(undefined);
      getInvoices();
      openSnackBar('Status updated successfully', eMessageType.success);
    } catch (e: any) {
      openSnackBar(e.message || `Unable to Update status`, eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const downloadInvoiceAsXlsx = async () => {
    const invoiceNumbers = itemsState.map((invoice: IInvoice) => invoice.invoiceNumber);
    try {
      setLoading(true);
      await exportData({
        url: apiToUrlMap.downloadInvoicesAsxlsx,
        method: 'POST',
        body: { invoiceNumbers },
      });
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable download invoices',
        eMessageType.error
      );
    } finally {
      setLoading(false);
    }
  };

  const bulkRetryPayment = async (invoiceNumbers: string[]) => {
    try {
      setLoading(true);
      const res = await fetchUrl('POST', apiToUrlMap.bulkRetryPayment, {
        body: { invoiceNumbers },
      });
      await getInvoices();
      const { message, type } = getMessageForPayment(res, true);
      openSnackBar(message || 'Payment Successful', type);
    } catch (e: any) {
      openSnackBar(e.message || 'Payment Failed', eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-invoices px-clients">
      <LoadingDialog isDialogOpen={!itemsState || isLoading} />
      <div className="invoice-header">
        <h1 className="margin-top-2 margin-bottom-2">Invoices</h1>
        <TertiaryButton onClick={downloadInvoiceAsXlsx} className="export-button">
          <GetApp className="margin-right-1" />
          Export
        </TertiaryButton>
      </div>
      <div className="margin-top-1 margin-bottom-1">
        <SearchField
          tmpSearchTermState={tmpSearchTerm}
          setTmpSearchTermState={setTmpSearchTerm}
          setSearchTermState={setSearchTerm}
          placeholder="Search"
        />
      </div>
      {statusFilterInfo && (
        <StatusFilter
          statusInfo={statusFilterInfo}
          appliedInvoiceStatus={filtersApplied?.invoiceStatus}
          setInvoiceStatusFilter={setInvoiceStatusFilter}
        />
      )}
      <Divider className="margin-top-1" />
      <div className="cell small-12 margin-top-1">
        {!!settings && (
          <AllRequiredFilters
            requiredFilters={invoiceFilterArr}
            customFilters={invoiceFilterArr}
            chipsDataState={{
              ...settings,
              attributeLabels: {
                client: 'Client',
                referrer: 'Referrer',
                invoiceDate: 'Invoice Date',
                dueDate: 'Due Date',
                billingPeriodStartDate: 'Billing Period Start Date',
                invoicedAmount: 'Invoiced Amount',
              },
              optionLabels: {
                [generateLabelKey('client')]: settings.client,
                [generateLabelKey('referrer')]: settings.referrer,
              },
              [generateLabelKey('invoicedAmount')]: generateFilterLabel('Invoiced Amount'),
              [generateLabelKey('invoiceDate')]: generateFilterLabel('Invoice Date'),
              [generateLabelKey('dueDate')]: generateFilterLabel('Due Date'),
              [generateLabelKey('client')]: generateFilterLabel('Client'),
              [generateLabelKey('billingPeriodStartDate')]: generateFilterLabel('Billing Period'),
              [generateLabelKey('referrer')]: generateFilterLabel('Referrer'),
            }}
            // list of filters applied
            filtersApplied={invoiceFilters}
            setFiltersApplied={setInvoiceFilters}
            // advanced Filters
            advanceFilters={advanceFilters}
            // Menu (count of items per attribute)
            filterCountState={filterCountState}
            // Chips events
            chipsEvent={chipsEvent}
            // resetFilter
            resetFilter={resetOrderFilters}
          />
        )}
      </div>
      <InvoiceGrid
        itemsState={itemsState}
        view="ALL"
        bulkPaidItems={bulkPaidItems}
        bulkRetryPayment={bulkRetryPayment}
      />
    </div>
  );
};

export default Invoice;
