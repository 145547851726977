import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { BackButton } from '../../components/AtomComponents';
import Progress from '../../components/Progress/Progress';

import apiToUrlMap, { formatString } from '../../ApiMapping';

import useDataService from '../../hooks/useDataService';

import { isEmpty } from '../../_lib/utils';

import { IClientContact } from '../../types/IClientContact';
import { eMessageType } from '../../types/IMessageType';

import './ClientDetails.scss';

const ClientContact = React.lazy(() => import('../../components/ClientDetails/ClientContact'));
const PaymentMethod = React.lazy(() => import('../../components/PaymentMethods/PaymentMethod'));
const Invoice = React.lazy(() => import('../../components/ClientDetails/Invoice'));
const ContractTerms = React.lazy(() => import('../../components/ClientDetails/ContractTerms/ContractTerms'));

const ClientDetailsTabs = [
  {
    id: 0,
    label: 'Contact',
  },
  {
    id: 1,
    label: 'Invoices',
  },
  {
    id: 2,
    label: 'Payment Method',
  },
  {
    id: 3,
    label: 'Contract Terms',
  },
];

const ClientInfo = () => {
  
  const location = useLocation();
  const history = useHistory();

  const { fetchUrl, openSnackBar } = useDataService();

  const pathnames = location.pathname.split('/');

  const [value, setValue] = React.useState<number>(0);
  const [clientContact, setClientContact] = useState<IClientContact>();

  const handleChange = (event:  React.ChangeEvent<{}>, newValue: number) => {
    history.push({ search: `?tab=${newValue}` });
  };

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Client Details';
  }, []);

  // currently loaded customer id
  const id = pathnames[pathnames.length - 1].toString();

  useEffect(() => {
    fetchClientContacts();

    if (location.search) {
      serachParamsbasedTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const serachParamsbasedTab = () => {
    const search = new URLSearchParams(location.search);
    const tabIdFromSearch = search.get('tab');
    if (tabIdFromSearch && [0, 1, 2, 3].includes(+tabIdFromSearch)) {
      setValue(+tabIdFromSearch);
    } else {
      history.push({ search: '?tab=0' });
      setValue(0);
    }
  };

  async function fetchClientContacts() {
    try {
      const contacts: IClientContact = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.clientContactDetails, {
          id: id,
        })
      );
      setClientContact(contacts);
    } catch (e: any) {
      openSnackBar(e.message || `Unable to fetch details about ${id}`, eMessageType.error);
      history.goBack();
    }
  }
  const renderTab = () => {
    switch (value) {
      case 0:
        return <ClientContact id={id} contact={clientContact!} />;
      case 1:
        return <Invoice id={id} />;
      case 2:
        return <PaymentMethod id={id} />;
      case 3:
        return <ContractTerms id={id} />;
      default:
        return <ClientContact id={id} contact={clientContact!} />;
    }
  };

  if (isEmpty(clientContact))
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );

  return (
    <div className="px-clients">
      <div className="px-one-line-data">
        <BackButton />
        <h3>Clients Details / {clientContact?.clientName || '--'}</h3>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="client details tab"
        className="margin-top-1 margin-bottom-1">
        {ClientDetailsTabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} data-id={tab.label} />
        ))}
      </Tabs>
      {value >= 0 && <React.Suspense fallback={<></>}>{renderTab()}</React.Suspense>}
    </div>
  );
};

export default ClientInfo;
