import React from 'react';
import './Homepage.scss';

function Homepage(props: any) {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '{}');
    if (config.siteTitle) document.title = String(config.siteTitle) + ' - Login';
  }, []);

  return (
    <div className="px-v-homepage grid-x">
      <main className="cell small-12 grid-x align-center align-top">{props.children}</main>
    </div>
  );
}

export default Homepage;
