import { Auth } from 'aws-amplify';
const getAwsconfig = (config: any) => {
  return {
    Auth: {
      // REQUIRED - Amazon Cognito Regionmanufacturer,model,capacity
      region: config.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: config.userPoolWebClientId,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      //this will be a server side lookup or client side look up based on the phonex subdomain or the tenant domain in which
      // this website is currently loaded
      //hardcoding to hyla for now
      // clientMetadata: {
      //   role: 'tenant',
      // },
    },
    API: {
      endpoints: [
        {
          name: config.tenantApiName,
          endpoint: config.tenantApiEndPoint,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
          },
        },
      ],
    },
  };
};
export default getAwsconfig;
