import React from 'react';
import { toCapitalize } from '../../_lib/utils';

interface IInvoiceStatusProps {
  label: string;
  isEditable?: boolean;
  onClick?: (lable: string) => void;
  dataId?: string;
}

const InvoiceStatus = ({ label, isEditable, onClick, dataId }: IInvoiceStatusProps) => {
  const getBackgroundColor = () => {
    switch (label) {
      case 'AWAITING_PAYMENT':
        return 'px-background-badge_1';
      case 'OVERDUE':
        return 'px-background-badge_5';
      case 'PAID':
        return 'px-background-badge_2';
      case 'VOIDED':
        return 'px-background-black';
      case 'WRITTEN_OFF':
        return 'px-background-badge_6';
      default:
        return 'px-background-badge_2';
    }
  };

  const handleClick = () => {
    if (isEditable && onClick) {
      onClick(label);
    }
  };
  return (
    <span
      data-id={dataId}
      className={`px-grid-chips ${getBackgroundColor()}`}
      onClick={handleClick}>
      {toCapitalize(label).replace('_', ' ')}
    </span>
  );
};

export default InvoiceStatus;
