import Button from '@material-ui/core/Button';
import React from 'react';
import {
  PrimaryButton,
  PrimaryCheckbox,
  PrimaryRadio,
  TextField,
} from '../../../components/AtomComponents';
import { allowNumbersOnly } from '../../../_lib/utils';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core';

export function Start(props: any) {
  const handleChange = (e: any) => {
    props.setTenantDataState({
      ...props.tenantDataState,
      [e.target.name]: e.target.value,
    });
    return true;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    props.setTenantDataState({
      ...props.tenantDataState,
      [e.target.name]: checked,
    });
    return true;
  };

  return (
    <>
      <div className="small-12">
        <h4>Tenant not found!</h4>
      </div>
      <div className="small-12">
        <h4>Fill out this form to create a new one:</h4>
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Tenant ID"
          size="small"
          className="textField"
          name="tenant_id"
          helperText="Ex.: T01"
          value={props.tenantDataState.tenant_id}
          disabled
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Tenant Name"
          size="small"
          className="textField"
          name="tenant_name"
          helperText="Ex.: WeSell Celular"
          value={props.tenantDataState.tenant_name}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Tenant Short Name"
          size="small"
          className="textField"
          name="tenant_short_name"
          helperText="Ex.: WeSell"
          value={props.tenantDataState.tenant_short_name}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Domain for Tenant Sender Email"
          size="small"
          className="textField"
          name="domain"
          helperText="Ex.: phonexinc.com"
          value={props.tenantDataState.domain}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Execution Name"
          size="small"
          className="textField"
          name="execution_name"
          value={props.tenantDataState.execution_name}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Email"
          size="small"
          className="textField"
          name="email"
          value={props.tenantDataState.email}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Tenant Sender Email"
          size="small"
          className="textField"
          name="tenant_sender_email"
          value={props.tenantDataState.tenant_sender_email}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Phone"
          size="small"
          className="textField"
          name="phone"
          value={props.tenantDataState.phone}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Whatsapp"
          size="small"
          className="textField"
          name="whatsapp"
          value={props.tenantDataState.whatsapp}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="First Name of Tenant Owner"
          size="small"
          className="textField"
          name="first_name"
          value={props.tenantDataState.first_name}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Last Name of Tenant Owner"
          size="small"
          className="textField"
          name="last_name"
          value={props.tenantDataState.last_name}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Site Title"
          size="small"
          className="textField"
          name="site_title"
          value={props.tenantDataState.site_title}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Site Description"
          size="small"
          className="textField"
          name="site_description"
          value={props.tenantDataState.site_description}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Language"
          size="small"
          className="textField"
          name="language"
          value={props.tenantDataState.language}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Region"
          size="small"
          className="textField"
          name="region"
          value={props.tenantDataState.region}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Primary Color"
          size="small"
          className="textField"
          name="px_color_primary"
          helperText="Ex.: #FF00FF"
          value={props.tenantDataState.px_color_primary}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Secondary Color"
          size="small"
          className="textField"
          name="px_color_secondary"
          helperText="Ex.: #FF00FF"
          value={props.tenantDataState.px_color_secondary}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="CSS for px_font_title"
          size="small"
          className="textField"
          name="px_font_title"
          value={props.tenantDataState.px_font_title}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="CSS for px_font_body"
          size="small"
          className="textField"
          name="px_font_body"
          value={props.tenantDataState.px_font_body}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="host1\nhost2\nhost3"
          multiline={true}
          size="small"
          className="textField"
          name="routes"
          value={props.tenantDataState.routes}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Address 1"
          size="small"
          className="textField"
          name="business_address_1"
          value={props.tenantDataState.business_address_1 || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Address 2"
          size="small"
          className="textField"
          name="business_address_2"
          value={props.tenantDataState.business_address_2 || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Address 3"
          size="small"
          className="textField"
          name="business_address_3"
          value={props.tenantDataState.business_address_3 || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Buyer Site"
          size="small"
          className="textField"
          name="business_buyer_site"
          value={props.tenantDataState.business_buyer_site || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Copyright Text"
          size="small"
          className="textField"
          name="business_copyrights_text"
          value={props.tenantDataState.business_copyrights_text || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Phone"
          size="small"
          className="textField"
          name="business_phone"
          value={props.tenantDataState.business_phone || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Tenant Website"
          size="small"
          className="textField"
          name="business_tenant_site"
          value={props.tenantDataState.business_tenant_site || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Business Website"
          size="small"
          className="textField"
          name="business_website"
          value={props.tenantDataState.business_website || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="User Invitation Template"
          size="small"
          className="textField"
          name="user_invitation_template"
          value={props.tenantDataState.user_invitation_template || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="User Verification Template"
          size="small"
          className="textField"
          name="user_verification_template"
          value={props.tenantDataState.user_verification_template || ''}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Trial Period In Days"
          size="small"
          className="textField"
          name="trial_period_in_days"
          value={props.tenantDataState.trial_period_in_days}
          onChange={handleChange}
          onKeyPress={(e) => allowNumbersOnly(e)}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <input
          style={{ display: 'none' }}
          name="user_pool_logo"
          type="file"
          ref={props.poolLogoRef}
          onChange={(e: any) => {
            props.setLogoState(e.target.files[0]);
          }}
        />
        <Button
          variant="contained"
          onClick={() => props.poolLogoRef.current && props.poolLogoRef.current.click()}>
          User Pool Logo
        </Button>
        {props.logoState ? <span className="completed margin-left-1">Logo selected</span> : ''}
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <input
          id="favicon"
          style={{ display: 'none' }}
          name="favicon"
          type="file"
          onChange={(e: any) => {
            props.setFavicon(e.target.files[0]);
          }}
        />
        <Button variant="contained">
          <label role="button" htmlFor="favicon">
            Favicon
          </label>
        </Button>{' '}
        {props.favicon ? <span className="completed margin-left-1">Favicon selected</span> : ''}
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <TextField
          label="Pxn invitation code"
          size="small"
          className="textField"
          name="pxn_invitation_code"
          value={props.tenantDataState.pxn_invitation_code}
          onChange={handleChange}
        />
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <FormControl>
          <FormLabel>Api Endpoint Configuration</FormLabel>
          <RadioGroup
            onChange={handleChange}
            row
            name="apiEndpointConfiguration"
            value={props.tenantDataState.apiEndpointConfiguration}
            aria-label="Api Endpoint Configuration">
            <FormControlLabel value="Regional" control={<PrimaryRadio />} label="Regional" />
            <FormControlLabel value="Edge" control={<PrimaryRadio />} label="Edge" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="small-12 medium-6 large-4 margin-top-3">
        <FormControlLabel
          label="Is Assurant Reseller"
          control={
            <PrimaryCheckbox
              name="is_assurant_reseller"
              checked={props.tenantDataState.is_assurant_reseller || false}
              onChange={handleCheckboxChange}
            />
          }
        />
      </div>
      <div className="small-12 margin-top-3 margin-bottom-3">
        <PrimaryButton onClick={props.startProcess}>Start Onboarding Workflow</PrimaryButton>
      </div>
      {props.processState !== 1 && (
        <div className="small-12">
          <Button variant="contained" className="margin-bottom-3" onClick={props.restartProcess}>
            Go to the beginning
          </Button>
        </div>
      )}
    </>
  );
}

export default Start;
