import React, { useEffect, useState } from 'react';
import './RegistrationForm.scss';
import RegistrationFields from '../../components/ResellerInvite/RegistrationFields';
import { useHistory } from 'react-router-dom';
import Progress from '../../components/Progress/Progress';
import ConfirmInvite from '../../components/ResellerInvite/ConfirmInvite';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import apiToUrlMap from '../../ApiMapping';
import { IAcceptRequestPayload, IUserDetails } from '../../types/IResellerInvite';
import HtmlContent from '../../components/AtomComponents/UnSanitizedShadowDom';

const RegistrationForm = () => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState<IUserDetails>();
  const [error, setError] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackBar, unAuthorizedFetchUrl } = useDataService();

  // get request details from path and get user details
  useEffect(() => {
    // if request details not passed redirect to PhoneX website
    if (!history.location.search) {
      window.location.href = 'https://www.phonexinc.com/';
      return;
    }
    const logoLink = document.getElementById('logo-link');
    if (logoLink) logoLink.style.pointerEvents = 'none';
    const searchParams = new URLSearchParams(history.location.search);
    const requestDetails: any = {};

    for (const [key, value] of searchParams) {
      requestDetails[key] = value;
    }

    fetchUserDetails(requestDetails);
    setError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disabling and Enabling the Accept button
  useEffect(() => {
    if (
      !userDetails?.optionSelected ||
      (userDetails?.optionSelected === 'yes' && !userDetails?.isPxTenant)
    )
      setIsDisable(true);
    else setIsDisable(false);
    document.title = userDetails?.isInviteAccepted ? 'PhoneX' : 'PhoneX - Reseller Invitation';
  }, [userDetails]);

  //Check if response is empty
  const isResponseNotEmpty = (response: any) => {
    if (!response) return false;
    return (
      response.length > 0 && response.every((element: any) => Object.keys(element).length !== 0)
    );
  };

  // verify the invite
  const fetchUserDetails = async (requestBody: any) => {
    setIsLoading(true);
    const data = {
      invitationCode: requestBody.invitationCode,
      user: requestBody.user,
    };
    try {
      const response: any = await unAuthorizedFetchUrl('POST', apiToUrlMap.verifyInvitation, data);
      if (response) {
        if (!response.isValidInvitation) {
          window.location.href = 'https://www.phonexinc.com/';
          return;
        }

        if (optionsReadOnly(response))
          openSnackBar(inviteAcceptedMessage(response), eMessageType.warning);
        updateMultipleKeys(response);
      }
    } catch (err: any) {
      console.error('Error:', err);
      openSnackBar(err.message, eMessageType.error);
    }
    setIsLoading(false);
  };

  const checkStatusPending = (status: string) => {
    return status === 'PENDING_APPROVAL';
  };

  //Get request data for accept
  const getRequestData = (userDetails: any, isInitialResponse: boolean) => {
    if (!userDetails) return;
    const data: IAcceptRequestPayload = {
      invitationCode: userDetails.invitationCode,
      user: userDetails.user,
      isPxTenant: false,
      pxTenantId: '',
      autoLookup: true,
    };
    if (isInitialResponse) {
      data.isPxTenant = userDetails?.isNotYetClient ? false : userDetails.isPxTenant;
      data.pxTenantId = userDetails?.isNotYetClient ? '' : userDetails.pxTenantId;
      data.autoLookup = userDetails?.isNotYetClient ? false : !userDetails.isLocateUsed;
    } else {
      const index = userDetails.prevResponse.length - 1;
      data.isPxTenant = !!userDetails.prevResponse[index].isPxTenant;
      data.pxTenantId = userDetails.prevResponse[index].pxTenantId;
    }
    return data;
  };

  //Show snackbar based on isPxnr and prev responses from verify call
  const inviteAcceptedMessage = (response: any) => {
    const showActivationEmail = !response.isPxnr && isResponseNotEmpty(response.prevResponse);
    if (checkStatusPending(response.invitationStatus))
      return <>This Invite has already been accepted and is being processed.</>;
    return (
      <>
        This Invite has already been accepted.
        {showActivationEmail && (
          <span
            className="resend-email"
            onClick={async () => {
              const index = response.prevResponse.length - 1;
              await acceptTenant(
                getRequestData(response, false) as IAcceptRequestPayload,
                response,
                !response.prevResponse[index].isPxTenant
                  ? response.userEmail
                  : response.prevResponse[index].ownerEmail
              );
            }}>
            RESEND SUPPLIER ACTIVATION EMAIL
          </span>
        )}
      </>
    );
  };

  // locate the tenant
  const onLocateTenant = async () => {
    if (!userDetails?.siteUrl) return setError(true);
    setIsLoading(true);
    const data = {
      invitationCode: userDetails.invitationCode,
      user: userDetails.user,
      siteUrl: userDetails.siteUrl,
    };
    try {
      const response: any = await unAuthorizedFetchUrl('POST', apiToUrlMap.locateTenant, data);
      if (response) {
        if (response.isPxnr || isResponseNotEmpty(response.prevResponse))
          openSnackBar(inviteAcceptedMessage(response), eMessageType.warning);
        updateMultipleKeys({ ...response, isLocateUsed: true });
        if (!response?.isPxTenant && !response?.pxTenantId) setError(true);
      }
    } catch (err: any) {
      console.error('Error:', err);
      openSnackBar(err.message, eMessageType.error);
    }
    setIsLoading(false);
  };

  // Accept the tenant
  const acceptTenant = async (
    data: IAcceptRequestPayload,
    userDetails?: IUserDetails,
    activationOwnerEmail?: string
  ) => {
    if (!data) return;
    setIsLoading(true);
    try {
      await unAuthorizedFetchUrl('POST', apiToUrlMap.acceptInvitation, data);
      const keysToUpdate = {
        isInviteAccepted: true,
      };
      if (userDetails) updateMultipleKeys(keysToUpdate, true, userDetails);
      else updateMultipleKeys(keysToUpdate);
      !!activationOwnerEmail &&
        openSnackBar(
          `Supplier Activation Email has been resent to ${activationOwnerEmail}`,
          eMessageType.success
        );
    } catch (err: any) {
      console.error('Error:', err);
      openSnackBar(err.message, eMessageType.error);
    }
    setIsLoading(false);
  };

  // on update of URL and Accept the invite
  const onChangeWebsite = async (value: string | null, actionToDo: string) => {
    switch (actionToDo) {
      case 'updateUrl':
        setError(false);
        updateMultipleKeys({
          siteUrl: value?.trim(),
        });
        return;
      case 'locateUrl':
        return await onLocateTenant();
      case 'acceptInvite':
        return await acceptTenant(getRequestData(userDetails, true) as IAcceptRequestPayload);
      default:
        setError(false);
    }
  };

  // Update keys in user details
  const updateMultipleKeys = (keys: any, isResendEmail?: boolean, stateToUpdate?: IUserDetails) => {
    const detailsToUpdate: any = isResendEmail ? { ...stateToUpdate } : { ...userDetails };
    for (const [key, value] of Object.entries(keys)) {
      detailsToUpdate[key] = value;
    }
    setUserDetails(detailsToUpdate);
    setError(false);
  };

  const optionsReadOnly = (userDetails: IUserDetails) => {
    return (
      userDetails.isPxnr ||
      isResponseNotEmpty(userDetails.prevResponse) ||
      checkStatusPending(userDetails.invitationStatus)
    );
  };

  if (!userDetails) {
    return (
      <>
        <h3 className="text-center">Loading...</h3>
        <Progress />
      </>
    );
  }
  return (
    <>
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="px-registration-form">
        <div className="margin-top-1 cell small-12">
          <h5>PhoneX Connect Reseller Registration</h5>
          <h3 className="margin-0">{userDetails.pxnsTenantName} Reseller</h3>
        </div>
        {userDetails.isInviteAccepted ? (
          <ConfirmInvite tenantName={userDetails.pxnsTenantName} />
        ) : (
          <>
            <div className="px-details margin-top-1 padding-2">
              <HtmlContent htmlString={userDetails?.textFields.welcomeHtmlTemplate} />
            </div>
            <RegistrationFields
              userDetails={userDetails}
              isError={error}
              onChangeWebsite={onChangeWebsite}
              updateMultipleKeys={updateMultipleKeys}
              isDisable={isDisable}
              isOptionsReadOnly={optionsReadOnly(userDetails)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default RegistrationForm;
