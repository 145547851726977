import { Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import useAdvanceFilter from '../../hooks/useAdvanceFilter';
import SearchField from '../../components/SearchField/SearchField';
import ClientFormGrid from '../../components/ClientForms/ClientFormGrid';
import { isEmpty } from '../../_lib/utils';
import { FormStatus, IClientForm } from '../../types/IClientForm';

const FormStatusTab = [
  {
    id: 0,
    label: 'SUBMITTED',
  },
  {
    id: 1,
    label: 'COMPLETED',
  },
  {
    id: 2,
    label: 'REJECTED',
  },
];

const ClientForms = () => {
  const [tab, setTab] = useState(0);
  const [allClientForms, setAllClientForms] = useState<IClientForm[]>([]);
  const [filteredClientForms, setFilteredClientForms] = useState<IClientForm[]>([]);

  const { fetchUrl, openSnackBar } = useDataService();

  const [isLoading, setLoading] = useState(false);

  const pxGridRef = useRef<any>();

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Client Forms';
    getAllClientsForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tmpSearchTerm, setTmpSearchTerm, setSearchTerm, itemsState } = useAdvanceFilter({
    requiredFilters: [],
    advanceFilter: {},
    outOfStockField: 'invalidKey',
    pxGridRef,
    settings: {},
    items: allClientForms,
    viewName: 'clientForms',
  });

  useEffect(() => {
    const currentTab = getTabLabel(tab);
    const filterForms = filterByFormStatus(currentTab as FormStatus);
    setFilteredClientForms(filterForms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, itemsState]);

  const handleChange = (event:  React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const getTabLabel = (tabId: number) => {
    const tab = FormStatusTab.find((status: { id: number; label: string }) => status.id === tabId);
    return tab?.label;
  };

  const getAllClientsForm = async () => {
    try {
      setLoading(true);
      const forms = await fetchUrl('GET', apiToUrlMap.getProspectiveTenants);
      setAllClientForms(forms);
    } catch (e) {
      openSnackBar('Something went wrong', eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const filterByFormStatus = (status: FormStatus) => {
    if (!isEmpty(itemsState))
      return itemsState.filter((form: IClientForm) => form.status === status);
    return [];
  };

  return (
    <>
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="px-one-line-data">
        <h3>Client Forms</h3>
      </div>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="From status tab"
        className="margin-top-1 margin-bottom-1">
        {FormStatusTab.map((tab) => (
          <Tab label={tab.label} key={tab.id} />
        ))}
      </Tabs>
      <div className="margin-top-1">
        <SearchField
          tmpSearchTermState={tmpSearchTerm}
          setTmpSearchTermState={setTmpSearchTerm}
          setSearchTermState={setSearchTerm}
          placeholder="Search"
        />
      </div>
      <ClientFormGrid itemsState={filteredClientForms} />
    </>
  );
};

export default ClientForms;
