import React from 'react';
import './AuthWrapper.scss';
import Popper from '@material-ui/core/Popper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Fade from '@material-ui/core/Fade';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Paper } from '@material-ui/core';

function PasswordPolicyPopover(props: any) {
  const { target, pswdState, onClose } = props;

  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  return (
    <Popper
      id="new-paswd-policy"
      open={true}
      placement="right"
      disablePortal={true}
      className="padding-top-0"
      modifiers={{
        preventOverflow: {
          enabled: true,
        },
        flip: {
          enabled: false,
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
        keepTogether: {
          enabled: true,
        },
        offset: {
          offset: '0,16',
        },
        hide: { enabled: false },
      }}
      keepMounted={false}
      transition
      anchorEl={target}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className="box-shadow-none">
            <ClickAwayListener onClickAway={onClose}>
              <Paper className="box-shadow-none">
                <span data-popper-arrow className={'arrow-popover'} ref={setArrowRef} />
                <DialogTitle className="p-l-px-16 dialog-title padding-top-0">
                  {'Password must contain'}
                </DialogTitle>
                <DialogContent className="p-l-px-16 dialog-content">
                  <div className="grid-x align-middle">
                    {!pswdState['upperCase'] ? (
                      <ClearIcon className="m-r-px-12" color="error" />
                    ) : (
                      <DoneIcon className="m-r-px-12" style={{ fill: 'green' }} />
                    )}
                    <DialogContentText className="margin-0 px-text-description">
                      Atleast one uppercase letter.
                    </DialogContentText>
                  </div>
                  <div className="grid-x align-middle">
                    {!pswdState['lowerCase'] ? (
                      <ClearIcon className="m-r-px-12" color="error" />
                    ) : (
                      <DoneIcon className="m-r-px-12" style={{ fill: 'green' }} />
                    )}
                    <DialogContentText className="margin-0 px-text-description">
                      Atleast one lowercase letter.
                    </DialogContentText>
                  </div>
                  <div className="grid-x align-middle">
                    {!pswdState['number'] ? (
                      <ClearIcon className="m-r-px-12" color="error" />
                    ) : (
                      <DoneIcon className="m-r-px-12" style={{ fill: 'green' }} />
                    )}
                    <DialogContentText className="margin-0 px-text-description">
                      Atleast one number.
                    </DialogContentText>
                  </div>
                  <div className="grid-x align-middle">
                    {!pswdState['specialCharacter'] ? (
                      <ClearIcon className="m-r-px-12" color="error" />
                    ) : (
                      <DoneIcon className="m-r-px-12" style={{ fill: 'green' }} />
                    )}
                    <DialogContentText className="margin-0 px-text-description">
                      Atleast one special character.
                    </DialogContentText>
                  </div>
                  <div className="grid-x align-middle">
                    {!pswdState['minLength'] ? (
                      <ClearIcon className="m-r-px-12" color="error" />
                    ) : (
                      <DoneIcon className="m-r-px-12" style={{ fill: 'green' }} />
                    )}
                    <DialogContentText className="margin-0 px-text-description">
                      Minimum length of 8 characters.
                    </DialogContentText>
                  </div>
                </DialogContent>
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default PasswordPolicyPopover;
