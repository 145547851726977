import * as types from '../actions/actionTypes';

export default function clientReducer(state: any = { sort: [], search: '' }, action: any) {
  switch (action.type) {
    case types.SET_CLIENT_FILTER:
      return { ...state, ...action.clientSort };
    default:
      return state;
  }
}
