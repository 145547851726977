import React from "react";
import { PrimaryButton } from "../../../components/AtomComponents";
import Progress from "../Progress/Progress";
import Button from "@material-ui/core/Button";

export function Continue(props: any) {
  const getDate = (date: any) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  };

  return (
    <>
      <div className="small-12">
        <h4>The tenant data was submited.</h4>
      </div>
      {props.statusState && (
        <div className="small-12 border-top border-bottom margin-top-3 px-workflow-status">
          <h4>STATUS:</h4>
          {props.statusState?.startDate && (
            <span className="block">
              <span>Date:</span> {`${getDate(props.statusState?.startDate)}`}
            </span>
          )}
          {props.statusState?.timestamp && (
            <span className="block">
              <span>Date:</span> {`${getDate(props.statusState?.timestamp)}`}
            </span>
          )}
          {props.statusState?.executionArn && (
            <span className="block">
              <span>Execution ARN:</span>{" "}
              {`${props.statusState?.executionArn || "-"}`}
            </span>
          )}
          {props.statusState?.error && (
            <span className="block error">
              <span>Error:</span> {`${props.statusState.error}`}
            </span>
          )}
          <br />
        </div>
      )}
      <div className="small-12 margin-top-1">
        <PrimaryButton
          className="margin-top-2"
          onClick={props.continueProcess}
          disabled={
            !props.statusState ||
            (props.statusState && props.statusState.loading) ||
            (props.statusState && props.statusState.hasOwnProperty("error"))
          }
        >
          Continue...
        </PrimaryButton>
      </div>
      {props.statusState && props.statusState.loading && (
        <div className="small-12 margin-top-3 border-top">
          <p>
            The page will redirect automativally to the next step when the
            server completes the work.
          </p>
          <p>This might take up to 2 minutes.</p>
          <Progress duration={120000} />
        </div>
      )}
      {props.processState !== 1 && (
        <div className="small-12">
          <Button
            variant="contained"
            className="margin-top-3"
            onClick={props.restartProcess}
          >
            Go to the beginning
          </Button>
        </div>
      )}
    </>
  );
}

export default Continue;
