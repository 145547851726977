import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MailIcon } from '../../_lib/icons';

interface IConfirmInviteProps {
  tenantName: string;
}

const ConfirmInvite = ({ tenantName }: IConfirmInviteProps) => {
  return (
    <div className="px-details padding-2 margin-top-1">
      <div className="show-icon-text">
        <CheckCircleIcon className="padding-0 success" />
        <p className="margin-0 font-24px">Accepted Reseller Invitation from {tenantName}</p>
      </div>
      <div className="show-icon-text">
        <MailIcon className="padding-0 mail-icon" />
        <p className="margin-0 font-24px">Check your inbox for confirmation and next steps</p>
      </div>
    </div>
  );
};

export default ConfirmInvite;
