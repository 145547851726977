import React from 'react';
import { useState, useEffect } from 'react';

import { isEmpty } from '../../_lib/utils';
import { IObject } from '../../types/IObject';

import { PrimaryButton, TextField } from '../AtomComponents';

import { logoMapping } from '../../views/UpdateConfig/UpdateConfig';

interface IConfigField {
  config: string;
  value: string;
  checkDisabledField: (value: string) => boolean;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  type: string;
  setUpdatedImages: React.Dispatch<React.SetStateAction<IObject<string>>>;
  updatedImages: IObject<string>;
  name: string;
  tenantConfig: IObject<string>;
}

const ConfigField = ({
  config,
  value,
  checkDisabledField,
  onChange,
  type,
  setUpdatedImages,
  updatedImages,
  tenantConfig,
}: IConfigField) => {
  if (type === 'img')
    return (
      <LogoUpload
        src={value}
        alt={config}
        setUpdatedImages={setUpdatedImages}
        updatedImages={updatedImages}
        disabled={checkDisabledField(config)}
      />
    );
  return (
    <TextField
      label={config}
      value={value || ''}
      name={config}
      className="margin-bottom-2"
      disabled={checkDisabledField(config)}
      onChange={onChange}
      // error={!tenantConfig[config] ? false : !!!value}
    />
  );
};

interface ILogoUpload {
  src: string;
  alt: string;
  setUpdatedImages: React.Dispatch<React.SetStateAction<IObject<string>>>;
  updatedImages: IObject<any>;
  disabled: boolean;
}

const LogoUpload = ({ src, alt, setUpdatedImages, updatedImages, disabled }: ILogoUpload) => {
  const [image, setImage] = useState<any>('');

  useEffect(() => {
    if (isEmpty(updatedImages)) setImage(`${src}?${performance.now()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedImages]);

  const handleChange = async (e: any) => {
    if (e.target.files[0]) {
      const base64Image = await blobToBase64(e.target.files[0]);
      setImage(base64Image);
      setUpdatedImages({ ...updatedImages, [logoMapping[e.target.name]]: base64Image });
    } else {
      cancelChanges(logoMapping[e.target.name]);
    }
  };

  const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const cancelChanges = (key: string) => {
    const updatedImagesRef = updatedImages;
    delete updatedImagesRef[logoMapping[key]];
    setUpdatedImages({ ...updatedImagesRef });
    setImage(`${src}?${performance.now()}`);
  };

  return alt !== 'logoSmall' ? (
    <>
      {image && (
        <div className="grid-y image-preview">
          <label>{alt} </label>
          <img className="margin-top-1" src={image} alt={alt} />
        </div>
      )}
      <div className="upload-button">
        <PrimaryButton className="margin-right-1">
          <label role="button" htmlFor={`upload-img-${alt}`}>
            {`Upload ${alt}`}
          </label>
        </PrimaryButton>
        {image.substring(0, image.indexOf('?')) !== src && (
          <PrimaryButton onClick={() => cancelChanges(alt)}>Cancel</PrimaryButton>
        )}
      </div>

      <input
        id={`upload-img-${alt}`}
        type="file"
        onChange={handleChange}
        accept="image/png, image/gif, image/jpeg, image/jpg"
        name={alt}
        disabled={disabled}
        style={{ display: 'none' }}
      />
    </>
  ) : (
    <></>
  );
};

export default ConfigField;
