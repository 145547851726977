import { CheckCircle } from '@material-ui/icons';
import React from 'react';

const SuccessfulRegistartion = () => {
  return (
    <div>
      <h1>
        <CheckCircle className="success margin-right-2 margin-top-3" />
        Account Registration Successful
      </h1>
      <div className="grid-y instruction padding-3">
        <p className="px-fontsize-24px">What Happens Next</p>
        <p className="px-fontsize-16px">
          1. We’ll review your account details and reach out to you if we have any questions
        </p>
        <p className="px-fontsize-16px">
          2. A new PhoneX System instance will be created for your business
        </p>
        <p className="px-fontsize-16px">
          3. You will receive an email invitation to sign in to your new system and get started
        </p>
      </div>
      <div className="grid-y instruction padding-3 margin-top-3">
        <p className="px-fontsize-24px margin-bottom-3">Need Help</p>
        <p className="px-fontsize-16px">PhoneX Client Success Team</p>
        <p
          className="px-link-btn pointer"
          onClick={(e) => {
            window.location.href = 'mailto:ClientSuccess@phonexinc.com';
          }}>
          ClientSuccess@phonexinc.com
        </p>
      </div>
    </div>
  );
};

export default SuccessfulRegistartion;
