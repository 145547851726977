import React, { useEffect, createContext, useState } from 'react';
import { formatISODate, getConfig, getI18n } from './_lib/lib';
export const I18nContext = createContext<any>(null);

const I18nWrapper = (props: any) => {
  const format = (text: string, variables: Array<any>) => {
    let result = text;
    const count = variables.length;
    for (let i = 1; i <= count; i++) {
      result = result.replace('${' + i + '}', variables[i - 1]);
    }
    return result;
  };

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const uppercase = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.toUpperCase();
  };

  const lowercase = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.toLowerCase();
  };

  const reactStringReplace = (
    str: string,
    labelStringFormatter: any,
    ReactComponentFormatter: any
  ) => {
    var parts = str.split(/\{|\}/g);
    var fn = labelStringFormatter,
      output = [];
    for (var i = 0; i < parts.length; i++) {
      output[i] = fn(parts[i], `reactStringReplace-${i}`);
      // toggle between the two functions
      fn = fn === labelStringFormatter ? ReactComponentFormatter : labelStringFormatter;
    }
    return <>{output}</>;
  };

  const formatType = (
    val: any,
    type: 'date' | 'id' | 'longdate' | 'shortdate' | 'percent' | 'currency' | 'integer' | any
  ) => {
    switch (type) {
      case 'id':
        return val || val === 0 ? `${val}` : '--';
      case 'date':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'longdate':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'shortdate':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'percent':
        return val || +val === 0 ? (typeof +val === 'number' ? `${val.toFixed(1)}%` : val) : '--';
      case 'currency':
        const localeCurrency = localStorage.getItem('PxCurrencySymbol');
        let currencySymbol = '$';
        if (localeCurrency) {
          currencySymbol = localeCurrency;
        }
        return !isNaN(parseInt(val)) && +val !== 0
          ? `${currencySymbol}${(+val.toFixed(2)).toLocaleString('en', {
              useGrouping: true,
              minimumFractionDigits: 2,
            })}`
          : '--';
      case 'integer':
        return `${val ? val.toFixed(0) : '--'}`;
      default:
        return val || val === 0 ? val : '--';
    }
  };

  const [I18nState, setI18nState] = useState<any>({
    _format: format,
    _capitalize: capitalize,
    _uppercase: uppercase,
    _lowercase: lowercase,
    _formatType: formatType,
    _reactStringReplace: reactStringReplace,
  });

  useEffect(() => {
    getConfig().then(() => {
      getI18n().then((res: any) => {
        setI18nState({
          ...res,
          _format: format,
          _capitalize: capitalize,
          _uppercase: uppercase,
          _lowercase: lowercase,
          _formatType: formatType,
          _reactStringReplace: reactStringReplace,
        });
      });
    });
  }, []);

  return (
    <>
      {I18nState ? (
        <I18nContext.Provider value={I18nState}>{props.children}</I18nContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default I18nWrapper;
