import { Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TertiaryButton } from '../../components/AtomComponents';
import { allTrueKey } from '../../types/IFilterEngine';
import { dataFormatting } from '../../_lib/lib';
import { IInvoiceStatus, IInvoiceStatusInfo, IInvoiceStatusLabel } from '../../types/IInvoice';

interface IStatusMap {
  label: IInvoiceStatusLabel;
  value: IInvoiceStatus;
}

export const statuses: IStatusMap[] = [
  {
    value: 'OVERDUE',
    label: 'OVERDUE',
  },
  {
    value: 'AWAITING_PAYMENT',
    label: 'AWAITING PAYMENT',
  },
  {
    value: 'PAID',
    label: 'PAID',
  },
  {
    value: 'WRITTEN_OFF',
    label: 'WRITTEN OFF',
  },
  {
    value: 'VOIDED',
    label: 'VOIDED',
  },
  {
    value:'PAYMENT_FAILED',
    label:'PAYMENT FAILED'
  }
];

interface IStatusFilter {
  statusInfo: IInvoiceStatusInfo;
  appliedInvoiceStatus: any;
  setInvoiceStatusFilter: React.Dispatch<any>;
}

const StatusFilter = ({
  statusInfo,
  setInvoiceStatusFilter,
  appliedInvoiceStatus,
}: IStatusFilter) => {
  const [invoiceStatuses, setInvoiceStatuses] = useState<any>();

  useEffect(() => {
    if (appliedInvoiceStatus) {
      const activeStatus: any = {};
      let isAllTrue = true;
      statuses.forEach(({ value }: IStatusMap) => {
        activeStatus[value] = appliedInvoiceStatus[value];
        if (!appliedInvoiceStatus[value] && isAllTrue) isAllTrue = false;
      });
      activeStatus[allTrueKey] = isAllTrue;
      setInvoiceStatuses(activeStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedInvoiceStatus]);

  const setInvoiceStatus = (chipVal: string) => {
    // apply filters
    const invoiceStatusApplied = { ...appliedInvoiceStatus };
    let allTrue = true;
    statuses.forEach(({ value }: IStatusMap) => {
      if (!invoiceStatusApplied[value]) allTrue = false;
    });
    if (allTrue) {
      statuses.forEach(({ value }: IStatusMap) => {
        invoiceStatusApplied[value] = false;
        if (value === chipVal) invoiceStatusApplied[value] = true;
      });
    } else {
      statuses.forEach(({ value }: IStatusMap) => {
        if (value === chipVal) {
          invoiceStatusApplied[value] = !invoiceStatusApplied[value];
        }
      });
    }

    let allFalse = true;
    statuses.forEach(({ value }: IStatusMap) => {
      if (invoiceStatusApplied[value]) allFalse = false;
    });
    if (allFalse) {
      // set all true
      return setInvoiceStatusFilter({ label: 'ALL' });
    }

    setInvoiceStatusFilter(invoiceStatusApplied);
  };

  const getChipLabel = (label: IInvoiceStatusLabel | 'PAYMENT STATUS', statusDetails: any) => {
    return `${label} (${statusDetails.count}) ${
      statusDetails.pending
        ? dataFormatting('currency', statusDetails.pending)
        : dataFormatting('currency', '0')
    }`;
  };

  return (
    <div className="margin-top-1">
      {statuses.map(({ label, value }: IStatusMap) => (
        <TertiaryButton
          disableRipple
          disableFocusRipple
          disableElevation
          key={value}
          onClick={() => {
            setInvoiceStatus(value);
          }}
          data-id={value}
          className="px-no-bg pointer-mouse padding-0 margin-right-1 margin-bottom-1">
          <Chip
            label={getChipLabel(label, statusInfo[value])}
            color="primary"
            variant="outlined"
            className={
              'pointer-mouse ' +
              (invoiceStatuses && invoiceStatuses[value] && !invoiceStatuses[allTrueKey]
                ? 'px-filter-on'
                : '')
            }
          />
        </TertiaryButton>
      ))}
    </div>
  );
};

export default StatusFilter;
