import React from 'react';
import SideMenu from '../components/SideMenu/SideMenu';
import { bindActionCreators } from 'redux';
import { messageSet } from '../redux/actions/messageActions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function DashboardLayout(props: any) {
  const [autoCloseState, setAutoCloseState] = React.useState(props.isMenuOpened || 0);
  
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    props.messagesSet(false, '');
  };

  return (
    <>
      <Snackbar 
        open={props.messages?.message?.length > 0} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "center"}}>
        <Alert onClose={handleClose} severity={props.messages?.type === 'SUCCESS_MESSAGE' ? "success" : "error"}>
          {props.messages.message}
        </Alert>
      </Snackbar>

      <div className="px-v-dashboard grid-x">

        <SideMenu autoCloseState={autoCloseState} setAutoCloseState={setAutoCloseState} />

        <main className={`cell auto ${autoCloseState > 0 ? 'px-main-padding' : "px-home-padding"}`}>
          {props.children}
        </main>
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    messagesSet: bindActionCreators(messageSet, dispatch),
  };
}

function mapStateToProps(state: any) {
  return {
    messages: state.messageState
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
