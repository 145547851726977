import { MenuItem } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface IPXMenuProps {
  to: string;
  setSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  setSideMenuSmallDeviceState: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoCloseState: React.Dispatch<React.SetStateAction<number>>;
  placeholder: string;
  icon?: React.ReactChild;
}

const PxMenu = ({
  to,
  setSideMenuState,
  setAutoCloseState,
  setSideMenuSmallDeviceState,
  placeholder,
  icon,
}: IPXMenuProps) => {
  return (
    <MenuItem className="px-menu-desk padding-0 border-bottom" disableRipple>
      <NavLink
        activeClassName="px-active-navlink"
        to={to}
        className="cell grid-x align-left align-middle px-sideMenu-item"
        onClick={(e: any) => {
          setAutoCloseState((old: any) => old + 1);
          setSideMenuState(false);
          setSideMenuSmallDeviceState(false);
        }}>
        {icon}
        <h5 className="margin-bottom-0 px-bold-fonts">{placeholder}</h5>
      </NavLink>
    </MenuItem>
  );
};

export default PxMenu;
