import * as types from './actionTypes';

export function messageSet(error: boolean, message: string) {
  let type = types.ERROR_MESSAGE;
  if (!error) {
    type = types.SUCCESS_MESSAGE;
  }
  return { type: type, message };
}

export function messageRemove() {
  return { type: types.REMOVE_MESSAGE };
}
