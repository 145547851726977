import React from "react";
import { getWorkflowStatus, startWorkflow, resumeWorkflow } from "../../_lib/lib";
import WorkflowSteps from "./WorkflowSteps/WorkflowSteps";
import CheckStatus from "./CheckStatus/CheckStatus";
import { Continue } from "./Continue/Continue";
import Start from "./Start/Start";
import { Resume } from "./Resume/Resume";
import "./NewTenant.scss";

export function NewTenant() {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Onboard New Tenant";
  }, []);

  const initObj = {
    tenant_id: "",
    whatsapp: "",
    email: "",
    phone: "",
    last_name: "",
    first_name: "",
    tenant_short_name: "",
    site_title: "",
    site_description: "",
    user_pool_logo: "",
    language: "",
    region: "",
    px_color_primary: "",
    px_color_secondary: "",
    px_font_title: "",
    px_font_body: "",
    routes: "",
    execution_name: "",
    tenant_sender_email: "",
    tenant_name: "",
    pxn_invitation_code:"",

    business_address_1: "",
    business_address_2: "",
    business_address_3: "",
    business_buyer_site: "",
    business_copyrights_text: "",
    business_phone: "",
    business_tenant_site: "",
    business_website: "",
    user_invitation_template: "tem_pfjCPBHPrJRBYCC9PygfpGgS",
    user_verification_template: "tem_VJCxdHtTTRpktgf4PP3btH9S",
    trial_period_in_days: '',
    apiEndpointConfiguration: "Regional",
    is_assurant_reseller:false
  };

  const poolLogoRef = React.useRef<HTMLInputElement>(null);
  const [logoState, setLogoState] = React.useState<any>(null);
  const [favicon, setFavicon] = React.useState<any>(null);
  const [statusState, setStatusState] = React.useState<any>(null);
  const [inProgressState, setInProgressState] = React.useState<number>(0);
  const [processState, setProcessState] = React.useState<any>(1);
  const [tenantIdState, setTenantIdState] = React.useState<any>(null);
  const [tenantDataState, setTenantDataState] = React.useState<any>(Object.assign({}, initObj));

  const checkProcess = () => {
    return getWorkflowStatus(tenantIdState + "");
  };

  const restartProcess = () => {
    setStatusState(null);
    setLogoState(null);
    setTenantIdState(null);
    setTenantDataState(Object.assign({}, initObj));
    setProcessState(1);
    setInProgressState(0);
  };

  const step1Process = () => {
    setInProgressState(1);
    setTenantDataState({ ...tenantDataState, tenant_id: tenantIdState });
    checkProcess().then(async (resp: any) => {
      setInProgressState(0);
      if (resp.hasOwnProperty("error") && resp.error === "Tenant Not Found") {
        setProcessState(2);
      } else if (resp.hasOwnProperty("status") && resp.status === "FAILED") {
        setStatusState(resp);
      } else if (resp.hasOwnProperty("status") && resp.status === "TIMED_OUT") {
        setStatusState(resp);
      } else if (resp.hasOwnProperty("status") && resp.status === "ABORTED") {
        setStatusState(resp);
      } else if (resp.hasOwnProperty("status") && resp.status === "RUNNING") {
        setStatusState(resp);
      } else if (resp.hasOwnProperty("status") && resp.status === "WAITING" && resp.type === 'DNS Mapping' ) {
        resp.data = JSON.parse(resp.output);
        resp.waitingType = 'DNS'
        setStatusState(resp);
        setProcessState(4);
      } else if (resp.hasOwnProperty("status") && resp.status === "WAITING" && resp.type === 'CNAME Records' ) {
        resp.data = JSON.parse(resp.output);
        resp.waitingType = 'CNAME';
        setStatusState(resp);
        setProcessState(4);
      } else if (resp.hasOwnProperty("status") && resp.status === "WAITING") {
        setStatusState(resp);
      } else if (resp.hasOwnProperty("status") && resp.status === "SUCCEEDED") {
        setStatusState(resp);
        setProcessState(5);
      } else {
        setProcessState(2);
      }
    });
  };

  const startProcess = () => {
    if (isTenantDataFilled()) {
      setProcessState(3);
      startWorkflow(tenantDataState, logoState, favicon).then(async (resp: any) => {
        setStatusState(resp);
      });
    }
  };

  const continueProcess = () => {
    setStatusState({...statusState, loading: true});
    const check = () => {
      checkProcess().then(async (resp: any) => {
        if (resp && resp.status === 'SUCCEEDED') {
          setStatusState(resp);
          if (processState !== 5) {
            setProcessState(5);
          }
        } else
        if (processState === 3 && resp && resp.status === 'WAITING') {
          resp.data = JSON.parse(resp.output);
          setStatusState(resp);
          setProcessState(4);
        } else
        if (resp && resp.status === 'RUNNING') {
          setTimeout(() => {
            check();
          }, 15 * 1000);
        } else
        if (processState === 5 && resp && resp.status === 'WAITING') {
          setTimeout(() => {
            check();
          }, 15 * 1000);
        }
      });
    };

    check();
  };

  const resumeProcess = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure that you want to RESUME the process?")) {
      setStatusState({status: 'RUNNING', loading: true});
      setProcessState(5);
      resumeWorkflow(tenantIdState).then(async (resp: any) => {
        continueProcess();
      });
    }
  };

  const isTenantDataFilled = () => {
    const msg = "Please fill out all the data!";
    if (logoState === null) {
      alert(`${msg}\nCheck field Pool Logo.`);
      return false;
    }
    if (!tenantDataState.tenant_id.length) {
      alert(`${msg}\nCheck field tenant_id.`);
      return false;
    }
    if (!tenantDataState.whatsapp.length) {
      alert(`${msg}\nCheck field whatsapp.`);
      return false;
    }
    if (!tenantDataState.email.length) {
      alert(`${msg}\nCheck field email.`);
      return false;
    }
    if (!tenantDataState.phone.length) {
      alert(`${msg}\nCheck field phone.`);
      return false;
    }
    if (!tenantDataState.last_name.length) {
      alert(`${msg}\nCheck field last_name.`);
      return false;
    }
    if (!tenantDataState.first_name.length) {
      alert(`${msg}\nCheck field first_name.`);
      return false;
    }
    if (!tenantDataState.tenant_short_name.length) {
      alert(`${msg}\nCheck field tenant_short_name.`);
      return false;
    }
    if (!tenantDataState.site_title.length) {
      alert(`${msg}\nCheck field site_title.`);
      return false;
    }
    if (!tenantDataState.site_description.length) {
      alert(`${msg}\nCheck field site_description.`);
      return false;
    }
    if (!tenantDataState.language.length) {
      alert(`${msg}\nCheck field language.`);
      return false;
    }
    if (!tenantDataState.region.length) {
      alert(`${msg}\nCheck field region.`);
      return false;
    }
    if (!tenantDataState.px_color_primary.length) {
      alert(`${msg}\nCheck field px_color_primary.`);
      return false;
    }
    if (!tenantDataState.px_color_secondary.length) {
      alert(`${msg}\nCheck field px_color_secondary.`);
      return false;
    }
    if (!(/^#([0-9a-f]{3}){1,2}$/i.test(tenantDataState.px_color_primary))) {
      alert(`${msg}\nInvalid color on field px_color_primary.`);
      return false;
    }
    if (!(/^#([0-9a-f]{3}){1,2}$/i.test(tenantDataState.px_color_secondary))) {
      alert(`${msg}\nInvalid color on field px_color_secondary.`);
      return false;
    }
    if (!tenantDataState.px_font_title.length) {
      alert(`${msg}\nCheck field px_font_title.`);
      return false;
    }
    if (!tenantDataState.px_font_body.length) {
      alert(`${msg}\nCheck field px_font_body.`);
      return false;
    }
    if (!tenantDataState.routes.length) {
      alert(`${msg}\nCheck field routes.`);
      return false;
    }
    if (!tenantDataState.tenant_name.length) {
      alert(`${msg}\nCheck field tenant_name.`);
      return false;
    }
    return true;
  };

  const steps: any = {
    steps: ["step_1", "step_2", "step_3", "step_4", "step_5"],
    step_1: { title: "Check Status" },
    step_2: { title: "Start Onboarding" },
    step_3: { title: "Continue" },
    step_4: { title: "Resume Workflow" },
    step_5: { title: "Confirm Status" },
  };

  const setTenantId = (e: any) => {
    setTenantIdState(e.target.value);
    return true;
  }

  return (
    <>
      <div className="px-new-tenant grid-x">
        <h3 className="small-12">Onboard New Tenant</h3>
        <div className="small-12 margin-bottom-3">
          <section className="grid-x">
            <>
              <div className="small-12">
                <WorkflowSteps processState={processState} steps={steps} />
              </div>
            </>
            {processState === 1 && (
              <CheckStatus statusState={statusState} inProgressState={inProgressState} tenantIdState={tenantIdState} setTenantId={setTenantId} step1Process={step1Process} restartProcess={restartProcess} processState={processState} />
            )}
            {processState === 2 && (
              <Start
                setTenantDataState={setTenantDataState}
                tenantDataState={tenantDataState}
                poolLogoRef={poolLogoRef}
                logoState={logoState}
                favicon={favicon}
                setLogoState={setLogoState}
                startProcess={startProcess}
                restartProcess={restartProcess}
                setFavicon={setFavicon}
              />
            )}
            {processState === 3 && (
              <Continue statusState={statusState} continueProcess={continueProcess} restartProcess={restartProcess} />
            )}
            {processState === 4 && (
              <Resume
                resumeProcess={resumeProcess}
                statusState={statusState}
                restartProcess={restartProcess}
              />
            )}
            {processState === 5 && (
              <CheckStatus statusState={statusState} inProgressState={inProgressState} tenantIdState={tenantIdState} setTenantId={setTenantId} step1Process={step1Process} restartProcess={restartProcess} processState={processState} />
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default NewTenant;
