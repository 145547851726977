import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { messageSet } from "../../redux/actions/messageActions";
import {
  commonService,
  getWorkflowStatus,
  startWorkflow,
} from "../../_lib/lib";
// import CheckStatus from "../NewTenant/CheckStatus/CheckStatus";
import Continue from "./Continue/Continue";
import Start from "./Start/Start";
import WorkflowSteps from "../NewTenant/WorkflowSteps/WorkflowSteps";
import "./Decommission.scss";
import { eMessageType } from "../../types/IMessageType";
import useDataService from "../../hooks/useDataService";
import Progress from "../NewTenant/Progress/Progress";

export function Decommission(props: any) {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Decommission Tenant";
  }, []);

  const initObj = {
    tenant_id: "",
    tenant_bucket: "",
    tenant_database: "",
    tenant_database_user: "",
    tenant_api_stack: "",
    tenant_secret: "",
    tenant_iam: "",
    tenant_public_tables: [],
    tenant_certificate_arn: [],
    tenant_env_application_configs: "",
  };

  const [statusState, setStatusState] = React.useState<any>(null);
  const [processState, setProcessState] = React.useState<any>(1);
  const [tenantIdState, setTenantIdState] = React.useState<any>(null);
  const [tenantDataState, setTenantDataState] = React.useState<any>(
    Object.assign({}, initObj)
  );
  const { openSnackBar } = useDataService();

  const checkProcess = () => {
    return getWorkflowStatus(tenantIdState);
  };

  const restartProcess = () => {
    setStatusState(null);
    setTenantIdState(null);
    setTenantDataState(Object.assign({}, initObj));
    setProcessState(1);
  };

  // const step1Process = () => {
  //   setStatusState(null);
  //   setTenantDataState({ ...tenantDataState, tenant_id: tenantIdState });
  //   checkProcess()
  //     .then(async (resp: any) => {
  //       setInProgressState(0);
  //       if (resp.hasOwnProperty("error") && resp.error === "Tenant Not Found") {
  //         openSnackBar("Tenant not found", eMessageType.error);
  //         setStatusState(null);
  //       } else if (resp.hasOwnProperty("status") && resp.status === "FAILED") {
  //         setStatusState(resp);
  //         setProcessState(2);
  //       } else if (
  //         resp.hasOwnProperty("status") &&
  //         resp.status === "TIMED_OUT"
  //       ) {
  //         setStatusState(resp);
  //       } else if (resp.hasOwnProperty("status") && resp.status === "ABORTED") {
  //         setStatusState(resp);
  //       } else if (resp.hasOwnProperty("status") && resp.status === "RUNNING") {
  //         console.log(resp);
  //         setStatusState(resp);
  //       } else if (resp.hasOwnProperty("status") && resp.status === "WAITING") {
  //         resp.data = JSON.parse(resp.output);
  //         setStatusState(resp);
  //         setProcessState(2);
  //       } else if (resp.hasOwnProperty("error")) {
  //         // openSnackBar("Something went wrong", eMessageType.error);
  //         setProcessState(2);
  //       } else {
  //         setProcessState(2);
  //       }
  //     })
  //     .catch(() => {
  //       openSnackBar("Tenant not found", eMessageType.error);
  //       restartProcess();
  //     });
  // };

  const startProcess = async () => {
    try {
      const response = await startWorkflow({
        ...tenantDataState,
        execution_name: `decommission-${
          tenantDataState.tenant_id
        }-${Date.now()}`,
      });

      if (response.error) {
        throw new Error("Tenant not found");
      } else if (processState === 1 && response.status === "WAITING") {
        setStatusState(response);
        setProcessState(2);
      } else {
        setProcessState(2);
      }
    } catch (e) {
      openSnackBar("Tenant not found", eMessageType.error);
    }
  };

  const continueProcess = () => {
    setStatusState({ ...statusState, loading: true });
    const check = () => {
      checkProcess().then(async (resp: any) => {
        if (resp && resp.error === "Tenant Not Found") {
          setStatusState(resp);
          if (processState !== 1) {
            openSnackBar(
              "Tenant Decommissioned successfully",
              eMessageType.success
            );
            setProcessState(1);
          }
        } else if (processState === 1 && resp && resp.status === "WAITING") {
          resp.data = JSON.parse(resp.output);
          setStatusState(resp);
          setProcessState(2);
        } else if (resp && resp.status === "RUNNING") {
          setStatusState({ ...resp, loading: true });
          setTimeout(() => {
            check();
          }, 15 * 1000);
        } else if (resp && resp.status === "FAILED") {
          setStatusState(null);
          openSnackBar("Decommission Failed, Try again", eMessageType.error);
        }
      });
    };

    check();
  };

  const resumeProcess = (isWaiting?: Boolean) => {
    const message = !!isWaiting
      ? "Are you sure that you want to CONTINUE the process?"
      : "Tenant is in WAITING state do you want to continue?";
    if (isWaiting) {
      setProcessState(2);
    }
    // eslint-disable-next-line no-restricted-globals
    if (confirm(message)) {
      setStatusState({ status: "RUNNING", loading: true });
      resumeWorkflow().then(async (resp: any) => {
        continueProcess();
      });
    }
  };

  const resumeWorkflow = async () => {
    return await commonService("post", `/workflow/resume`, {
      body: {
        tenant_id: tenantDataState.tenant_id,
        "decommission-resources": { resources: tenantDataState },
      },
      responseType: "text",
    });
  };

  const steps: any = {
    steps: ["step_1", "step_2"],
    step_1: { title: "Start Decommissoning" },
    step_2: { title: "Continue" },
    // step_4: { title: "Resume Workflow" },
  };

  const goBack = () => {
    setTenantDataState(Object.assign({}, initObj));
    setProcessState(processState - 1);
  };

  return (
    <>
      <div className="px-new-tenant grid-x">
        <h3 className="small-12">Decommission Tenant</h3>
        <div className="small-12 margin-bottom-3">
          <section className="grid-x">
            <>
              <div className="small-12">
                <WorkflowSteps processState={processState} steps={steps} />
              </div>
            </>
            {processState === 1 && (
              <Start
                setTenantDataState={setTenantDataState}
                tenantDataState={tenantDataState}
                startProcess={startProcess}
                restartProcess={restartProcess}
              />
            )}
            {processState === 2 && (
              <Continue
                statusState={statusState}
                continueProcess={resumeProcess}
                restartProcess={restartProcess}
                setTenantDataState={setTenantDataState}
                tenantDataState={tenantDataState}
                goBack={goBack}
                setProcessState={setProcessState}
                setStatusState={setStatusState}
              />
            )}
          </section>
        </div>
      </div>
      {props.statusState && props.statusState.loading && (
        <div className="small-12 margin-top-3 border-top">
          <p>
            The page will redirect automativally to the next step when the
            server completes the work.
          </p>
          <p>This might take up to 2 minutes.</p>
          <Progress duration={120000} />
        </div>
      )}
    </>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    messagesSet: bindActionCreators(messageSet, dispatch),
  };
}

function mapStateToProps(state: any) {
  return {
    messages: state.messageState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Decommission);
