import { SvgIcon } from '@material-ui/core';
import React from 'react';

interface IProps {
  className?: string;
}

const MultipleSelected = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M20,3 L4,3 C2.9,3 2,3.9 2,5 L2,19 C2,20.1 2.9,21 4,21 L20,21 C21.1,21 22,20.1 22,19 L22,5 C22,3.9 21.1,3 20,3 Z M10,17 L5,17 L5,15 L10,15 L10,17 Z M10,13 L5,13 L5,11 L10,11 L10,13 Z M10,9 L5,9 L5,7 L10,7 L10,9 Z M14.82,15 L12,12.16 L13.41,10.75 L14.82,12.17 L17.99,9 L19.41,10.42 L14.82,15 Z"
        id="path-1"></path>
    </SvgIcon>
  );
};

export default MultipleSelected;
