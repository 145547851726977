import React, { useState } from 'react';
import { I18nContext } from '../../I18n';
import './AuthWrapper.scss';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { PrimaryButton, TertiaryButton, TextField } from '../AtomComponents';
import { Auth } from 'aws-amplify';

import { fieldValidation } from '../../_lib/lib';

import { useHistory, useLocation } from 'react-router-dom';

import useDataService from '../../hooks/useDataService';
import PasswordPolicyPopover from './PasswordPolicyPopover';
import LoadingDialog from '../LoadingDialog/LoadingDialog';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword(props: any) {
  const { configState } = props;
  const I18n = React.useContext(I18nContext);

  const isResetPswdFromEmail = useQuery().get('resetPswdFromEmail') === 'true';

  const [popoverTarget, setPopOverTarget] = useState(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [resetPasswordState, setResetPasswordState] = useState<any>({
    email: '',
    verificationCode: '',
    newPassword: '',
  });

  const [pswdState, setPswdState] = useState({
    upperCase: false,
    lowerCase: false,
    minLength: false,
    number: false,
    specialCharacter: false,
  });

  const [errorState, setErrorState] = useState<any>({});

  const { openSnackBar } = useDataService();

  const [isVerificationCodeView, setIsVerificationView] = useState<boolean>(false);

  const history = useHistory();

  const errorValidation = (name: string, value: string, type: string) => {
    if (value === '') return;

    const errorData: any = { ...errorState };

    const requiredRes = fieldValidation(value, type);

    if (requiredRes) {
      errorData[name] = requiredRes;
    } else {
      delete errorData[name];
    }

    setErrorState(errorData);
  };

  const onChangeState = (e: any) => {
    setResetPasswordState({ ...resetPasswordState, [e.target.name]: e.target.value });
    setErrorState({ ...errorState, [e.target.name]: '' });

    if (e.target.name === 'newPassword' && e.target.value) {
      const isLowerCase = /[a-z]/.test(e.target.value);
      const isUpperCase = /[A-Z]/.test(e.target.value);
      const isMinLength = e.target.value.length >= 8;
      const isNumber = /[0-9]/.test(e.target.value);
      const isSpecialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(e.target.value);

      setPopOverTarget(e.target);
      setPswdState({
        lowerCase: isLowerCase,
        upperCase: isUpperCase,
        minLength: isMinLength,
        number: isNumber,
        specialCharacter: isSpecialCharacter,
      });
    } else setPopOverTarget(null);
  };

  const goToSignInPage = (e: any) => {
    e.event?.preventDefault();
    return history.replace(`/`);
  };

  const onClickPrimaryButton = async (e: any) => {
    e.preventDefault();
    try {
      if (!isVerificationCodeView && !isResetPswdFromEmail) {
        setIsLoading(true);
        await Auth.forgotPassword(resetPasswordState.email);
        setIsVerificationView(true);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        await Auth.forgotPasswordSubmit(
          resetPasswordState.email,
          resetPasswordState.verificationCode,
          resetPasswordState.newPassword
        );
        setIsLoading(false);
        history.replace('/');
        openSnackBar('New Password Set', 'success');
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      openSnackBar(err.message, 'error');
    }
  };

  return (
    <Paper elevation={3} className="px-reset-password">
      <form>
        <div className="cell small-12 margin-bottom-1">
          <img className="login-logo" src={configState.userPoolLogo} alt="Logo" />
          <h3>{I18n?.ResetPassword?.i18n_value || 'Reset your password'}</h3>
          <p className="reset-pass cell small-12 text-muted margin-bottom-2">
            {isResetPswdFromEmail
              ? I18n?.AdminPassVerificationCode?.i18n_value ||
                'If you received an email from us requesting a password change, enter your email and the Code that we sent you then set your New Password.'
              : !isVerificationCodeView
              ? I18n?.weWillSendVerificationCode?.i18n_value ||
                'We’ll send a verification code in an email that you will have to enter on the next step.'
              : I18n?.weHaveSentVerificationCode?.i18n_value ||
                'We’ve sent a verification code to your email. Enter the Code that we sent you then set your New Password.'}
          </p>
          <div className="form-fields margin-bottom-2 grid-x">
            {isResetPswdFromEmail || !isVerificationCodeView ? (
              <div className="cell small-12 margin-bottom-1">
                <label className="margin-bottom-1 px-text-description">Email Address</label>
                <TextField
                  onChange={onChangeState}
                  onBlur={(e) => errorValidation(e.target.name, e.target.value, 'email')}
                  name="email"
                  value={resetPasswordState['email']}
                  placeholder="Enter Email"
                  type="email"
                  className="margin-top-1"
                  error={errorState?.email ? true : false}
                  helperText={errorState?.email}
                  required
                  fullWidth
                />
              </div>
            ) : null}
            {isResetPswdFromEmail || isVerificationCodeView ? (
              <>
                <div className="cell small-12 margin-bottom-1">
                  <label className="margin-bottom-1 px-text-description">Verification Code</label>
                  <TextField
                    onChange={onChangeState}
                    name="verificationCode"
                    onBlur={(e) => errorValidation(e.target.name, e.target.value, 'number')}
                    value={resetPasswordState['verificationCode']}
                    className="margin-top-1"
                    error={errorState?.verificationCode ? true : false}
                    helperText={errorState?.verificationCode}
                    placeholder="Enter Verification code"
                    required
                    fullWidth
                  />
                </div>
                <div className="cell small-12 margin-bottom-1">
                  <label className="margin-bottom-1 px-text-description">New Password</label>
                  <TextField
                    onChange={onChangeState}
                    name="newPassword"
                    type="password"
                    onBlur={(e) => errorValidation(e.target.name, e.target.value, 'text')}
                    value={resetPasswordState['newPassword']}
                    className="margin-top-1"
                    error={errorState?.newPassword ? true : false}
                    helperText={errorState?.newPassword}
                    placeholder={'Enter New Password'}
                    required
                    fullWidth
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="cell small-12 margin-bottom-1 text-right">
            <TertiaryButton onClick={goToSignInPage} className="margin-right-2">
              BACK To SIGN IN
            </TertiaryButton>
            <PrimaryButton
              onClick={onClickPrimaryButton}
              type="submit"
              disabled={
                (isResetPswdFromEmail || isVerificationCodeView) &&
                (!pswdState['lowerCase'] ||
                  !pswdState['upperCase'] ||
                  !pswdState['minLength'] ||
                  !pswdState['number'] ||
                  !pswdState['specialCharacter'])
              }>
              {!isResetPswdFromEmail && !isVerificationCodeView
                ? I18n?.sendCode?.i18n_value || 'SEND CODE'
                : I18n?.submit?.i18n_value || 'SUBMIT'}
            </PrimaryButton>
          </div>
        </div>
      </form>
      {popoverTarget && (
        <PasswordPolicyPopover
          target={popoverTarget}
          pswdState={pswdState}
          onClose={(e: any) => {
            setPopOverTarget(null);
          }}
        />
      )}
      <LoadingDialog isDialogOpen={isLoading} />
    </Paper>
  );
}

function mapStateToProps(state: any) {
  return {
    configState: state.configState,
  };
}

export default connect(mapStateToProps)(ResetPassword);
