import * as types from "./actionTypes";
// import { API } from 'aws-amplify';
// import { SideMenuMap } from '../../types/AuthorityTypes';
import { sideMenuItemsSet } from "./sideMenuActions";
// import { apiName } from '../../_lib/lib';

export function userSet(user: any) {
  return {
    type: types.USER_SET,
    user,
  };
}

export function userStateSet(state: any) {
  return { type: types.USER_STATE_SET, state };
}

export function userAuthoritySet(authorities: any) {
  return { type: types.USER_AUTHORITIES_SET, authorities };
}

export const setUserAndFetchAuthorities = (user: any) => {
  return (dispatch: any) => {
    // const isRoleTenant =  user.signInUserSession.idToken.payload['cognito:groups'].indexOf('Tenant') > -1;
    const isRoleTenant = true;
    const role = user.signInUserSession.idToken.payload[
      "cognito:groups"
    ]?.reduce(
      (current: any, previous: string) => ({ ...current, [previous]: true }),
      {}
    );

    // dispatch(userRoleSet(role));
    dispatch(
      userSet({
        username: user.username,
        isRoleTenant,
        role: role || ["PLATFORM_ADMIN"],
      })
    );

    if (!isRoleTenant) return;

    const PxUserAuthorities = localStorage.getItem("PxUserAuthorities");
    const PxSideMenuItems = localStorage.getItem("PxSideMenuItems");

    if (!PxUserAuthorities || !PxSideMenuItems) {
      // API.get(apiName, '/px-api-gateway/security/authorities', {
      fetch("/", {
        headers: {},
      })
        .then(async (res: any) => {
          const authorityObj: any = {};
          const sideMenuItemsObj: any = {};

          // res.forEach((obj: any) => {
          //   authorityObj[obj['authority']] = true;

          //   if (SideMenuMap[obj['authority']]) {
          //     if (!Array.isArray(SideMenuMap[obj['authority']]))
          //       sideMenuItemsObj[SideMenuMap[obj['authority']]] = 'show';
          //     else {
          //       for (let authority of SideMenuMap[obj['authority']]) {
          //         sideMenuItemsObj[authority] = 'show';
          //       }
          //     }
          //   }
          // });

          localStorage.setItem(
            "PxUserAuthorities",
            JSON.stringify(authorityObj)
          );
          localStorage.setItem(
            "PxSideMenuItems",
            JSON.stringify(sideMenuItemsObj)
          );

          dispatch(userAuthoritySet(authorityObj));
          dispatch(sideMenuItemsSet(sideMenuItemsObj));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(userAuthoritySet(JSON.parse(PxUserAuthorities)));
      dispatch(sideMenuItemsSet(JSON.parse(PxSideMenuItems)));
    }
  };
};

export const userRoleSet = (role: string[]) => {
  return { type: types.USER_ROLE_SET, role };
};
