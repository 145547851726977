import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import {
  BackButton,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '../../components/AtomComponents';

import Progress from '../../components/Progress/Progress';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';

import './UpdateConfig.scss';

import { trimValue } from '../../_lib/lib';
import { Prompt } from 'react-router-dom';
import { isEmpty } from '../../_lib/utils';

import { ITenantList } from '../../types/IUpdateConfig';
import { IObject } from '../../types/IObject';

import ChangeDetector from '../../components/UpdateConfig/ChangeDetector';
import ConfigField from '../../components/UpdateConfig/ConfigField';


export const configurationLayout = [
  {
    label: 'General Configuration',
    check: (key: string) =>
      key.search(/CSS::/) && !['favicon', 'logoSmall', 'userPoolLogo'].includes(key),
    type: 'string',
  },
  {
    label: 'CSS Configuration',
    check: (key: string) => key.search(/CSS::/) === 0,
    type: 'string',
  },
  {
    label: 'Images and Logo',
    check: (key: string) => ['favicon', 'logoSmall', 'userPoolLogo'].includes(key),
    type: 'img',
  },
];

export const logoMapping: IObject<string> = {
  favicon: 'config_favicon_logo',
  userPoolLogo: 'config_userpool_logo',
  config_favicon_logo: 'favicon',
  config_userpool_logo: 'userPoolLogo',
};

const UpdateConfig = () => {
  const [tenantList, setTenantList] = useState<ITenantList>({} as ITenantList);
  const [configKeys, setConfigKeys] = useState<IObject<string>>({});
  const [tenantConfig, setTenantConfig] = useState<IObject<string>>({});
  const [updatedConfig, setUpdatedConfig] = useState<IObject<string>>({});
  const [updatedImages, setUpdatedImages] = useState<IObject<string>>({});

  const [selectedTenant, setSelectedTenant] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { openSnackBar, fetchUrl } = useDataService();

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    getAllConfigKeys();
    getAllTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkForChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantConfig, updatedConfig, updatedImages]);

  const getAllTenant = async () => {
    try {
      setIsLoading(true);
      const result = await fetchUrl('get', '/update-config/list-tenants');
      setTenantList(result);
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong,Unable to fetch tenant', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = async (tenant_id: string) => {
    try {
      setIsLoading(true);

      const res = await fetchUrl('post', '/update-config/tenant-config', {
        body: { tenant_id },
      });

      setTenantConfig(res.results);
      setUpdatedConfig(res.results);
      setUpdatedImages({});
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable to tenant configurations',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAllConfigKeys = async () => {
    try {
      setIsLoading(true);
      const result = await fetchUrl('get', '/update-config/list-configs');
      setConfigKeys(result.config_keys);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable to fetch config keys',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const tenantChanged = (e: React.ChangeEvent<{ value: string }>) => {
    setSelectedTenant(e.target.value);
    setUpdatedConfig({} as IObject<string>);
    setUpdatedImages({});
    getConfig(e.target.value);
  };

  const checkDisabledField = (key: string) => {
    return !Object.keys(configKeys)
      .filter((configKey) => configKeys[configKey])
      .includes(key);
  };

  const updateConfig = async () => {
    let body: any = { tenant_id: selectedTenant };
    if (!isEmpty(updatedImages)) {
      body = { ...body, ...updatedImages };
    }
    let updatedValues: IObject<string> = getUpdatedValues();

    if (!isEmpty(updatedValues)) {
      body = { ...body, config: { ...updatedValues } };
    }
    if (!isEmpty(updatedImages)) {
      const updatedImagesKey = Object.keys(updatedImages);
      let updatedImageValues = {};
      updatedImagesKey.forEach((key) => {
        updatedImageValues = {
          ...updatedImageValues,
          [logoMapping[key]]: tenantConfig[logoMapping[key]],
        };
      });
      body = { ...body, config: { ...body.config, ...updatedImageValues } };
    }
    try {
      setIsLoading(true);
      await fetchUrl('post', '/update-config', {
        body,
      });
      setTenantConfig(updatedConfig);
      setUpdatedImages({});
      getConfig(selectedTenant);
      openSnackBar('Updated successfully', eMessageType.success);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable to update config keys',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getUpdatedValues = () => {
    const updatedKeys = Object.keys(updatedConfig).filter(
      (key) => updatedConfig[key] !== tenantConfig[key]
    );
    let updatedValues = {};
    updatedKeys.forEach((key) => {
      updatedValues = { ...updatedValues, [key]: updatedConfig[key] };
    });
    return updatedValues;
  };

  const onChange = (e: any) => {
    setUpdatedConfig({ ...updatedConfig, [e.target.name]: e.target.value });
  };

  const cancelChanges = () => {
    setUpdatedConfig(tenantConfig);
    setUpdatedImages({});
  };

  const checkForChanges = () => {
    if (
      JSON.stringify(trimValue(tenantConfig)) === JSON.stringify(trimValue(updatedConfig)) &&
      isEmpty(updatedImages)
    ) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
  };

  // const checkForEmptyValue = () => {
  //   const emptyValues = Object.keys(updatedConfig).filter(
  //     (value: string) => !!!updatedConfig[value]
  //   );
  //   return emptyValues.length;
  // };

  if (isEmpty(tenantList)) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }
  return (
    <div className="px-update-config">
      <div className="cell small-6 medium-6 px-one-line-data">
        <LoadingDialog isDialogOpen={isLoading} />
        <BackButton />
        <h3 className="small-12">Update Config</h3>
      </div>
      <div className="grid-x ">
        <Prompt
          when={hasChanges}
          message={'You have some unsaved changes. OK to forget these changes and leave the page?'}
        />
        <div className="cell auto margin-bottom-1 action-button">
          <SecondaryButton
            className="margin-top-1"
            // disabled={disableCancel()}
            onClick={cancelChanges}>
            CANCEL CHANGES
          </SecondaryButton>
          <PrimaryButton
            className="margin-left-1 margin-top-1"
            disabled={!hasChanges}
            onClick={updateConfig}>
            SAVE CHANGES
          </PrimaryButton>
        </div>
      </div>
      <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
        <TextField
          select
          value={selectedTenant}
          onChange={tenantChanged}
          label="Tenant Name"
          className="text-field"
          disabled={Object.keys(tenantList?.tenants).length <= 1}>
          {tenantList?.tenants.map((tenant: string) => (
            <MenuItem key={tenant} value={tenant}>
              {tenant}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {!isEmpty(updatedConfig) &&
        configurationLayout.map((configuration) => (
          <Accordion className="margin-bottom-2 config-wrapper" key={configuration.label}>
            <AccordionSummary expandIcon={<ArrowDropDown />}>
              <Typography>
                <>{configuration.label}</>
              </Typography>
              <ChangeDetector
                label={configuration.label}
                updatedValues={updatedConfig}
                updatedImages={updatedImages}
                getUpdatedValues={getUpdatedValues}
              />
            </AccordionSummary>
            <AccordionDetails className="grid-y">
              {Object.keys(configKeys).map((config) => {
                return configuration.check(config) ? (
                  <ConfigField
                    config={config}
                    value={updatedConfig[config]}
                    name={config}
                    checkDisabledField={checkDisabledField}
                    onChange={onChange}
                    key={config}
                    type={configuration.type}
                    setUpdatedImages={setUpdatedImages}
                    updatedImages={updatedImages}
                    tenantConfig={tenantConfig}
                  />
                ) : (
                  <React.Fragment key={config} />
                );
              })}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default UpdateConfig;
