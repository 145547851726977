import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TextField from '../AtomComponents/TextField';
import DateDialog from './DateDialog';
import './DateField.scss';


interface IDateField {
  textfieldProps?: TextFieldProps;
  date: any;
  setDate: any;
  disabled?: {
    after?: any;
    before?: any;
  };
}

const commonDateFormat = 'MM/dd/yyyy';

const DateField = forwardRef(
  ({ textfieldProps, date, setDate, disabled }: IDateField, ref: any) => {
    const [dateError, setDateError] = useState<string>('');
    const [openCalendarState, setOpenCalendarState] = useState<boolean>(false);
    const onChange = (e: any) => {
      setDate(e.target.value);
    };
    const changeDateFromCalendar = (calendarDate: any) => {
      if (isValid(calendarDate)) {
        setDate(format(calendarDate, commonDateFormat));
      }
    };
    const toggleCalendar = () => {
      if (textfieldProps?.disabled) {
        return;
      }
      setOpenCalendarState(!openCalendarState);
    };

    useImperativeHandle(
      ref,
      () => ({
        isCorrectDate: () => {
          // find the format
          const dateFormatsSupported = [
            'Mo-dd-yyyy',
            'MM-dd-yyyy',
            'MMM-dd-yyyy',
            'MMMM-dd-yyyy',
            'MMMMM-dd-yyyy',
            'Mo/dd/yyyy',
            'MM/dd/yyyy',
            'MMM/dd/yyyy',
            'MMMM/dd/yyyy',
            'MMMMM/dd/yyyy',
            'Mo dd yyyy',
            'MM dd yyyy',
            'MMM dd yyyy',
            'MMMM dd yyyy',
            'MMMMM dd yyyy',
            'Mo.dd.yyyy',
            'MM.dd.yyyy',
            'MMM.dd.yyyy',
            'MMMM.dd.yyyy',
            'MMMMM.dd.yyyy',
            'MM/dd',
            'MM.dd',
            'MM dd',
            'MMMM/dd',
            'MMMM.dd',
            'MMMM dd',

            'yyyy-dd-Mo',
            'yyyy-dd-MM',
            'yyyy-dd-MMM',
            'yyyy-dd-MMMM',
            'yyyy-dd-MMMMM',
            'yyyy/dd/Mo',
            'yyyy/dd/MM',
            'yyyy/dd/MMM',
            'yyyy/dd/MMMM',
            'yyyy/dd/MMMMM',
            'yyyy dd Mo',
            'yyyy dd MM',
            'yyyy dd MMM',
            'yyyy dd MMMM',
            'yyyy dd MMMMM',
            'yyyy.dd.Mo',
            'yyyy.dd.MM',
            'yyyy.dd.MMM',
            'yyyy.dd.MMMM',
            'yyyy.dd.MMMMM',

            'dd-Mo-yyyy',
            'dd-MM-yyyy',
            'dd-MMM-yyyy',
            'dd-MMMM-yyyy',
            'dd-MMMMM-yyyy',
            'dd/Mo/yyyy',
            'dd/MM/yyyy',
            'dd/MMM/yyyy',
            'dd/MMMM/yyyy',
            'dd/MMMMM/yyyy',
            'dd Mo yyyy',
            'dd MM yyyy',
            'dd MMM yyyy',
            'dd MMMM yyyy',
            'dd MMMMM yyyy',
            'dd.Mo.yyyy',
            'dd.MM.yyyy',
            'dd.MMM.yyyy',
            'dd.MMMM.yyyy',
            'dd.MMMMM.yyyy',
            'dd/MMMM',
            'dd.MMMM',
            'dd MMMM',
          ];

          for (let i = 0; i < dateFormatsSupported.length; i++) {
            const parsedDate = parse(date, dateFormatsSupported[i], new Date());
            if (isValid(parsedDate)) {
              console.log(
                'current date: ',
                parsedDate,
                'is before',
                disabled?.before,
                'is',
                isBefore(parsedDate, parse(disabled?.before, commonDateFormat, new Date())),
                'is after',
                disabled?.after,
                'is',
                isBefore(parsedDate, parse(disabled?.after, commonDateFormat, new Date())),
                'format',
                dateFormatsSupported[i]
              );
              if (
                disabled?.before &&
                isBefore(parsedDate, parse(disabled.before, commonDateFormat, new Date()))
              ) {
                // console.log(
                //   'current date: ',
                //   parsedDate,
                //   'is before',
                //   disabled.before,
                //   'is',
                //   isBefore(parsedDate, parse(disabled.before, commonDateFormat, new Date())),
                //   'format',
                //   dateFormatsSupported[i]
                // );
                // check the date is before
                setDateError(`The ${textfieldProps?.label} cannot be before ${disabled.before}`);
                return false;
              }
              if (
                disabled?.after &&
                isAfter(parsedDate, parse(disabled.after, commonDateFormat, new Date()))
              ) {
                // console.log(
                //   'current date: ',
                //   parsedDate,
                //   'is after',
                //   disabled.after,
                //   'is',
                //   isAfter(parsedDate, parse(disabled.after, commonDateFormat, new Date())),
                //   'format',
                //   dateFormatsSupported[i]
                // );
                // check the date is after
                setDateError(`The ${textfieldProps?.label} cannot be after ${disabled.after}`);
                return false;
              }
              setDateError('');
              return parsedDate;
            }
          }

          setDateError(
            'Invalid Date! please enter the date in the format: MM/DD/YYYY or DD/MM/YYYY'
          );
          return false;
        },
        removeError: () => {
          onFocus();
        },
        showError: (error: string) => {
          setDateError(error);
        },
      }),
      // eslint-disable-next-line
      [date]
    );

    const onFocus = () => {
      setDateError('');
    };

    return (
      <div className="px-datefield-container">
        <DateDialog
          onChange={changeDateFromCalendar}
          disabled={disabled}
          dateVal={date}
          openDialog={openCalendarState}
          closeDialog={toggleCalendar}
        />
        <TextField
          onChange={onChange}
          onFocus={onFocus}
          {...textfieldProps}
          error={!!dateError}
          helperText={dateError}
          value={date}
          InputProps={{
            endAdornment: (
              <InputAdornment className="pointer-mouse" position="end" onClick={toggleCalendar}>
                <CalendarToday />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
);

export default DateField;
