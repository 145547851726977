import { combineReducers } from 'redux';
import userReducer from './userReducer';
import configReducer from './configReducer';
import contractsReducer from './contractsReducer';
import urlReducer from './urlReducer';
import flagsReducer from './flagsReducer';
import messageReducer from './messageReducer';
import sideMenuReducer from './sideMenuReducer';
import clientReducer from './clientsReducer';

const rootReducer = combineReducers({
  userState: userReducer,
  configState: configReducer,
  contractsState: contractsReducer,
  urlState: urlReducer,
  flagsState: flagsReducer,
  messageState: messageReducer,
  sideMenuState: sideMenuReducer,
  clientSortState: clientReducer
});

export const InitialState = () => {
  const PxConfig = localStorage.getItem('PxConfig');
  let config = PxConfig && JSON.parse(PxConfig);
  config = config && { ...config, isLoaded: true };

  const initState = {
    userState: null,
    configState: config || { isLoaded: false },
    // contractsState: null,
    // filterState: { filterData: null },
    // stockGroupState: { isLoaded: false, results: null },
    // stockState: { isLoaded: false, results: null },
    // stockCustomState: {},
    // offerClearingState: {
    //   results: null,
    //   isLoaded: false,
    //   stats: {},
    // },
    // inventoryState: null,
    urlState: { url: '', preventWindowClose: null, preventRouteChange: null, preventLogout: null },
    flagsState: { isHyla: window.location.href.indexOf('hyla') > -1 },
    sideMenuState: {
      Home: 'show',
      Web: 'hide',
      Inventory: 'hide',
      Channels: 'hide',
      Customers: 'hide',
      StockList: 'hide',
      Pricing: 'hide',
      OfferClearing: 'hide',
      Auctions: 'hide',
      Orders: 'hide',
      PreOrders: 'hide',
      Returns: 'hide',
      Settings: 'hide',
    },
    // preorderState: { awards: null, awardsClosed: null, importAwards: null },
  };
  console.log('initState', initState);
  return initState;
};

export default rootReducer;
