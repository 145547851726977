import React from 'react';
import { IInvoiceLineItem } from '../../types/IInvoice';
import { dataFormatting } from '../../_lib/lib';

interface IInvoiceLineItems {
  lineItems: IInvoiceLineItem[];
}

const InvoiceLineItems = ({ lineItems }: IInvoiceLineItems) => {
  return (
    <>
      <div className="invoice-line-items margin-left-1">
        <div className="cell small-6 medium-6 margin-bottom-1 px-one-line-data">
          <h4 className="margin-right-1 bold">Items</h4>
        </div>
        <InvoiceLineItemHeader />
        {lineItems.map((data: IInvoiceLineItem, index: number) => {
          return <InvoiceLineItem {...data} key={`${data.description}-${index}`} />;
        })}
      </div>
    </>
  );
};

export default InvoiceLineItems;

const InvoiceLineItem = ({ total, quantity, unitPrice, description }: IInvoiceLineItem) => {
  return (
    <div key={`${description}`} className="cell grid-x margin-vertical-1" data-id="orderLineItem">
      <div className="cell small-4 large-6" data-id='description'>
        <strong>
          <h5>{description || ''}</h5>
        </strong>
      </div>
      <div className="cell small-3 large-2 text-right" data-id="quantity">
        {quantity}
      </div>
      <div className="cell small-3 large-2 text-right" data-id="unitPrice">
        {dataFormatting('currency', unitPrice)}
      </div>
      <div className="cell small-3 large-2 text-right" data-id="amount">
        <div className="total-edit text-right">{dataFormatting('currency', total)}</div>
      </div>
    </div>
  );
};

const InvoiceLineItemHeader = () => {
  return (
    <div className="cell grid-x margin-bottom-2">
      <div className="cell small-4 large-6 margin-vertical-1 px-text-description capitalize">
        <span className="px-text-description">Description</span>
      </div>
      <div className="cell small-2 large-2 margin-vertical-1 text-right px-text-description capitalize">
        Qty.
      </div>
      <div className="cell small-3 large-2 margin-vertical-1 text-right px-text-description capitalize">
        Unit Price
      </div>
      <div className="cell small-3 large-2 margin-vertical-1 text-right px-text-description capitalize">
        Total
      </div>
    </div>
  );
};
