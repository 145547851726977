import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import apiToUrlMap from '../../ApiMapping';
// import useDataService from '../../hooks/useDataService';
import { I18nContext } from '../../I18n';
import { PrimaryIconButton, TertiaryButton } from '../AtomComponents';
import PrimaryButton from '../AtomComponents/PrimaryButton';
import Dialog from '../Dialog/Dialog';
import Logo from '../Logo/Logo';
import './TopMenu.scss';
import { logOutTheUser } from '../../_lib/lib';
// import { ClickAwayListener, MenuList, Popper } from '@material-ui/core';

interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

function TopMenu(props: any) {
  const I18n = useContext(I18nContext);
  // const { fetchUrl } = useDataService();
  // const [userData, setUserData] = useState<IUserInfo>();
  const [userData] = useState<IUserInfo>();

  // const [logoutMenu, setLogoutMenu] = React.useState<any>();
  const [logoutDialog, setLogoutDialog] = React.useState<any>();

  // const getStringColorCode = (str: string) => {
  //   return str ? str[0].charCodeAt(0) : 0;
  // };

  useEffect(() => {
    const fetchUserData = async () => {
      // try {
      //   const res: IUserInfo = await fetchUrl('get', apiToUrlMap.getUserInfo);
      //   res.firstName = res.firstName.trim();
      //   res.lastName = res.lastName.trim();
      //   // calculate user initial color
      //   const elem: any = document.querySelector('nav.px-c-TopMenu');
      //   if (elem) {
      //     const colorNumber =
      //       Math.abs((getStringColorCode(res.firstName) + getStringColorCode(res.lastName)) % 6) +
      //       1;
      //     elem.style.setProperty('--px_user_data_color', `var(--px_color_badge_${colorNumber})`);
      //   }
      //   if (!localStorage.getItem('userInfo'))
      //     localStorage.setItem('userInfo', JSON.stringify(res));
      //   setUserData(res);
      // } catch (error: any) {
      //   console.error('error while updating');
      // }
    };

    if (props.userState?.isSignedIn) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userState?.isSignedIn]);

  // const openMenu = (e: any) => {
  //   setLogoutMenu(e.currentTarget);
  // };
  const openLogoutDialog = (e: any) => {
    // closeMenu();
    setLogoutDialog(!!e);
  };

  const closeLogoutDialog = () => {
    // closeMenu();
    setLogoutDialog(undefined);
  };

  // const closeMenu = async () => {
  //   setLogoutMenu(undefined);
  // };

  const logout = async (e: any) => {
    e.preventDefault();
    closeLogoutDialog();
    try {
      await logOutTheUser(
        I18n?.logoutSuccessMessage?.i18n_value || 'You logged out successfully!',
        'success'
      );
    } catch (error: any) {
      console.error('error signing out: ', error);
    }
  };

  return (
    <>
      <h1 className="show-for-sr text-center">
        {props.configState.tenantShortName || 'ACME'} SASS
      </h1>
      <nav className="px-c-TopMenu grid-cols-12">
        <Link to="/" id="logo-link">
          <Logo />
        </Link>
        {props.userState.isSignedIn ? (
          <ul>
            <li>
              <TertiaryButton onClick={openLogoutDialog}>Logout</TertiaryButton>
            </li>
            {userData && (
              <li>
                <PrimaryIconButton className="px-user-initials">
                  {userData.firstName[0]?.toUpperCase() || '\u0020'}
                  {userData.lastName[0]?.toUpperCase() || '\u0020'}
                </PrimaryIconButton>
              </li>
            )}
          </ul>
        ) : (
          <></>
        )}
      </nav>

      {/* <Popper
        open={!!logoutMenu}
        placement="bottom-start"
        className="px-popper-menu px-accounts-menu"
        keepMounted={false}
        transition
        anchorEl={logoutMenu}>
        <ClickAwayListener onClickAway={closeMenu}>
          <MenuList>
            {props.contractsState?.isUserAdmin && (
              <MenuItem component={Link} to="/policy-settings">
                <span className="link">Policies</span>
              </MenuItem>
            )}
            <MenuItem onClick={openLogoutDialog}>
              <span className="link">Logout</span>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper> */}
      <Dialog
        isDialogOpen={!!logoutDialog}
        closeDialog={closeLogoutDialog}
        label={I18n?.logout?.i18n_value || 'Logout'}
        title={I18n?.logout?.i18n_value || 'Logout'}
        content={I18n?.logoutConfirmationMessage?.i18n_value || 'Are you sure you want to log out?'}
        actions={
          <>
            <TertiaryButton onClick={closeLogoutDialog}>
              {I18n?.cancel?.i18n_value || 'Cancel'}
            </TertiaryButton>
            <PrimaryButton onClick={logout}>{I18n?.logout?.i18n_value || 'Logout'}</PrimaryButton>
          </>
        }
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    urlState: state.urlState,
    configState: state.configState,
    contractsState: state.contractsState,
  };
}

export default connect(mapStateToProps)(TopMenu);
