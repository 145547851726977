import React from "react";
import Button from "@material-ui/core/Button";
import { PrimaryButton, TextField } from "../../../components/AtomComponents";

export function CheckStatus(props: any) {
  const getDate = (date: any) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  };

  return (
    <>
      <div className="small-12 margin-bottom-1">
        <h4>Check Tenant Status:</h4>
      </div>
      <div className="small-12">
        <TextField
          label="Tenant ID"
          size="small"
          className="textField"
          name="tenantId"
          value={props.tenantIdState || ""}
          onChange={props.setTenantId}
        />
      </div>
      <div className="small-12 margin-top-1">
        <PrimaryButton
          onClick={props.step1Process}
          disabled={
            (props.statusState && props.statusState?.loading) ||
            (props.inProgressState !== 0 && props.inProgressState !== 5) ||
            !props.tenantIdState ||
            !props.tenantIdState.length
          }
        >
          {props.inProgressState === 0 ? "Check" : "Wait..."}
        </PrimaryButton>
      </div>
      {props.statusState && (
        <div className="small-12 border-top margin-top-3 px-workflow-status">
          <h4>STATUS:</h4>
          {props.statusState?.timestamp && (
            <span className="block">
              <span>Date:</span> {`${getDate(props.statusState.timestamp)}`}
            </span>
          )}
          {props.statusState?.status && (
            <span className="block">
              <span>Status:</span> {`${props.statusState.status || "-"}`}
            </span>
          )}
          {props.statusState?.message && (
            <span className="block">
              <span>Message:</span> {`${props.statusState.message}`}
            </span>
          )}
          {props.statusState?.error && (
            <span className="block error">
              <span>Error:</span> {`${props.statusState.error}`}
            </span>
          )}
        </div>
      )}
      {props.processState !== 1 && (
        <div className="small-12">
          <Button
            variant="contained"
            className="margin-top-3"
            onClick={props.restartProcess}
          >
            Go to the beginning
          </Button>
        </div>
      )}
    </>
  );
}

export default CheckStatus;
