export interface IClientForm {
  clientName: string;
  companyName: string;
  contactEmail: string;
  createDate: Date;
  status: FormStatus;
  updateDate: Date;
  submissionId: number;
}
export type FormStatus = 'SUBMITTED' | 'COMPLETED' | 'REJECTED';

export interface IClientFormDetails {
  submissionId: number;
  tenant_id: string | null;
  status: FormStatus;
  contactEmail: string;
  officialLegalName: string;
  dba: string;
  jurisdictionOfOrganization: string;
  signerName: string;
  signerTitle: string;
  signerEmail: string;
  signerPhone: string;
  payableContactName: string;
  payableEmail: string;
  payablePhone: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingZipcode: string;
  businessName: string;
  businessShortName: string;
  currency: string;
  adminSiteUrl: string;
  adminUserEmail: string;
  buyerSiteUrl: string;
  buyerFromEmail: string;
  buyerSiteLanguage: string;
  logo: string | null;
  favicon: string | null;
  existingBusinessWebsite: string;
  createDate: Date;
  updatedDate: Date;
}
export const intialClientFormDetails = {
  contactEmail: null,
  officialLegalName: null,
  dba: null,
  jurisdictionOfOrganization: null,
  signerName: null,
  signerTitle: null,
  signerEmail: null,
  signerPhone: null,
  payableContactName: null,
  payableEmail: null,
  payablePhone: null,
  billingAddress1: null,
  billingAddress2: null,
  billingCity: null,
  billingState: null,
  billingCountry: null,
  billingZipcode: null,
  businessName: null,
  businessShortName: null,
  currency: null,
  adminSiteUrl: null,
  adminUserEmail: null,
  buyerSiteUrl: null,
  buyerFromEmail: null,
  buyerSiteLanguage: null,
  logo: null,
  favicon: null,
  existingBusinessWebsite: null,
};
