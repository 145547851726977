import React from "react";
import { useHistory } from "react-router-dom";

import { PrimaryButton } from "../AtomComponents";
import { ArrowForward } from "@material-ui/icons";

import "./Dashboard.scss";

interface IConfigBoxProps {
  header: string;
  description?: string;
  redirect: string;
}

export default function ConfigBox({
  header,
  description,
  redirect,
}: IConfigBoxProps) {
  const history = useHistory();
  return (
    <div className="cardx">
      <div className="cardx-section">
        <h4>{header}</h4>
        {description && <small>{description}</small>}
        <p className="margin-top-1">
          <PrimaryButton
            onClick={() => {
              history.push(`/${redirect}`);
            }}
          >
            <ArrowForward />
          </PrimaryButton>
        </p>
      </div>
    </div>
  );
}
