import { LinearProgress } from "@material-ui/core";
import React from "react";
import ConfigBox from "../../components/Dashboard/ConfigBox";

interface IProps {
  syncFor: string;
}

export const SchemaSync = ({ syncFor }: IProps) => {
  const UiBoxes = [
    {
      header: "Upload Queries",
      description: "Upload csv file containing queries",
      redirect: `config-schema/${syncFor}/manual-upload`,
    },
    {
      header: "Sync across Tenants",
      redirect: `config-schema/${syncFor}/horizontal-sync`,
    },
  ];

  if (process.env.REACT_APP_ENV === "test") {
    UiBoxes.push({
      header: "Sync across Environment",
      redirect: `config-schema/${syncFor}/vertical-sync`,
    });
  }

  return (
    <div className="margin-top-3">
      {UiBoxes.map((Uibox) => {
        return (
          <ConfigBox
            header={Uibox.header}
            description={Uibox.description}
            redirect={Uibox.redirect}
            key={`${Uibox.header}-${syncFor}`}
          />
        );
      })}
    </div>
  );
};
export const Wait = () => {
  return (
    <div className="grid-x px-config-wait">
      <div className="small-12">
        <h5>Processing. Please wait...</h5>
        <h6>This process can take 30 seconds or more.</h6>
      </div>
      <div className="small-12">
        <LinearProgress />
      </div>
    </div>
  );
};
