import React from 'react';
import { dataFormatting } from '../../_lib/lib';

interface IInvoiceSubtotal {
  subTotal: number;
  tax: number;
  taxDetails?: {
    taxLabel: string;
    taxPercentage: string;
  };
  total: number;
}

const InvoiceSubTotal = ({ subTotal, tax, total }: IInvoiceSubtotal) => {
  return (
    <div className="invoice-subtotal">
      <div className="order-metrics">
        <div className="detail">
          <div className="faded_1">Subtotal:</div>
          <div data-id="subTotal">{subTotal ? dataFormatting('currency', subTotal) : '$0.00'}</div>
        </div>
        <div className="detail">
          <div className="faded_1">Sales Tax:</div>
          <div data-id="tax">{dataFormatting('currency', tax)}</div>
        </div>
        <div className="detail">
          <div>
            <h4 className="faded_1">Total:</h4>
          </div>
          <div>
            <h4 className="bold" data-id="total">
              {total ? dataFormatting('currency', total) : '$0.00'}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSubTotal;
