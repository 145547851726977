import React, { useState } from 'react';

import {
  BackButton,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '../../components/AtomComponents';
import Progress from '../NewTenant/Progress/Progress';
import { ResellerFormConfig } from './ResellerFormConfig';
import './ResellerOnboarding.scss';
import { TextArea } from '../../components/AtomComponents';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { trimValue } from '../../_lib/lib';

const intialResellerDetails: any = {
  pxnsTenantId: '',
  pxnrTenantId: '',
  pxnrTenantName: '', //optional,
  pxnrTenantDescription: '', //optional
  pxnrParticipantId: '',
  pxnrCurrencyCode: '',
  pxnrCurrencySymbol: '',
  pxnrBuyerId: '',
  pxnrShippingAddressId: '',
  pxnrBillingAddressId: '',
  pxnrShippingOptionsJSON: '{}',
  pxnrPaymentOptionJSON: '{}',
  pxnrAdditionalServices: '[]',
  adminLoginEmail: '',
  resellerLoginEmail: '',
};

const ResellerOnboarding = () => {
  const [isLoading, setLoading] = useState(false);
  const [resellerDetails, setResellerDetails] = useState<any>(intialResellerDetails);
  const { fetchUrl, openSnackBar } = useDataService();
  const updateResellerDetails = (event: any) => {
    setResellerDetails({
      ...resellerDetails,
      [event.target.name]: event.target.value.length ? event.target.value : null,
    });
  };

  const clear = () => {
    setResellerDetails(intialResellerDetails);
  };

  const disableSave = () => {
    const { pxnrTenantName, pxnrTenantDescription, ...requiredResellerDetails } = resellerDetails;
    const isEmpty = !Object.values(requiredResellerDetails).every((data: any) =>
      data ? !!data.trim() : !!data
    );
    return isEmpty;
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      const resellerData = processDataForOnboarding(resellerDetails);
      if (resellerData) {
        await fetchUrl('post', apiToUrlMap.resellerOnboarding, {
          body: trimValue(resellerData),
        });
        openSnackBar('Reseller Onboarded Successfully', eMessageType.success);
        clear();
      }
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong', eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const processDataForOnboarding = (resellerDetails: any) => {
    const processedData = { ...resellerDetails };
    if (processedData.pxnrAdditionalServices) {
      try {
        processedData.pxnrAdditionalServices = JSON.parse(processedData.pxnrAdditionalServices);
      } catch (e) {
        openSnackBar('Additional service should be valid array', eMessageType.error);
        return;
      }
    }
    return processedData;
  };
  return (
    <div className="grid-y px-reseller-onboarding">
      {isLoading && <Progress />}
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12">Reseller Onboarding</h3>
      </div>
      <div className="cell auto margin-bottom-1 text-right">
        <SecondaryButton className="margin-top-1" onClick={clear}>
          CANCEL
        </SecondaryButton>
        <PrimaryButton
          className="margin-left-1 margin-top-1"
          disabled={disableSave()}
          onClick={saveChanges}>
          Onboard Reseller
        </PrimaryButton>
      </div>

      {ResellerFormConfig.map(
        (fields: {
          label: string;
          name: string;
          required: boolean;
          maxLength: number;
          isTextArea: boolean;
          isEmail: boolean;
        }) =>
          !fields.isTextArea ? (
            <TextField
              label={fields.label}
              size="medium"
              value={resellerDetails[fields.name] || ''}
              onChange={updateResellerDetails}
              placeholder={fields.label}
              className="margin-right-1 margin-bottom-2 width-60"
              name={fields.name}
              key={fields.name}
              inputProps={{ maxLength: fields.maxLength }}
            />
          ) : (
            <React.Fragment key={fields.name}>
              <span className="text-faded">{fields.label}</span>
              <TextArea
                value={resellerDetails[fields.name] || ''}
                onChange={updateResellerDetails}
                className="margin-right-1 margin-bottom-2 width-60"
                name={fields.name}
                minRows="7"
                cols={50}
                spellCheck={false}
              />
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default ResellerOnboarding;
