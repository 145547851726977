import React from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dialog } from '@material-ui/core';
import { parse, isBefore, isAfter } from 'date-fns';

interface IDateDialog {
  onChange: any;
  dateVal: any;
  openDialog: any;
  closeDialog: any;
  disabled?: {
    before?: any;
    after?: any;
  };
}

const commonDateFormat = 'MM/dd/yyyy';

function DateDialog({ onChange, dateVal, openDialog, closeDialog, disabled }: IDateDialog) {
  const changeDateValue = (date: any) => {
    onChange(date);
    closeDialog();
  };
  const disableBeforeAfter = (date: any) => {
    // console.log('date', date, 'before', disabled?.before, 'after', disabled?.after);
    if (disabled?.before) {
      const parsedBefore = parse(disabled.before, commonDateFormat, new Date());
      if (isBefore(date, parsedBefore)) {
        return true;
      }
    }

    if (disabled?.after) {
      const parsedAfter = parse(disabled.after, commonDateFormat, new Date());
      if (isAfter(date, parsedAfter)) {
        return true;
      }
    }
    return false;
  };
  return (
    <Dialog className="px-date-dialog" open={openDialog} onClose={closeDialog}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          value={dateVal}
          // disableFuture
          shouldDisableDate={disableBeforeAfter}
          onChange={changeDateValue}
        />
      </MuiPickersUtilsProvider>
    </Dialog>
  );
}

export default DateDialog;
