import { ColDef, ColGroupDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { dataFormatting } from '../../_lib/lib';

import { AgGridReact } from 'ag-grid-react';
import ClientFormStatus from './ClientFormStatus';
import { IClientForm } from '../../types/IClientForm';

interface IClientFormGrid {
  itemsState: IClientForm[];
}

const ClientFormGrid = ({ itemsState }: IClientFormGrid) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();

  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      setGridAPI(params.api);
    }
  };
  useEffect(() => {
    if (gridAPI && itemsState) {
      const columnDefs: ColDef[] | ColGroupDef[] = [
        {
          headerName: 'Company Name',
          field: 'companyName',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return (
              <NavLink to={`/client-form/${params.data.submissionId}`}>{params.value}</NavLink>
            );
          },
        },

        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return <ClientFormStatus label={params.value} />;
          },
        },
        {
          headerName: 'Contact Email',
          field: 'contactEmail',
          sortable: true,
          width: 200,
        
        },
        // {
        //   headerName: 'Client Name',
        //   field: 'clientName',
        //   sortable: true,
        //   width: 200,
        //   cellRendererFramework: (params: ICellRendererParams) => {
        //     return !!params.value ? params.value : '--'
        //   },
        // },
        {
          headerName: 'Create Date',
          field: 'createDate',
          sortable: true,
          valueFormatter: (params: { value: string }) => {
            return dataFormatting('dateInEST', params.value);
          },
          // sort: 'desc',
          width: 60,
        },
        {
          headerName: 'Update Date',
          field: 'updateDate',
          sortable: true,
          valueFormatter: (params: { value: string }) => {
            return dataFormatting('dateInEST', params.value);
          },
          width: 60,
        },
      ];

      gridAPI.setColumnDefs(columnDefs);
      gridAPI.setRowData(itemsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState, gridAPI]);

  const gridOptions: GridOptions = {
    headerHeight: 32,
    defaultColDef: {
      flex: 1,
      resizable: true,
      minWidth: 120,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      cellStyle: {
        wordBreak: 'break-word',
        height: '100%',
      },
    },
    getRowNodeId: function (data: { submissionId: string }) {
      return data.submissionId;
    },
    getRowHeight: () => 54,
    // onSelectionChanged: (params: SelectionChangedEvent) => {
    //   setSelectedItems(params.api.getSelectedNodes());
    // },
    animateRows: false,
    singleClickEdit: true,
    suppressAggFuncInHeader: true,
    stopEditingWhenGridLosesFocus: true,
    groupDefaultExpanded: -1,
    suppressMovableColumns: true,
    suppressContextMenu: true,
    // rowSelection: 'multiple',
    // grid scroll code
    debounceVerticalScrollbar: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    rowBuffer: 5,
    loadingOverlayComponentFramework: () => (
      <div className="px-grid-loader">
        <div className="px-circular-loader"></div>
        <h3 className="text-center margin-top-1">Loading...</h3>
      </div>
    ),
  };

  return (
    <div className="ag-theme-alpine px-height-100vh margin-top-2" data-id="client-form-table">
      <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
    </div>
  );
};

export default ClientFormGrid;
