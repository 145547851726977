import { Box, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { PrimaryButton } from "../../components/AtomComponents";
import FileWell from "../../components/AtomComponents/FileWell";
import useDataService from "../../hooks/useDataService";
import { eMessageType } from "../../types/IMessageType";
import { manualSchemaUpload } from "../SchemaSync/ManualUpload";
import JSONInput from "react-json-editor-ajrm";
import { localeEn } from "../ConfigTenant/ConfigTenant";
import { checkForSuccesInvocation } from "../../_lib/utils";

interface IProps {
  syncFor: string;
  messagesSet: any;
}
export const SchemaUpload = ({ syncFor, messagesSet }: IProps) => {
  const [fileState, setFileState] = React.useState<any>(null);
  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);
  const { openSnackBar } = useDataService();

  const setFile = (file: any) => {
    setFileState(file[0]);
  };

  const restart = () => {
    setProcessState(0);
    setFileState(null);
    setPreviewState(null)
  };

  const upload = () => {
    if (!fileState) {
      alert("Please choose a file");
    } else if (processState !== 0) {
      alert("Upload already in progress. Please wait!");
    } else if (fileState && processState === 0) {
      setProcessState(1);
      manualSchemaUpload(fileState, syncFor).then(async(data: any) => {
        if (!data.error) {
          try {
            checkForSuccesInvocation(data, setPreviewState, setProcessState)
          } catch (e: any) {
            openSnackBar(
              e.message || 'Something went wrong,Unable to check status',
              eMessageType.error
            );
          }
        } else {
          openSnackBar("Something went wrong", eMessageType.error);
          messagesSet(true, "Error on server!");
          restart();
        }
      });
    }
  };

  const Wait = () => {
    return (
      <div className="grid-x px-config-wait">
        <div className="small-12">
          <h5>Processing. Please wait...</h5>
          <h6>This process can take 30 seconds or more.</h6>
        </div>
        <div className="small-12">
          <LinearProgress />
        </div>
      </div>
    );
  };
  return (
    <Box>
      {processState === 0 && (
        <div>
          <FileWell
            filesLimit={1}
            acceptedFiles={[
              ".xlsx",
              ".xls",
              ".csv",
              "application/vnd.ms-excel",
              "application/msexcel",
              "application/x-msexcel",
              "application/x-ms-excel",
              "application/x-excel",
              "application/x-dos_ms_excel",
              "application/xls",
              "application/x-xls",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/excel",
            ]}
            handleDrop={setFile}
            className="margin-bottom-3 margin-top-3"
          />
          <PrimaryButton onClick={upload} disabled={!fileState}>
            Upload Schema
          </PrimaryButton>
        </div>
      )}

      {processState === 1 && <Wait />}

      {processState === 2 && (
        <>
          <div className="margin-bottom-3">
            <JSONInput
              id={`${syncFor}-manual-jsonEditor`}
              theme="light_mitsuketa_tribute"
              locale={localeEn}
              placeholder={previewState}
              height="550px"
              width="100%"
              viewOnly={true}
              style={{ body: { fontSize: "16px" } }}
            />
          </div>
          <Alert severity="success" className="margin-bottom-3">
            Schema Uploaded successfully!
          </Alert>
          <PrimaryButton onClick={restart}>Restart</PrimaryButton>
        </>
      )}
    </Box>
  );
};
