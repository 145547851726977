import React from 'react';
import apiToUrlMap from '../ApiMapping';
import { fetchData } from './lib';
import { eMessageType } from '../types/IMessageType';

export const isEmpty = (data: Object | Array<any> | undefined | null) => {
  if (!data) return true;
  if (data instanceof Array) return !data.length;
  if (data instanceof Object) return !Object.keys(data).length;
  return !data;
};

export const getKeyByValue = (obj: Record<string, any>, targetValue: any) => {
  let targetKey: string | undefined;
  for (let [key, value] of Object.entries(obj)) {
    if (value === targetValue) {
      targetKey = key;
      break;
    }
  }
  return targetKey;
};

export const getQueryString = <T,>(queryObj: T & Record<string, any>, previousState?: string) => {
  const queryParams = new URLSearchParams(previousState ?? queryObj);
  Object.keys(queryObj).forEach((key) => {
    queryParams.set(key, queryObj[key]);
  });
  return `?${queryParams}`;
};

export const parseQueryString = (queryString: string) => {
  const pageState: Record<string, any> = {};
  const queryParams = new URLSearchParams(queryString);
  queryParams.forEach((value, key) => {
    pageState[key] = value;
  });
  return pageState;
};

export const mergeObj = (target: any = {}, source: any) => {
  for (const key of Object.keys(source))
    if (source?.[key] instanceof Object) {
      Object.assign(source?.[key], mergeObj(target?.[key], source?.[key]));
    }

  Object.assign(target, source);
  return target;
};
export const objFromPath = (path: string, separator: string, payload: Object = {}) => {
  var tempObject = {} as any;
  var container = tempObject;
  path.split(separator).forEach((k, i, values) => {
    container = container[k] = i === values.length - 1 ? { ...payload } : {};
  });
  return tempObject;
};

export const getArray = <T,>(array: T[]): T[] => array;
export const findByKey = (arr: Array<any>, key: string, value: any) => {
  if (!Array.isArray(arr)) return null;
  return arr.find((obj: any) => obj[key] === value);
};

export const toCapitalize = (value: string) => {
  const lowerCase = value.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const allowFloatInput = (event: React.KeyboardEvent<Element>, negative: boolean = true) => {
  const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
  if (negative) validCharacters.push('-');
  if (!validCharacters.includes(event.key)) event.preventDefault();
};

export const preventArrowInInput = (event: React.KeyboardEvent<Element>) => {
  const preventList = ['ArrowUp', 'ArrowDown'];
  if (preventList.includes(event.key)) {
    event.preventDefault();
  }
};

export const convertToTitleCase = (str: string): string => {
  return str
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const isEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  return !!email.match(/^[\w-_\.+]{1,64}@[\w-].{1,252}\.[a-zA-Z]{1,252}$/g);
};

export const checkStatus = async (response: any) => {
  try {
    const result: any = await fetchData('post', apiToUrlMap.adminStatus, {
      body: { AdminOperationId: response.id },
    });
    return result;
  } catch (e: any) {
    throw new Error('Something went wrong');
  }
};

export const checkForSuccesInvocation = async (
  response: any,
  setPreviewState: any,
  setProcessState: any
) => {
  const result = await checkStatus(response);
  let timer;
  if (result.Status !== 'Waiting') {
    if (timer) clearInterval(timer);
    setPreviewState(result);
    setProcessState(2);
  } else {
    timer = setTimeout(() => {
      checkForSuccesInvocation(response, setPreviewState, setProcessState);
    }, 60000);
  }
};

const tenantApiEndPoint =
  process.env.REACT_APP_ADMIN_API ||
  'https://7aw3yxmw73.execute-api.us-east-1.amazonaws.com/Integration';

export const fetchDataWithoutAuthorization = async (method: string, url: string, body: any) => {
  let res: any;
  try {
    res = await fetch(`${tenantApiEndPoint}/${url}`, {
      method,
      body,
      // headers: { 'Content-Type': 'multipart/form-data' },
    });
    let finalResponse = {};
    if (res.status !== 200) {
      let response = await res.json();
      finalResponse = { error: true, ...response };
    } else {
      finalResponse = await res.json();
    }
    return finalResponse;
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const allowPhoneNumber = (event: React.KeyboardEvent<Element>) => {
  const validCharacters = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '-',
    '+',
    '(',
    ')',
    ' ',
  ];
  if (!validCharacters.includes(event.key)) event.preventDefault();
};

export const scrollIntoView = (id: string) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const allowNumbersOnly = (event: React.KeyboardEvent<Element>) => {
  const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (!validCharacters.includes(event.key)) event.preventDefault();
};

export const getMessageForPayment = (
  response: any,
  showCount?: boolean
): { message: any; type: eMessageType } => {
  const successMessage = `Payment${!showCount ? '' : '(s)'} ${
    !showCount ? 'Successful' : 'Succeeded'
  }`;
  const errorMessage = `Payment${!showCount ? '' : '(s)'} Failed`;
  const skippedMessage = 'Payment(s) Skipped';

  const message = response ? (
    <>
      {response.succeed ? (
        <p className="margin-0">
          {successMessage}
          {showCount ? `: ${response.succeed}` : ''}
        </p>
      ) : (
        <></>
      )}
      {response.skipped ? (
        <p className="margin-0">
          {skippedMessage}
          {showCount ? `: ${response.skipped}` : ''}
        </p>
      ) : (
        <></>
      )}
      {response.errored ? (
        <p className="margin-0">
          {errorMessage}
          {showCount ? `: ${response.errored}` : ''}
        </p>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
  let type = eMessageType.success;
  if (response.errored) {
    type = eMessageType.error;
  } else if (!response.skipped && !response.errored) {
    type = eMessageType.success;
  } else {
    type = eMessageType.warning;
  }
  return { message, type };
};

export const restrictFloatDigit = (e: any, feeType: string) => {
  if (e.target.value) {
    if (feeType === 'GMV_PERCENTAGE' && e.target.name === 'feeValue' && e.target.value > 100) {
      return e.target.value / 10;
    } else {
      return Math.round(e.target.value * 10000) / 10000;
    }
  } else {
    return 0;
  }
};
