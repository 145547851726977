export const ResellerFormConfig: any = [
  {
    label: 'Supplier Tenant ID *',
    name: 'pxnsTenantId',
    required: true,
    maxLength: 100,
  },
  {
    label: 'Reseller Tenant ID *',
    name: 'pxnrTenantId',
    required: true,
    maxLength: 100,
  },
  {
    label: 'Reseller Tenant Name',
    name: 'pxnrTenantName',
    maxLength: 100,
  },
  {
    label: 'Reseller Tenant Decription',
    name: 'pxnrTenantDescription',
    maxLength: 250,
    isTextArea: true,
  },
  {
    label: 'Reseller Participant ID *',
    name: 'pxnrParticipantId',
    maxLength: 100,
    required: true,
  },
  {
    label: 'Reseller Currency Code *',
    name: 'pxnrCurrencyCode',
    maxLength: 6,
    required: true,
  },
  {
    label: 'Reseller Currency Symbol *',
    name: 'pxnrCurrencySymbol',
    maxLength: 3,
    required: true,
  },
  {
    label: 'Reseller Buyer ID *',
    name: 'pxnrBuyerId',
    maxLength: 100,
    required: true,
  },
  {
    label: 'Reseller Shiiping Address ID *',
    name: 'pxnrShippingAddressId',
    maxLength: 100,
    required: true,
  },
  {
    label: 'Reseller Billing Address ID *',
    name: 'pxnrBillingAddressId',
    maxLength: 100,
    required: true,
  },
  {
    label: 'Reseller Shipping Option JSON *',
    name: 'pxnrShippingOptionsJSON',
    maxLength: 100,
    required: true,
    isTextArea: true,
  },
  {
    label: 'Reseller Payment Option JSON *',
    name: 'pxnrPaymentOptionJSON',
    maxLength: 100,
    required: true,
    isTextArea: true,
  },
  {
    label: 'Reseller Additional Services *',
    name: 'pxnrAdditionalServices',
    maxLength: 100,
    required: true,
    isTextArea: true,
  },
  {
    label: 'Admin Login Email *',
    name: 'adminLoginEmail',
    maxLength: 100,
    required: true,
    isEmail: true,
  },
  {
    label: 'Reseller Login Email *',
    name: 'resellerLoginEmail',
    maxLength: 100,
    required: true,
    isEmail: true,
  },
];
