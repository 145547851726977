import * as types from '../actions/actionTypes';

export default function contractsReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.CONTRACTS_SET:
      let isUserAdmin = !!action.isUserAdmin;
      if (typeof action.contracts === 'object' && Object.keys(action.contracts).length) {
        isUserAdmin = true;
      }
      return {
        data: action.contracts,
        isUserAdmin,
      };
    default:
      return state;
  }
}
