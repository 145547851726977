import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  withStyles,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import apiToUrlMap, { formatString } from '../../ApiMapping';
import { TertiaryButton } from '../../components/AtomComponents';
import Progress from '../../components/Progress/Progress';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { checkNullOrUndefined, currencyFormatter, dataFormatting } from '../../_lib/lib';
import { BillingPeriodOrdersDetail } from '../../components/BillingPeroidOrders/BillingPeriodOrdersDetails';
import './BillingPeriodOrders.scss';

interface IOrderData {
  orderNumber: string;
  shippedDate: string;
  shippedAmountInTenantCurrency: number;
  shippedAmountInUsd: number;
}

const BorderLessCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '16px 16px 16px 0',
  },
})(TableCell);

const ColoredEvenRows = withStyles({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'RGBA(145,52,85,0.03)',
    },
  },
})(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 650,
  },
  tableHead: {
    borderBottom: '1px solid RGBA(var(--px_color_text_primary), var(--px_alpha_medium))',
  },
});

const orderColumns: any = [
  {
    id: 'orderNumber',
    label: 'Order Number',
    width: 130,
    align: 'left',
  },
  {
    id: 'shippedDate',
    label: 'Shipped Date',
    align: 'left',
    width: 120,
    format: (value: string) => dataFormatting('dateInEST', value),
  },
  {
    id: 'shippedAmountInUsd',
    label: 'Shipped USD Amount in Period',
    align: 'left',
    width: 150,
    format: (value: number) => currencyFormatter(value, 2, false, '$'),
  },
];

const getOrderColumns = (tab: string, invoiceBillingPeriodOrders: any) => {
  const billingOrderColumns = [...orderColumns];
  if (tab === 'storefront') {
    if (invoiceBillingPeriodOrders.storefront.currencyCode !== 'USD') {
      billingOrderColumns.splice(2, 0, {
        id: 'shippedAmountInTenantCurrency',
        label: `Shipped ${invoiceBillingPeriodOrders.storefront.currencyCode} Amount in Period`,
        align: 'left',
        width: 150,
        format: (value: number) =>
          currencyFormatter(value, 2, false, invoiceBillingPeriodOrders.storefront.currency),
      });
    }
  } else if (tab === 'offline') {
    if (invoiceBillingPeriodOrders.offline.currencyCode !== 'USD') {
      billingOrderColumns.splice(2, 0, {
        id: 'shippedAmountInTenantCurrency',
        label: `Shipped ${invoiceBillingPeriodOrders.offline.currencyCode} Amount in Period`,
        align: 'left',
        width: 150,
        format: (value: number) =>
          currencyFormatter(value, 2, false, invoiceBillingPeriodOrders.offline.currency),
      });
    }
  } else if (tab !== 'storefront' && invoiceBillingPeriodOrders.connect.length) {
    const invoicedOrder = invoiceBillingPeriodOrders.connect.find(
      (supplier: any) => supplier.supplierTenantName === tab
    );
    if (invoicedOrder.currencyCode !== 'USD') {
      billingOrderColumns.splice(2, 0, {
        id: 'shippedAmountInSupplierCurrency',
        label: `Shipped ${invoicedOrder.currencyCode} Amount in Period`,
        align: 'left',
        width: 150,
        format: (value: number) => currencyFormatter(value, 2, false, invoicedOrder.currency),
      });
    }
  }
  return billingOrderColumns;
};

const OrdersGridHead = (props: any) => {
  const { order, orderBy, onRequestSort, billingPeriodOrderColumns } = props;
  const { tableHead } = useStyles();

  const createSortHandler = (property: keyof IOrderData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {billingPeriodOrderColumns.map((column: any) => (
          <BorderLessCell
            className={tableHead}
            key={column.id}
            align={column.align}
            width={column.width}
            sortDirection={orderBy === column.id ? order : false}>
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}>
              {column.label}
            </TableSortLabel>
          </BorderLessCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const OrdersGrid = ({ gridData, tab, invoiceBillingPeriodOrders }: any) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('shippedDate');
  const classes = useStyles();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IOrderData) => {
    const isDescOrder = orderBy === property && order === 'desc';
    setOrder(isDescOrder ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    let orderByColumnA = orderBy,
      orderByColumnB = orderBy;
    if (orderBy === 'orderNumber') {
      orderByColumnA = a.refOrderNumber ? 'refOrderNumber' : 'phonexOrderNumber';
      orderByColumnB = b.refOrderNumber ? 'refOrderNumber' : 'phonexOrderNumber';
    }
    if (b[orderByColumnB] < a[orderByColumnA]) {
      return -1;
    }
    if (b[orderByColumnB] > a[orderByColumnA]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: string, orderBy: string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const sortData = (data: IOrderData[], comparator: any) => {
    const sortedData = data.map((values: IOrderData, index: number) => [values, index]);
    sortedData.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return sortedData.map((value: any) => value[0]);
  };
  const billingPeriodOrderColumns = getOrderColumns(tab, invoiceBillingPeriodOrders);
  return (
    <TableContainer className="px-terms-orders-grid">
      <Table className={classes.table} aria-label="orders" stickyHeader>
        <OrdersGridHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          billingPeriodOrderColumns={billingPeriodOrderColumns}
        />
        <TableBody>
          {sortData(gridData, getComparator(order, orderBy)).map((row: any) => {
            return (
              <ColoredEvenRows hover key={row.phonexOrderNumber} data-id={row.phonexOrderNumber}>
                {billingPeriodOrderColumns.map((column: any) => {
                  const value =
                    column.id === 'orderNumber'
                      ? row.refOrderNumber || row.phonexOrderNumber
                      : row[column.id];
                  return (
                    <BorderLessCell
                      key={column.id}
                      data-id={column.id}
                      align={column.align}
                      width={column.width}
                      className={`px-fontsize-14px ${column.classes ? column.classes : ''}`}>
                      {column.format ? column.format(value) : value}
                    </BorderLessCell>
                  );
                })}
              </ColoredEvenRows>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ExchangeRateSummary = ({
  tab,
  invoiceBillingPeriodOrders,
}: {
  tab: string;
  invoiceBillingPeriodOrders: any;
}) => {
  let tabInfo: any = {};
  if (tab === 'storefront') {
    tabInfo = invoiceBillingPeriodOrders.storefront;
    tabInfo.label = 'Storefront Shipped';
    tabInfo.shippedAmountKey = 'shippedAmountInTenantCurrency';
  } else if (tab === 'offline') {
    tabInfo = invoiceBillingPeriodOrders.offline;
    tabInfo.label = 'Offline Shipped';
    tabInfo.shippedAmountKey = 'shippedAmountInTenantCurrency';
  } else {
    //a supplier tab is selected;
    tabInfo = invoiceBillingPeriodOrders.connect.find(
      (supplier: any) => supplier.supplierTenantName === tab
    );
    tabInfo.label = tabInfo.supplierTenantName;
    tabInfo.shippedAmountKey = 'shippedAmountInSupplierCurrency';
  }
  if (tabInfo.currencyCode !== 'USD') {
    return (
      <div className="margin-bottom-2">
        <div className="grid-x px-main-padding px-fontsize-16px">
          <div className="cell small-4 large-2 faded_1">
            {tabInfo.label} {tabInfo.currencyCode}:
          </div>
          <div className="cell small-4 large-1 align-right " data-id="storefrontShippedTenant">
            {currencyFormatter(tabInfo[tabInfo.shippedAmountKey], 2, false, tabInfo.currency)}
          </div>
        </div>
        <div className="grid-x px-main-padding px-fontsize-16px">
          <div className="cell small-4 large-2 faded_1">
            Exchange Rate ({tabInfo.currencyCode} to USD):
          </div>
          <div
            className="cell small-4 large-1 align-right "
            data-id="storefrontShippedTenantExchangeRate">
            {tabInfo.exchangeRate && parseFloat(tabInfo.exchangeRate.toFixed(8))}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const InvoiceBillingPeriodOrders = () => {
  const [invoiceBillingPeriodOrders, setInvoiceBillingPeriodOrders] = useState<any>();
  const [tab, setTab] = useState<string>();
  const [tabContent, setTabContent] = useState<Record<string, any>>({});
  const [gridData, setGridData] = useState<any>();
  const location = useLocation();
  const pathnames = location.pathname.split('/');
  const invoiceNumber = pathnames[pathnames.length - 1].toString();
  const history = useHistory();

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Billing Period Orders';
  }, []);

  const { openSnackBar, fetchUrl } = useDataService();

  useEffect(() => {
    let isCancelled = false;
    async function getInvoiceBillingPeriodOrders() {
      try {
        const billingPeriodOrders = await fetchUrl(
          'GET',
          formatString(apiToUrlMap.getBillingPeriodOrders, { invoiceNumber }),
          {}
        );
        if (!isCancelled) {
          const tabData: Record<string, any> = {};
          if (billingPeriodOrders.storefront.orders.length > 0) {
            tabData.storefront = { label: 'STOREFRONT' };
          }
          if (billingPeriodOrders.offline.orders.length > 0) {
            tabData.offline = { label: 'OFFLINE' };
          }
          if (billingPeriodOrders.connect.length > 0) {
            billingPeriodOrders.connect.forEach((supplier: any) => {
              tabData[supplier.supplierTenantName] = {
                label: `CONNECT (${supplier.supplierTenantName})`,
              };
            });
          }
          if (tabData.storefront) {
            setGridData(billingPeriodOrders.storefront.orders);
            setTab('storefront');
          } else if (tabData.offline) {
            setGridData(billingPeriodOrders.offline.orders);
            setTab('offline');
          } else if (Object.keys(tabData).length > 0) {
            setGridData(billingPeriodOrders.connect[0].orders);
            setTab(`${billingPeriodOrders.connect[0].supplierTenantName}`);
          } else setGridData([]);
          setInvoiceBillingPeriodOrders(billingPeriodOrders);
          setTabContent(tabData);
        }
      } catch (error: any) {
        console.error(error);
        openSnackBar(
          `Something went wrong while fetching invoice billing period orders-${error.message}`,
          eMessageType.error
        );
        setInvoiceBillingPeriodOrders({});
        history.push('/');
      }
    }
    getInvoiceBillingPeriodOrders();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const changeTab = (e: any, tab: string) => {
    let orders: any;
    setTab(tab);
    if (tab === 'storefront') {
      orders = invoiceBillingPeriodOrders.storefront.orders;
    } else if (tab === 'offline') {
      orders = invoiceBillingPeriodOrders.offline.orders;
    } else {
      //a supplier tab is selected;
      const supplierInfo = invoiceBillingPeriodOrders.connect.find(
        (supplier: any) => supplier.supplierTenantName === tab
      );
      orders = supplierInfo.orders;
    }
    setGridData(orders);
  };

  if (
    checkNullOrUndefined(invoiceBillingPeriodOrders) ||
    checkNullOrUndefined(tabContent) ||
    checkNullOrUndefined(gridData)
  )
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );

  return (
    <div className="billing-period">
      <div className="grid-x cell margin-bottom-2">
        <TertiaryButton
          data-id="goBack"
          onClick={goBack}
          className="margin-0 padding-0 px-color-text-primary go-back">
          <ArrowBackIos />
        </TertiaryButton>
        <h3 className="cell auto font-small-h2">{invoiceNumber} / Billing Period Orders</h3>
      </div>
      <BillingPeriodOrdersDetail invoiceBillingPeriodOrders={invoiceBillingPeriodOrders} />
      {gridData!.length && tab ? (
        <>
          <Tabs
            value={tab}
            data-id={tab}
            onChange={changeTab}
            indicatorColor="secondary"
            textColor="secondary"
            color="primary"
            className="margin-top-2">
            {Object.keys(tabContent).map((tab) => (
              <Tab
                key={tab}
                value={tab}
                label={tabContent[tab].label}
                color="primary"
                style={{
                  maxWidth: 'max-content',
                }}
              />
            ))}
          </Tabs>
          <ExchangeRateSummary tab={tab} invoiceBillingPeriodOrders={invoiceBillingPeriodOrders} />
          <OrdersGrid
            gridData={gridData}
            tab={tab}
            invoiceBillingPeriodOrders={invoiceBillingPeriodOrders}
          />
        </>
      ) : (
        <div className="margin-top-2">No Orders Found</div>
      )}
    </div>
  );
};

export default InvoiceBillingPeriodOrders;
