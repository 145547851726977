import React from 'react';

import { dataFormatting } from '../../_lib/lib';

export const BillingPeriodOrdersDetail = ({ invoiceBillingPeriodOrders }: any) => {
  return (
    <>
      <div className="grid-x px-main-padding px-fontsize-16px">
        <div className="cell small-4 large-2 faded_1">Billing Period Start:</div>
        <div className="cell auto" data-id="billingPeriodStart">
          {invoiceBillingPeriodOrders.billingPeriodStartDate
            ? dataFormatting('dateInEST', invoiceBillingPeriodOrders.billingPeriodStartDate)
            : '--'}
        </div>
      </div>
      <div className="grid-x px-main-padding px-fontsize-16px">
        <div className="cell small-4 large-2 faded_1">Billing Period End:</div>
        <div className="cell auto " data-id="billingPeriodEnd">
          {invoiceBillingPeriodOrders.billingPeriodEndDate
            ? dataFormatting('dateInEST', invoiceBillingPeriodOrders.billingPeriodEndDate)
            : '--'}
        </div>
      </div>
      {invoiceBillingPeriodOrders.storefront.orders.length > 0 && (
        <div className="grid-x px-main-padding px-settings-title">
          <div className="cell small-4 large-3 faded_1">Storefront Shipped USD:</div>
          <div className="cell small-3 large-1 align-right px-bold" data-id="storefrontShipped">
            {dataFormatting('currency', invoiceBillingPeriodOrders.storefront.shippedAmountInUsd)}
          </div>
        </div>
      )}
      {invoiceBillingPeriodOrders.offline.orders.length > 0 && (
        <div className="grid-x px-main-padding px-settings-title">
          <div className="cell small-4 large-3 faded_1">Offline Shipped USD:</div>
          <div className="cell small-3 large-1 align-right px-bold" data-id="offlineShipped">
            {dataFormatting('currency', invoiceBillingPeriodOrders.offline.shippedAmountInUsd)}
          </div>
        </div>
      )}
      {invoiceBillingPeriodOrders.connect.length > 0 &&
        invoiceBillingPeriodOrders.connect.map((order: any) => (
          <div className="grid-x px-main-padding px-settings-title">
            <div className="cell small-4 large-3 faded_1">
              Connect {`(${order.supplierTenantName})`} Shipped USD:
            </div>
            <div className="cell  small-3 large-1 align-right px-bold" data-id="connectFeesUSD">
              {dataFormatting('currency', order.shippedAmountInUsd)}
            </div>
          </div>
        ))}
    </>
  );
};
