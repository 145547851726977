import React from 'react';

interface IClientStatusProps {
  label: 'Active' | 'Trial Period' | 'Deactivated' | 'Onboarding' | 'Pending Deactivation';
  dataId: string;
}

const ClientsStatus = ({ label, dataId }: IClientStatusProps) => {
  const getBackgroundColor = () => {
    switch (label) {
      case 'Active':
        return 'px-background-badge_2';
      case 'Trial Period':
        return 'px-background-badge_1';
      case 'Deactivated':
        return 'px-background-badge_6';
      case 'Onboarding':
        return 'px-background-badge_4';
      case 'Pending Deactivation':
        return 'px-background-purple';
      default:
        return 'px-background-badge_2';
    }
  };
  return (
    <span data-id={dataId} className={`px-grid-chips ${getBackgroundColor()}`}>
      {label}
    </span>
  );
};

export default ClientsStatus;
