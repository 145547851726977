import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import {
  differenceInCalendarDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isValid,
  sub,
} from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import parse from 'date-fns/parse';
import React, { useEffect, useRef, useState } from 'react';
import {
  IAdvanceFilterFn,
  IDateFilterStrategy,
  IFilterDialog,
  IFiltersApplied,
  IFilterSaveParams,
} from '../../../types/IFilterEngine';
import { getDateFormat } from '../../../_lib/lib';
import { PrimaryButton, TertiaryButton, TextField } from '../../AtomComponents';
import DateField from '../../DateField/DateField';
import './FilterDate.scss';
interface IFilterDate extends IFilterDialog {
  applyButtonLabel?: any;
  clearButtonLabel?: any;
}

export const commonDateFormat = 'MM/dd/yyyy';

const prefilledDateRangeOptions = [
  {
    id: 'seven-days-incl-today',
    label: 'Last 7 days + Today',
  },
  {
    id: 'fourteen-days-incl-today',
    label: 'Last 14 days + Today',
  },
  {
    id: 'twentyeight-days-incl-today',
    label: 'Last 28 days + Today',
  },
  {
    id: 'custom',
    label: 'Custom',
  },
];

function FilterDate({
  customOptionObj,
  title,
  attribute,
  clearFilter,
  saveFilter,
  closeDialog,
  getLabel,
  getCount,
  applyButtonLabel = 'APPLY',
  clearButtonLabel = 'Clear',
}: IFilterDate) {
  // initial filter set
  const [prefilledRange, setPrefilledRange] = useState<any>('');
  const [disabledCustomDateSelection, setDisableCustomDateSelection] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<IDateFilterStrategy>();

  useEffect(() => {
    if (customOptionObj) {
      try {
        const startDate = customOptionObj.startDate ? new Date(customOptionObj.startDate) : '';
        const endDate = customOptionObj.endDate ? new Date(customOptionObj.endDate) : '';
        // set the prefilledRange,
        const dateDifference = startDate && endDate ? differenceInDays(endDate, startDate) : -1;
        let prefilledDateRangeState = '';
        switch (dateDifference) {
          case 7:
            prefilledDateRangeState = 'seven-days-incl-today';
            break;
          case 14:
            prefilledDateRangeState = 'fourteen-days-incl-today';
            break;
          case 28:
            prefilledDateRangeState = 'twentyeight-days-incl-today';
            break;
          default:
            if (dateDifference >= 0) {
              prefilledDateRangeState = 'custom';
            }
            break;
        }

        // set disabledCustomDateSelection,
        setPrefilledRange(prefilledDateRangeState || '');
        if (prefilledDateRangeState === 'custom') {
          setDisableCustomDateSelection(false);
        }

        if (startDate && endDate)
          // set disabled dates
          setDisabledDates({
            before: format(startDate, commonDateFormat),
            after: format(endDate, commonDateFormat),
          });

        // set rangeDates
        setFilterOptions({
          startDate: startDate ? format(startDate, commonDateFormat) : '',
          endDate: endDate ? format(endDate, commonDateFormat) : '',
        });
      } catch (error: any) {
        setFilterOptions({
          startDate: '',
          endDate: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customOptionObj]);

  const setStartDate = (val: any) => {
    setFilterOptions({
      startDate: val,
      endDate: filterOptions?.endDate || '',
    });
    setDisabledDates({
      ...disabledDates,
      before: val,
    });
  };

  const setEndDate = (val: any) => {
    setFilterOptions({
      startDate: filterOptions?.startDate || '',
      endDate: val,
    });
    setDisabledDates({
      ...disabledDates,
      after: val,
    });
  };

  const [disabledDates, setDisabledDates] = useState<any>({
    before: new Date(),
    after: new Date(),
  });
  const startDateRef = useRef<any>();
  const endDateRef = useRef<any>();

  const setPrefilledDateRange = (e: any) => {
    let startDate = new Date();
    let startDay: any;
    if (e.target.value !== 'custom' && !disabledCustomDateSelection) {
      setDisableCustomDateSelection(true);
    }
    switch (e.target.value) {
      case 'seven-days-incl-today':
        startDay = startDate.getDate() - 7;
        break;
      case 'fourteen-days-incl-today':
        startDay = startDate.getDate() - 14;
        break;
      case 'twentyeight-days-incl-today':
        startDay = startDate.getDate() - 28;
        break;
      default:
        // enable the custom field edit
        startDay = startDate.getDate();
        setDisableCustomDateSelection(false);
        break;
    }

    startDate.setDate(startDay);
    setDisabledDates({
      ...disabledDates,
      before: format(startDate, commonDateFormat),
      after: format(new Date(), commonDateFormat),
    });
    setPrefilledRange(e.target.value);
    setFilterOptions({
      ...filterOptions,
      startDate: format(startDate, commonDateFormat),
      endDate: format(new Date(), commonDateFormat),
    });
  };

  const showInvalidDatesError = () => {
    // show error
    if (startDateRef?.current?.showError) {
      startDateRef.current.showError(`Invalid Dates`);
    }

    if (endDateRef?.current?.showError) {
      endDateRef.current.showError(`Invalid Dates`);
    }
  };
  const handleApply = () => {
    const dates: any = {
      startDate: '',
      endDate: '',
    };

    const startDate = filterOptions?.startDate || '',
      endDate = filterOptions?.endDate || '';

    const startDateFormat = getDateFormat(startDate);
    const endDateFormat = getDateFormat(endDate);
    if (prefilledRange && (!startDateFormat || !endDateFormat || !startDate || !endDate))
      return showInvalidDatesError();

    dates.startDate = startOfDay(parse(startDate, startDateFormat, new Date()));
    dates.endDate = endOfDay(parse(endDate, endDateFormat, new Date()));

    // check if start date < end date
    if (dates.startDate && dates.endDate && isAfter(dates.startDate, dates.endDate)) {
      // show error
      if (startDateRef?.current?.showError)
        startDateRef.current.showError(`The start date cannot be before ${endDate}`);
      if (endDateRef?.current?.showError)
        endDateRef.current.showError(`The end date cannot be before ${startDate}`);
      return;
    }

    let label = '';
    switch (prefilledRange) {
      case 'seven-days-incl-today':
        label = 'Last 7 Days';
        break;
      case 'fourteen-days-incl-today':
        label = 'Last 14 Days';
        break;
      case 'twentyeight-days-incl-today':
        label = 'Last 28 Days';
        break;
      case 'custom':
        label = `${format(dates.startDate || 0, 'MMM dd')} -  ${format(
          dates.endDate || 0,
          'MMM dd'
        )}`;
        break;
    }

    const updatedFilter: IFilterSaveParams = {
      title: title,
      attribute: attribute,
      selectedFilters:
        filterOptions?.endDate && filterOptions?.startDate
          ? {
              ...dates,
              label,
            }
          : {
              label: 'All',
            },
    };

    // handle filter
    saveFilter(updatedFilter);
  };

  const handleClear = () => {
    clearFilter({ title, attribute });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>{' '}
      </DialogTitle>

      <DialogContent>
        <div className="grid-x margin-top-1">
          <TextField
            className="cell small-12"
            onFocus={() => startDateRef.current?.removeError()}
            select
            label="Select Date Range"
            value={prefilledRange}
            onChange={setPrefilledDateRange}>
            {prefilledDateRangeOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <div className="small-12 margin-top-3 px-custom-date-range-filter-selector-container">
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: 'Start Date',
                disabled: disabledCustomDateSelection,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                after: disabledDates?.after,
              }}
              date={filterOptions?.startDate}
              setDate={setStartDate}
              ref={startDateRef}
            />
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: 'End Date',
                disabled: disabledCustomDateSelection,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: disabledDates?.before,
              }}
              date={filterOptions?.endDate}
              setDate={setEndDate}
              ref={endDateRef}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>{clearButtonLabel}</TertiaryButton>
        <PrimaryButton onClick={handleApply}>{applyButtonLabel}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export const createDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { createDate } = item;
  if (!createDate || typeof createDate !== 'string') {
    return false;
  }
  const filteredDates: any = filtersApplied.createDate;

  if (!filteredDates.startDate || !filteredDates.endDate) return true;
  const createdDate = new Date(createDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(createdDate, startDate) || isAfter(createdDate, endDate)) {
    return false;
  }

  return true;
};

export const updateDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { updateDate } = item;
  if (!updateDate || typeof updateDate !== 'string') {
    return false;
  }

  const filteredDates: any = filtersApplied.updateDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }

  const updatedDate = new Date(updateDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(updatedDate, startDate) || isAfter(updatedDate, endDate)) {
    return false;
  }

  return true;
};

export const invoiceDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { invoiceDate } = item;
  if (!invoiceDate || typeof invoiceDate !== 'string') {
    return false;
  }

  const filteredDates: any = filtersApplied.invoiceDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }

  const updatedDate = new Date(invoiceDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(updatedDate, startDate) || isAfter(updatedDate, endDate)) {
    return false;
  }

  return true;
};

export const dueDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { dueDate } = item;
  if (!dueDate || typeof dueDate !== 'string') {
    return false;
  }

  const filteredDates: any = filtersApplied.dueDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }

  const updatedDate = new Date(dueDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(updatedDate, startDate) || isAfter(updatedDate, endDate)) {
    return false;
  }

  return true;
};

export const billingPeriodStartDateFilter: IAdvanceFilterFn = (
  item: any,
  filtersApplied: IFiltersApplied
) => {
  const { billingPeriodStartDate } = item;
  if (!billingPeriodStartDate || typeof billingPeriodStartDate !== 'string') {
    return false;
  }

  const filteredDates: any = filtersApplied.billingPeriodStartDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }

  const updatedDate = new Date(billingPeriodStartDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(updatedDate, startDate) || isAfter(updatedDate, endDate)) {
    return false;
  }

  return true;
};

export default FilterDate;

export const getFilteredDates = (decodedDateFilters: any) => {
  let startDate,
    endDate = new Date();
  switch (decodedDateFilters.optionId) {
    case 'today':
      startDate = new Date();
      break;
    case 'seven-days-incl-today':
      startDate = sub(new Date(), { days: 7 });
      break;
    case 'fourteen-days-incl-today':
      startDate = sub(new Date(), { days: 14 });
      break;
    case 'twentyeight-days-incl-today':
      startDate = sub(new Date(), { days: 28 });
      break;
    case 'custom':
      startDate = new Date(decodedDateFilters.startDate);
      endDate = new Date(decodedDateFilters.endDate);
      break;
    default:
      startDate = new Date(decodedDateFilters.startDate);
      endDate = new Date(decodedDateFilters.endDate);
  }
  return {
    startDate: isValid(startDate) ? startDate : undefined,
    endDate: isValid(endDate) ? endDate : undefined,
  };
};

export const getDateFilterLabel = (startDateStr: string, endDateStr: string, optionId: string) => {
  try {
    return (
      (optionId && getDateFilterLabelFromOptionId(startDateStr, endDateStr, optionId)) ||
      getDateFilterLabelFromDayDifference(startDateStr, endDateStr)
    );
  } catch (error) {
    console.log('error while parsing the filter date', error);

    return 'Infinity';
  }
};

const getDateFilterLabelFromOptionId = (
  startDateStr: string,
  endDateStr: string,
  optionId: string
) => {
  const idx = prefilledDateRangeOptions.findIndex((option) => option.id === optionId);
  if (idx === -1 || prefilledDateRangeOptions[idx].id === 'custom') {
    const startDate = new Date(startDateStr),
      endDate = new Date(endDateStr);
    return getCustomDateFilterLabel(startDate, endDate);
  }
  return prefilledDateRangeOptions[idx].label;
};

const getCustomDateFilterLabel = (startDate: Date, endDate: Date) => {
  return `${format(startDate || 0, 'MMM dd')} -  ${format(endDate || 0, 'MMM dd')}`;
};

const getDateFilterLabelFromDayDifference = (startDateStr: string, endDateStr: string) => {
  const startDate = new Date(startDateStr),
    endDate = new Date(endDateStr),
    dateDiff = differenceInCalendarDays(endDate, startDate);
  if (dateDiff < 0) return 'Infinity';
  switch (dateDiff) {
    case 0:
      return 'Today';
    case 7:
      return 'Last 7 Days';
    case 14:
      return 'Last 14 Days';
    case 28:
      return 'Last 28 Days';
    default:
      return getCustomDateFilterLabel(startDate, endDate);
  }
};
