import React, { useEffect, useState } from 'react';
import { BackButton } from '../../components/AtomComponents';
import useDataService from '../../hooks/useDataService';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { useHistory, useLocation } from 'react-router-dom';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import { fetchDataWithoutAuthorization, isEmpty } from '../../_lib/utils';
import ClientFormStatus from '../../components/ClientForms/ClientFormStatus';
import { IClientFormDetails } from '../../types/IClientForm';
import Progress from '../../components/Progress/Progress';
import { dataFormatting } from '../../_lib/lib';
import FormFields from '../../components/ClientForms/FormFields';
import './ClientForm.scss';
import SuccessfulRegistartion from '../../components/ClientForms/SuccessfulRegistartion';

interface IUpdateClientForm {
  isNewClient?: boolean;
}

const UpdateClientForm = ({ isNewClient }: IUpdateClientForm) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - New Client Account Details';
    if (!isNewClient) fetchClientForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { fetchUrl, openSnackBar } = useDataService();

  const [isLoading, setLoading] = useState(false);
  const [clientForm, setClientForm] = useState<IClientFormDetails>({} as IClientFormDetails);

  const pathnames = location.pathname.split('/');
  const submittedId = pathnames[pathnames.length - 1].toString();

  const [registered, setRegisterd] = useState(false);

  const fetchClientForm = async () => {
    try {
      setLoading(true);
      const formInfo = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.getProsPectivetenant, {
          submittedId,
        })
      );
      setClientForm(formInfo);
    } catch (e: any) {
      openSnackBar(e.message || `Unable to fetch`, eMessageType.error);
      history.goBack();
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = async (updatedClientForm: IClientFormDetails) => {
    const { createDate, updatedDate, tenant_id, ...rest } = updatedClientForm;
    const body = objectToFormData({ tenantId: updatedClientForm.tenant_id, ...rest });
    if (!isNewClient) {
      updateClient(body, updatedClientForm);
    } else {
      createNewClient(body);
    }
  };

  const createNewClient = async (body: IClientFormDetails) => {
    try {
      setLoading(true);
      const response: any = await fetchDataWithoutAuthorization(
        'POST',
        apiToUrlMap.createNewProspectivetenant,
        body
      );
      if (!response.error) {
        setRegisterd(true);
        openSnackBar('Registered SuccessFully', eMessageType.success);
      } else {
        openSnackBar(response.message || `Something went wrong`, eMessageType.error);
      }
    } catch (e: any) {
      openSnackBar(e.message || `Something went wrong`, eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const updateClient = async (body: IClientFormDetails, updatedClientForm: IClientFormDetails) => {
    try {
      setLoading(true);
      await fetchUrl(
        'PUT',
        formatString(apiToUrlMap.getProsPectivetenant, {
          submittedId,
        }),
        {
          body,
        }
      );
      setClientForm(updatedClientForm);
      openSnackBar('Updated SuccessFully', eMessageType.success);
    } catch (e: any) {
      openSnackBar(e.message || `Something went wrong`, eMessageType.error);
    } finally {
      setLoading(false);
    }
  };

  const objectToFormData = (obj: any) => {
    const formData: any = new FormData();
    Object.keys(obj).forEach((key: string) => {
      // if (!!obj[key] && obj[key].length)
      formData.append(key, obj[key] ? obj[key] : '');
    });
    return formData;
  };
  if (isEmpty(clientForm) && !isNewClient) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }
  return (
    <div className={`px-update-clientForms ${isNewClient && 'margin-top-2 margin-left-3'}`}>
      <LoadingDialog isDialogOpen={isLoading} />
      <div className="px-one-line-data">
        {!isNewClient && <BackButton />}
        <h3>New Client Account Details</h3>
      </div>
      {!registered ? (
        <div className={isNewClient ? 'margin-0' : 'margin-left-1'}>
          {!isNewClient && (
            <>
              <ClientFormStatus label={clientForm.status} submittedId={submittedId} />
              <div className="px-fontsize-16px margin-top-1">
                <p>
                  <span className="faded_1 margin-right-1">Created Date:</span>
                  {dataFormatting('dateInESTWithTime', clientForm.createDate)}
                </p>
                <p>
                  <span className="faded_1 margin-right-1">Updated Date:</span>
                  {dataFormatting('dateInESTWithTime', clientForm.updatedDate)}
                </p>
              </div>
            </>
          )}

          <div className="px-fontsize-16px margin-top-2">
            <p>Make sure all info is correct and up to date.</p>
            <p>
              Click 'Submit Form’ when done. If you need help, reach us at
              ClientSuccess@phonexinc.com
            </p>
          </div>
          <FormFields clientForm={clientForm} onSubmit={onFormSubmit} isNewClient={isNewClient} />
        </div>
      ) : (
        <SuccessfulRegistartion />
      )}
    </div>
  );
};

export default UpdateClientForm;
