import React, { useEffect, useRef } from 'react';
import { checkNullOrUndefined } from '../../_lib/lib';

interface IUnsanitizedShadowDom {
  htmlString: string;
}

const UnSanitizedShadowDom = ({ htmlString }: IUnsanitizedShadowDom) => {
  const shadowRoot = useRef<any>(null);
  useEffect(() => {
    if (checkNullOrUndefined(htmlString)) return;
    const el = document.createElement('div');
    el.attachShadow({ mode: 'open' });
    if (el.shadowRoot) el.shadowRoot.innerHTML = htmlString;
    if (shadowRoot.current) shadowRoot.current.replaceChildren(el);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlString]);

  return <div style={{ whiteSpace: 'initial' }} ref={shadowRoot} />;
};

export default UnSanitizedShadowDom;
