import React from 'react';

import { useState, useEffect } from 'react';

import { Tooltip } from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';

import { isEmpty } from '../../_lib/utils';
import { IObject } from '../../types/IObject';

import { configurationLayout } from '../../views/UpdateConfig/UpdateConfig';

const ChangeDetector = ({ label, updatedValues, updatedImages, getUpdatedValues }: any) => {
  const [success, setSuccess] = useState<any>({});
  const [error, setError] = useState<any>({});

  useEffect(() => {
    const layout = configurationLayout.find((layout: any) => layout.label === label);
    setError({});
    setSuccess({});
    if (layout?.type !== 'img') {
      const changedValue = getUpdatedValues();
      checkForChanges(changedValue, layout);
    } else {
      checkForChangesInImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedValues, updatedImages, label]);

  const checkForChanges = (changedValue: IObject<string>, layout: any) => {
    if (!isEmpty(changedValue)) {
      const updatedKeys = Object.keys(changedValue).filter((key) => layout.check(key));
      let updatedValuesForthisLayout: IObject<string> = {};
      updatedKeys.forEach((key) => {
        updatedValuesForthisLayout = { ...updatedValuesForthisLayout, [key]: updatedValues[key] };
      });
      checkForErrorAndSuccess(updatedValuesForthisLayout);
    }
  };

  const checkForErrorAndSuccess = (updatedValuesForthisLayout: IObject<string>) => {
    const successfullChanges = Object.keys(updatedValuesForthisLayout).filter((value: any) => {
      return !!updatedValuesForthisLayout[value];
    });
    const errorChanges = Object.keys(updatedValuesForthisLayout).filter(
      (value: any) => !!!updatedValuesForthisLayout[value]
    );
    if (!isEmpty(errorChanges))
      setError({
        count: errorChanges.length,
        title: errorChanges.join(', '),
      });

    if (!isEmpty(successfullChanges))
      setSuccess({
        count: successfullChanges.length,
        title: successfullChanges.join(', '),
      });
  };

  const checkForChangesInImage = () => {
    const changesInImages = Object.keys(updatedImages);
    if (changesInImages.length) {
      setSuccess({
        count: changesInImages.length,
        title: changesInImages.join(', '),
      });
    } else {
      setSuccess({});
    }
  };

  return (
    <div>
      {!isEmpty(success) ? (
        <Tooltip title={`${success.count} changes in ${success.title}`}>
          <CheckCircle className="success margin-right-1" />
        </Tooltip>
      ) : (
        <></>
      )}
      {!isEmpty(error) ? (
        <Tooltip title={`${error.count} value missing in ${error.title}`}>
          <Error className="error" />
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangeDetector;
