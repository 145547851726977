import { ArrowBackIos } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TertiaryButton } from './index';

interface IBackButton {
  url?: string;
  goBack?: () => void;
}

function BackButton({ url, goBack }: IBackButton) {
  const history = useHistory();
  const goBackFn = () => {
    if (goBack) {
      return goBack();
    }
    if (!url) {
      return history.goBack();
    }
    return history.push(url);
  };
  return (
    <TertiaryButton
      data-id="goBack"
      onClick={goBackFn}
      className="margin-0 padding-0 px-color-text-primary">
      <ArrowBackIos />
    </TertiaryButton>
  );
}

export default BackButton;
