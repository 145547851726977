import React from "react";
import { messageSet } from "../../redux/actions/messageActions";
import { SchemaUpload } from "../ConfigSchema/SchemaUpload";
import { fetchData } from "../../_lib/lib";
import { BackButton } from "../../components/AtomComponents";
interface IProps {
  syncFor: string;
}

const ManualUpload = ({ syncFor }: IProps) => {
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12 margin-bottom-1">Upload Queries</h3>
      </div>
      <SchemaUpload syncFor={syncFor} messagesSet={messageSet} />
    </>
  );
};

export default ManualUpload;

export const manualSchemaUpload = async (fileState: any, syncFor: string) => {
  return await fetchData(
    "post",
    `schema-sync${syncFor !== "master" ? "-slave" : ""}/manual-upload`,
    {
      body: fileState,
    },
    true
  );
};
