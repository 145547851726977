import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/AtomComponents";
import Button from "@material-ui/core/Button";
import { commonService } from "../../../_lib/lib";
import useDataService from "../../../hooks/useDataService";
import { eMessageType } from "../../../types/IMessageType";
import { Progress } from "../../NewTenant/Progress/Progress";
import { Resources } from "./Resources";

export function Continue(props: any) {
  const [resources, setResource] = useState<any>({});
  const { openSnackBar } = useDataService();

  const updateDecommissionResource = (
    e: any,
    value: string,
    isobject?: boolean
  ) => {
    if (!isobject) {
      props.setTenantDataState({
        ...props.tenantDataState,
        [e.target.name]: e.target.checked ? value : "",
      });
    } else {
      if (e.target.checked) {
        props.setTenantDataState({
          ...props.tenantDataState,
          [e.target.name]: [...props.tenantDataState[e.target.name], value],
        });
      } else {
        props.setTenantDataState({
          ...props.tenantDataState,
          [e.target.name]: [
            ...props.tenantDataState[e.target.name].filter(
              (data: string) => data !== value
            ),
          ],
        });
      }
    }
  };
  useEffect(() => {
    getResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(props.tenantDataState);
  // }, [props]);

  const getResource = async () => {
    const resource = await commonService(
      "post",
      `/workflow/status`,
      {
        body: { tenant_id: props.tenantDataState?.tenant_id },
        responseType: "text",
      }
    );
    console.log(resource)
    if (resource.output) {
      const resourceObj = JSON.parse(resource.output);
      if (typeof resourceObj.resources === "object")
        setResource(resourceObj.resources);
      else {
        props.setStatusState({ status: "FAILED", loading: false });
        openSnackBar(
          "Unable to find resource, Try again after some time",
          eMessageType.error
        );
  
        props.setProcessState(1);
      }
    } else {
      props.setTenantDataState(props.initObj);
      props.setStatusState({ status: "FAILED", loading: false });
      openSnackBar(
        "Something went wrong. Try again after sometime",
        eMessageType.error
      );

      props.setProcessState(1);
    }
  };

  return (
    <>
      {!props.statusState?.loading && props.tenantDataState && (
        <>
          <div className="small-12">
            <h4>Please select the resources to decommission</h4>
          </div>
          <Resources
            resources={resources}
            tenantDataState={props.tenantDataState}
            updateDecommissionResource={updateDecommissionResource}
          />
          <div className="small-12 margin-top-1">
            <PrimaryButton
              className="margin-top-2"
              onClick={props.continueProcess}
              disabled={props.statusState && props.statusState.loading}
            >
              Continue...
            </PrimaryButton>
          </div>

          {props.processState !== 1 && (
            <div className="small-12">
              <Button
                variant="contained"
                className="margin-top-3"
                onClick={props.restartProcess}
              >
                Go to the beginning
              </Button>
              <Button
                variant="contained"
                className="margin-top-3 margin-left-1"
                onClick={props.goBack}
              >
                Go back
              </Button>
            </div>
          )}
        </>
      )}

      {props.statusState && props.statusState.loading && (
        <div className="small-12 margin-top-3 border-top">
          <p>
            The page will redirect automativally to the next step when the
            server completes the work.
          </p>
          <p>This might take up to 2 minutes.</p>
          <Progress duration={120000} />
        </div>
      )}
    </>
  );
}

export default Continue;
