import React from "react";
import { BackButton } from "../../components/AtomComponents";
import { releaseAutomation } from "./ConfigDeployment";
import { ConfigField } from "./ConfigField";

function TestToVersioned() {
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12 margin-bottom-1">Create Versioned Release branch</h3>
      </div>
      <ConfigField
        releaseAutomation={releaseAutomation}
        endpoint="test-to-versioned"
        type="test"
      />
    </>
  );
}

export default TestToVersioned;
