import React, { useEffect, useRef, useState } from 'react';
import { FormFieldsConfig } from './FormFieldsConfig';
import { PrimaryButton, TextField } from '../AtomComponents';
import { CheckSharp, Folder } from '@material-ui/icons';
import { IClientFormDetails, intialClientFormDetails } from '../../types/IClientForm';
import { allowPhoneNumber, isEmail, isEmpty, scrollIntoView } from '../../_lib/utils';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { InputAdornment } from '@material-ui/core';
import { ImportIcon } from '../../_lib/icons';
import { Prompt } from 'react-router-dom';
import { trimValue } from '../../_lib/lib';

interface IFormFields {
  clientForm: IClientFormDetails;
  onSubmit: (updatedClientForm: IClientFormDetails) => void;
  isNewClient?: boolean;
}
const optionalFields = [
  'signerPhone',
  'payablePhone',
  'billingAddress2',
  'billingState',
  'dba',
  'businessShortName',
  'logo',
  'favicon',
  'existingBusinessWebsite',
];

const FormFields = ({ clientForm, onSubmit, isNewClient }: IFormFields) => {
  const [currentClientForm, setCurrentClientForm] = useState<any>({} as IClientFormDetails);
  const [updatedClientForm, setUpdatedClientForm] = useState<any>({} as IClientFormDetails);

  const [error, setError] = useState<any>({});

  const { openSnackBar } = useDataService();

  useEffect(() => {
    setCurrentClientForm(isEmpty(clientForm) ? intialClientFormDetails : clientForm);
    setUpdatedClientForm(isEmpty(clientForm) ? intialClientFormDetails : clientForm);
  }, [clientForm]);

  useEffect(() => {
    const fieldWithError = Object.keys(error).filter((key: string) => !!error[key]);
    if (fieldWithError.length) {
      scrollIntoView(fieldWithError[0]);
    }
    let clearFilter: any;
    if (fieldWithError.length > 5) {
      clearFilter = setTimeout(() => {
        setError({});
      }, 3000);
    }
    return () => {
      if (clearFilter) clearTimeout(clearFilter);
    };
  }, [error]);

  const updateClientField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedClientForm({
      ...updatedClientForm,
      [event.target.name]: event.target.value.length ? event.target.value : null,
    });

    validateField(event.target.name, event.target.value);
  };

  const submitForm = () => {
    const {
      createDate,
      updatedDate,
      tenant_id,
      submissionId,
      signerPhone,
      payablePhone,
      billingAddress2,
      billingState,
      dba,
      businessShortName,
      logo,
      favicon,
      existingBusinessWebsite,
      ...rest
    } = updatedClientForm;
    const emptyFields = Object.keys(rest).filter((key: string) => !rest[key]);

    if (!emptyFields.length) {
      Object.keys(rest).forEach((key: string) => {
        validateField(key, rest[key]);
      });
      if (hasNoError()) onSubmit(trimValue(updatedClientForm));
      else {
        openSnackBar(`Please check all fields are filled with proper value`, eMessageType.error);
      }
    } else {
      const newError: any = {};
      emptyFields.forEach((key: string) => {
        newError[key] = true;
      });
      setError({ ...error, ...newError });
      openSnackBar(`Fill the required fields`, eMessageType.error);
    }
  };

  const getErrorMessage = (error: any, field: string) => {
    if (error[field]?.message) {
      return error[field].message;
    }
    return 'Required field';
  };

  const handleFileUpload = (name: string, file: any) => {
    setUpdatedClientForm({
      ...updatedClientForm,
      [name]: file ? file : null,
    });
  };

  const validateField = (fieldName: string, value: any) => {
    if (!optionalFields.includes(fieldName)) {
      setError({ ...error, [fieldName]: value ? !!!value.toString().trim() : true });
    }
    if (fieldName.includes('Email')) {
      if (!isEmail(value.trim())) {
        setError({ ...error, [fieldName]: { message: 'Invalid Email' } });
      }
    }
  };

  const checkHasChanges = (updatedClientForm: IClientFormDetails) => {
    if (JSON.stringify(currentClientForm) !== JSON.stringify(trimValue(updatedClientForm))) {
      return true;
    } else return false;
  };

  const hasNoError = () => {
    return Object.keys(error).every((key) => error[key] === false);
  };

  return (
    <div className="form-fields">
      <Prompt
        when={checkHasChanges(updatedClientForm)}
        message={'You have some unsaved changes. OK to forget these changes and leave the page?'}
      />
      {Object.keys(FormFieldsConfig).map((key: string) => (
        <React.Fragment key={key}>
          <h4 className="bold">{key}</h4>
          <div className="margin-top-1 grid-y width-40 cell">
            {FormFieldsConfig[key].map(
              (fields: {
                label: string;
                name: string;
                required: boolean;
                maxLength: number;
                instruction: string;
              }) => (
                <div className="grid-x margin-right-1 margin-bottom-2 " key={fields.name}>
                  <FormField
                    fields={fields}
                    updateClientField={updateClientField}
                    updatedClientForm={updatedClientForm}
                    error={error}
                    getErrorMessage={getErrorMessage}
                    handleFileUpload={handleFileUpload}
                  />
                  {fields.instruction && <div className="instruction">{fields.instruction}</div>}
                </div>
              )
            )}
          </div>
        </React.Fragment>
      ))}
      <PrimaryButton
        className="margin-top-2"
        onClick={submitForm}
        disabled={!checkHasChanges(updatedClientForm) || !hasNoError()}>
        {!isNewClient ? (
          <>
            <CheckSharp className="margin-right-1" />
            Update Form
          </>
        ) : (
          <>
            <ImportIcon />
            Submit Form
          </>
        )}
      </PrimaryButton>
    </div>
  );
};

export default FormFields;

const FormField = ({
  fields,
  updateClientField,
  updatedClientForm,
  error,
  getErrorMessage,
  handleFileUpload,
}: any) => {
  const ref = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState();

  const handleOnclick = (event: any) => {
    if (event.keyCode !== 9 && fields.type === 'image' && ref.current) {
      event.preventDefault();
      ref.current?.click();
    }
    if (fields.type === 'phone') {
      allowPhoneNumber(event);
    }
  };

  const handleFileChanges = (e: any) => {
    setFileName(e.target.files[0]?.name);
    handleFileUpload(e.target.name, e.target.files[0]);
  };

  return (
    <>
      <TextField
        id={fields.name}
        label={fields.label}
        size="medium"
        value={fileName ? fileName : updatedClientForm[fields.name] || ''}
        onChange={updateClientField}
        className={`fields ${fields.type === 'image' && 'file-field'}`}
        name={fields.name}
        key={fields.name}
        error={error[fields.name] && fields.required}
        helperText={error[fields.name] && fields.required && getErrorMessage(error, fields.name)}
        inputProps={{ maxLength: fields.maxLength, readOnly: fields.type === 'image' }}
        onClick={handleOnclick}
        onKeyPress={handleOnclick}
        InputProps={{
          endAdornment: fields.type === 'image' && (
            <InputAdornment position="end">
              <Folder />
            </InputAdornment>
          ),
        }}
      />
      {fields.type === 'image' && (
        <input
          id={fields.name}
          style={{ display: 'none' }}
          name={fields.name}
          type="file"
          ref={ref}
          accept="image/*"
          onChange={handleFileChanges}
        />
      )}
    </>
  );
};
