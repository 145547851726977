import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { messageSet } from '../../redux/actions/messageActions';
import { Box, LinearProgress, Tab } from "@material-ui/core";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { PrimaryButton, TextField } from "../../components/AtomComponents";
import FileWell from "../../components/AtomComponents/FileWell";
import JSONInput from "react-json-editor-ajrm";

import {downloadTemplateXlsx, downloadXlsxTenant, tenantConfigPreview, tenantConfigSubmit} from '../../_lib/lib';

import "./ConfigTenant.scss";

export function ConfigTenant(props: any) {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Config Tenant";
  }, []);

  const [tabState, setTabState] = React.useState("1");
  const [tenantIdState, setTenantIdState] = React.useState("");
  const [fileState, setFileState] = React.useState<any>(null);
  const [processState, setProcessState] = React.useState<number>(0);
  const [previewState, setPreviewState] = React.useState<any>(null);

  const handleChangeTab = (event: any, newValue: any) => {
    setTabState(newValue);
  };

  const downloadTemplate = () => {
    downloadTemplateXlsx()
      .then((url: any) => {
        console.log('downloadTemplate == ', url);
        if (url && url.error) {
          props.messagesSet(true, url.error);
          restart();
        } else {
          if (url) { window.open(url); }
        }
      });
  };

  const download = () => {
    downloadXlsxTenant(tenantIdState)
      .then((url: any) => {
        console.log('downloadTemplate == ', url);
        if (url && url.error) {
          props.messagesSet(true, url.error);
          restart();
        } else {
          if (url) { window.open(url); }
        }
      });
  };

  const setFile = (file: any) => {
    setFileState(file[0]);
  };

  const restart = () => {
    setProcessState(0);
    setPreviewState(null);
    setFileState(null);
  };

  const accept = () => {
    if (!tenantIdState || !tenantIdState.length) {
      alert('Please enter a valid Tenant ID first.');
    } else
    if (fileState && processState === 2) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure that you want to save this changes on Live Database?")) {
        setProcessState(3);
        tenantConfigSubmit(tenantIdState, fileState)
          .then((data: any) => {
            if (data) {
              setPreviewState(Object.assign({}, data));
              setTimeout(() => {
                setProcessState(4);
              }, 1);
            } else {
              props.messagesSet(true, 'Error on server!');
              restart();
            }
          });
      }
    } else {
      alert("Something went wrong!");
    }
  };

  const upload = () => {
    console.log('fileState', fileState);
    if (!tenantIdState || !tenantIdState.length) {
      alert('Please enter a valid Tenant ID first.');
    } else
    if (!fileState) {
      alert("Please choose a file");
    } else if (processState !== 0) {
      alert("Upload already in progress. Please wait!");
    } else if (fileState && processState === 0) {
      setProcessState(1);
      tenantConfigPreview(tenantIdState, fileState)
        .then((data: any) => {
          if (data) {
            setPreviewState(Object.assign({}, data));
            setTimeout(() => {
              setProcessState(2);
            }, 1);
          } else {
            props.messagesSet(true, 'Error on server!');
            restart();
          }
        });
    }
  };

  const Wait = () => {
    return (
      <div className="grid-x px-config-wait">
        <div className="small-12">
          <h5>Processing. Please wait...</h5>
          <h6>This process can take 30 seconds or more.</h6>
        </div>
        <div className="small-12">
          <LinearProgress />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="px-ConfigTenant grid-x">
        <h3 className="small-12">Tenant Configuration</h3>
        <small>
          <span className="link" onClick={downloadTemplate}>Download XLSX template here</span>
        </small>
        <div className="small-12 margin-top-3">
          <Box>
            <TabContext value={tabState}>
              <Box>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="Tenant config XLSX"
                >
                  <Tab label="Download" value="1" />
                  <Tab label="Upload" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TextField
                  label="Tenand ID"
                  size="small"
                  value={tenantIdState}
                  onChange={(e) => {
                    setTenantIdState(e.target.value);
                  }}
                  placeholder="Tenant ID"
                  className="margin-right-1"
                />
                <PrimaryButton
                  disabled={!tenantIdState.length}
                  onClick={download}
                >
                  Download XLSX
                </PrimaryButton>
              </TabPanel>
              <TabPanel value="2">
                {processState === 0 && (
                  <div>
                    <TextField
                      label="Tenand ID"
                      size="small"
                      value={tenantIdState}
                      onChange={(e) => {
                        setTenantIdState(e.target.value);
                      }}
                      placeholder="Tenant ID"
                      className="margin-right-1 margin-bottom-2"
                    />
                    <FileWell
                      filesLimit={1}
                      acceptedFiles={[
                        ".xlsx",
                        ".xls",
                        "application/vnd.ms-excel",
                        "application/msexcel",
                        "application/x-msexcel",
                        "application/x-ms-excel",
                        "application/x-excel",
                        "application/x-dos_ms_excel",
                        "application/xls",
                        "application/x-xls",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/excel",
                      ]}
                      handleDrop={setFile}
                      className="margin-bottom-3"
                    />
                    <PrimaryButton onClick={upload} disabled={!fileState || !tenantIdState}>
                      Upload XLSX
                    </PrimaryButton>
                  </div>
                )}

                {processState === 1 && <Wait />}

                {processState === 2 && (
                  <div>
                    <Alert severity="info" className="margin-bottom-3">
                      Please preview the data for tenant{" "}
                      {previewState.account.tenantId}:
                    </Alert>
                    <div className="margin-bottom-3">
                      <JSONInput
                        id="jsonEditor"
                        theme="light_mitsuketa_tribute"
                        locale={localeEn}
                        placeholder={previewState}
                        height="550px"
                        width="100%"
                        viewOnly={true}
                        style={{ body: { fontSize: "16px" } }}
                      />
                    </div>
                    <PrimaryButton
                      color="secondary"
                      onClick={restart}
                      style={{ marginRight: "40px" }}
                    >
                      Restart Upload Process
                    </PrimaryButton>
                    <PrimaryButton onClick={accept}>
                      Accept and Confirm Changes
                    </PrimaryButton>
                  </div>
                )}

                {processState === 3 && <Wait />}

                {processState === 4 && (
                  <div>
                    <Alert severity="success" className="margin-bottom-3">
                      Data for tenant {previewState.account.tenantId} updated
                      successfully!
                    </Alert>
                    <PrimaryButton onClick={restart}>Restart</PrimaryButton>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    messagesSet: bindActionCreators(messageSet, dispatch),
  };
}

function mapStateToProps(state: any) {
  return {
    messages: state.messageState
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigTenant);

export const localeEn = {
  format: "{reason} at line {line}",
  symbols: {
    colon: "colon", //
    comma: "comma", // ,  ،  、
    semicolon: "semicolon", // ;
    slash: "slash", // /  relevant for comment syntax support
    backslash: "backslash", // \  relevant for escaping character
    brackets: {
      round: "round brackets", // ( )
      square: "square brackets", // [ ]
      curly: "curly brackets", // { }
      angle: "angle brackets", // < >
    },
    period: "period", // . Also known as full point, full stop, or dot
    quotes: {
      single: "single quote", // '
      double: "double quote", // "
      grave: "grave accent", // ` used on Javascript ES6 Syntax for String Templates
    },
    space: "space", //
    ampersand: "ampersand", // &
    asterisk: "asterisk", // *  relevant for some comment sytanx
    at: "at sign", // @  multiple uses in other coding languages including certain data types
    equals: "equals sign", // =
    hash: "hash", // #
    percent: "percent", // %
    plus: "plus", // +
    minus: "minus", // −
    dash: "dash", // −
    hyphen: "hyphen", // −
    tilde: "tilde", // ~
    underscore: "underscore", // _
    bar: "vertical bar", // |
  },
  types: {
    // ... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
    key: "key",
    value: "value",
    number: "number",
    string: "string",
    primitive: "primitive",
    boolean: "boolean",
    character: "character",
    integer: "integer",
    array: "array",
    float: "float",
  },
  invalidToken: {
    tokenSequence: {
      prohibited:
        "'{firstToken}' token cannot be followed by '{secondToken}' token(s)",
      permitted:
        "'{firstToken}' token can only be followed by '{secondToken}' token(s)",
    },
    termSequence: {
      prohibited: "A {firstTerm} cannot be followed by a {secondTerm}",
      permitted: "A {firstTerm} can only be followed by a {secondTerm}",
    },
    double: "'{token}' token cannot be followed by another '{token}' token",
    useInstead: "'{badToken}' token is not accepted. Use '{goodToken}' instead",
    unexpected: "Unexpected '{token}' token found",
  },
  brace: {
    curly: {
      missingOpen: "Missing '{' open curly brace",
      missingClose: "Open '{' curly brace is missing closing '}' curly brace",
      cannotWrap: "'{token}' token cannot be wrapped in '{}' curly braces",
    },
    square: {
      missingOpen: "Missing '[' open square brace",
      missingClose: "Open '[' square brace is missing closing ']' square brace",
      cannotWrap: "'{token}' token cannot be wrapped in '[]' square braces",
    },
  },
  string: {
    missingOpen: "Missing/invalid opening string '{quote}' token",
    missingClose: "Missing/invalid closing string '{quote}' token",
    mustBeWrappedByQuotes: "Strings must be wrapped by quotes",
    nonAlphanumeric:
      "Non-alphanumeric token '{token}' is not allowed outside string notation",
    unexpectedKey: "Unexpected key found at string position",
  },
  key: {
    numberAndLetterMissingQuotes:
      "Key beginning with number and containing letters must be wrapped by quotes",
    spaceMissingQuotes: "Key containing space must be wrapped by quotes",
    unexpectedString: "Unexpected string found at key position",
  },
  noTrailingOrLeadingComma:
    "Trailing or leading commas in arrays and objects are not permitted",
};
