import React from "react";

import { fetchData } from "../../_lib/lib";

import { BackButton } from "../../components/AtomComponents";
import ConfigBox from "../../components/Dashboard/ConfigBox";

import "../ConfigTenant/ConfigTenant.scss";

export const releaseAutomation = async (
  versionCode: string,
  PRDescription: string,
  endpoint: string,
  userConfig?: Record<string, string>
) => {
  let dataToserver: any = {};
  if(versionCode) {
    dataToserver = { VersionCode: versionCode };
  }
  if (PRDescription.length) {
    dataToserver.PRDescription = PRDescription;
  }
  if(userConfig) {
    dataToserver = {...dataToserver, ...userConfig};
  }
  return await fetchData("post", `code-deployment/${endpoint}`, {
    body: dataToserver,
  });
};

export function ConfigDeployment() {
  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem("PxConfig") || "");
    document.title = String(config.siteTitle) + " - Deployment Tool";
  }, []);

  const UiBoxes = [
    {
      header: "Create Versioned Release branch ",
      redirect: "config-deployment/test-to-version",
    },
    {
      header: "Deploy to Release Environment",
      redirect: "config-deployment/version-to-release",
    },
    {
      header: "Deploy to Production Environment",
      redirect: "config-deployment/release-to-prod",
    },
  ];

  return (
    <>
      <div className="px-ConfigTenant grid-x">
        <div className="cell small-6 medium-6 px-one-line-data">
          <BackButton />
          <h3 className="small-12">Release Deployment Tool</h3>
        </div>
        <div className="small-12 margin-top-3">
          {UiBoxes.map((Uibox) => {
            return (
              <ConfigBox
                header={Uibox.header}
                redirect={Uibox.redirect}
                key={Uibox.header}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}



export default ConfigDeployment;
