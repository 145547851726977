import React, { useState } from 'react';
// Icons
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import {
  AddCircle as AddCircleIcon,
  MenuOpen as MenuOpenIcon,
  Menu as MenuIcon,
  Reorder as ReorderIcon,
  SettingsApplications,
  // Help as HelpIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './SideMenu.scss';
import PxMenu from './PxMenu';

function SideMenu(props: any) {
  const [sideMenuState, setSideMenuState] = useState(false);
  const [sideMenuSmallDeviceState, setSideMenuSmallDeviceState] = useState(false);
  const sassMenu = (
    <>
      <ul className="px-menu-mobile show-for-sm-only border-top">
        <li>
          <span className="link" data-id="toggleMobileMenu">
            <ReorderIcon
              onClick={() => {
                setSideMenuSmallDeviceState(!sideMenuSmallDeviceState);
              }}
            />
          </span>
        </li>
      </ul>
      <div className="px-sideMenu-sticky-container">
        <nav
          className={`px-c-SideMenu
          ${props.autoCloseState > 0 ? 'px-menu-autoclose' : 'px-menu-autoclose-off'}
          ${sideMenuState ? 'show-menu' : ''}
          ${sideMenuSmallDeviceState ? 'show-menu-small-device' : ''}
        `}
          onMouseEnter={() => {
            setSideMenuState(true);
          }}
          onMouseLeave={() => {
            setSideMenuState(false);
          }}>
          <>
            <MenuList className="padding-0">
              <MenuItem
                className="px-expand-side-menu padding-0 cell grid-x align-center text-center"
                disableRipple>
                <IconButton
                  aria-label="expand/collapse"
                  size="small"
                  className="p-t-px-8 cell"
                  disableRipple>
                  <MenuIcon className="px-expand-menu-icon" />
                </IconButton>
              </MenuItem>
              {props.userRole && props.userRole['PLATFORM_ADMIN'] && (
                <div>
                  <MenuItem
                    className="px-menu-desk padding-0 border-bottom px-sideMenu-heading"
                    disableRipple>
                    <NavLink
                      to="/"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => {
                        props.setAutoCloseState((old: any) => old + 1);
                        setSideMenuState(false);
                        setSideMenuSmallDeviceState(false);
                      }}>
                      <MenuOpenIcon className="px-sideMenu-item-icon" />
                      <h5 className="margin-bottom-0">Admin Menu</h5>
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="px-menu-desk padding-0 border-bottom px-sideMenu-heading"
                    disableRipple>
                    <NavLink
                      to="/new-tenant"
                      activeClassName="px-active-navlink"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => {
                        props.setAutoCloseState((old: any) => old + 1);
                        setSideMenuState(false);
                        setSideMenuSmallDeviceState(false);
                      }}>
                      <AddCircleIcon className="px-sideMenu-item-icon" />
                      <h5 className="margin-bottom-0">Onboard New Tenant</h5>
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="px-menu-desk padding-0 border-bottom px-sideMenu-heading"
                    disableRipple>
                    <NavLink
                      activeClassName="px-active-navlink"
                      to="/config-tenant"
                      className="cell grid-x align-left align-middle px-sideMenu-item"
                      onClick={(e: any) => {
                        props.setAutoCloseState((old: any) => old + 1);
                        setSideMenuState(false);
                        setSideMenuSmallDeviceState(false);
                      }}>
                      <SettingsApplications className="px-sideMenu-item-icon" />
                      <h5 className="margin-bottom-0">Tenant Config</h5>
                    </NavLink>
                  </MenuItem>
                </div>
              )}
              {props.userRole && props.userRole['FINANCE'] && (
                <div>
                  <PxMenu
                    to="/clients"
                    setAutoCloseState={props.setAutoCloseState}
                    setSideMenuState={setSideMenuState}
                    setSideMenuSmallDeviceState={setSideMenuSmallDeviceState}
                    placeholder="Clients"
                    icon={<MenuOpenIcon className="px-sideMenu-item-icon" />}
                  />
                  <PxMenu
                    to="/invoices"
                    setAutoCloseState={props.setAutoCloseState}
                    setSideMenuState={setSideMenuState}
                    setSideMenuSmallDeviceState={setSideMenuSmallDeviceState}
                    placeholder="Invoices"
                    icon={<MenuOpenIcon className="px-sideMenu-item-icon" />}
                  />
                  <PxMenu
                    to="/reports"
                    setAutoCloseState={props.setAutoCloseState}
                    setSideMenuState={setSideMenuState}
                    setSideMenuSmallDeviceState={setSideMenuSmallDeviceState}
                    placeholder="Reports"
                    icon={<MenuOpenIcon className="px-sideMenu-item-icon" />}
                  />
                </div>
              )}
              {/* CLIENT_SUCCESS */}
              {props.userRole && props.userRole['CLIENT_SUCCESS'] && (
                <PxMenu
                  to="/client-form"
                  setAutoCloseState={props.setAutoCloseState}
                  setSideMenuState={setSideMenuState}
                  setSideMenuSmallDeviceState={setSideMenuSmallDeviceState}
                  placeholder="Client Forms"
                  icon={<MenuOpenIcon className="px-sideMenu-item-icon" />}
                />
              )}
            </MenuList>
          </>
        </nav>
      </div>
    </>
  );

  return <>{sassMenu}</>;
}

function mapStateToProps(state: any) {
  return {
    menuItemDisplay: state.sideMenuState,
    contractsState: state.contractsState,
    userRole: state.userState.role,
  };
}

export default connect(mapStateToProps)(SideMenu);
