export const CONFIG_SET = 'CONFIG_SET';
export const CONTRACTS_SET = 'CONTRACTS_SET';
export const USER_SET = 'USER_SET';
export const FILTER_SET = 'FILTER_SET';
export const STOCK_GROUP_SET = 'STOCK_GROUP_SET';
export const STOCK_CUSTOM_SET = 'STOCK_CUSTOM_SET';
// inventory actions
export const INVENTORY_SET = 'INVENTORY_SET';
export const INVENTORY_UPDATE = 'INVENTORY_UPDATE';
export const INVENTORY_SETTINGS_SET = 'INVENTORY_SETTINGS_SET';
export const URL_CHANGE = 'URL_CHANGE';
export const URL_PREVENT_WINDOW_CLOSE = 'URL_PREVENT_WINDOW_CLOSE';
export const URL_PREVENT_ROUTE_CHANGE = 'URL_PREVENT_ROUTE_CHANGE';
export const URL_PREVENT_LOGOUT = 'URL_PREVENT_LOGOUT';
export const FLAGS_SET = 'FLAGS_SET';
export const USER_STATE_SET = 'USER_STATE_SET';
export const USER_AUTHORITIES_SET = 'USER_AUTHORITIES_SET';
export const SIDE_MENU_ITEMS_SET = 'SIDE_MENU_ITEMS_SET';
//Role based action
export const USER_ROLE_SET = "USET_ROLE_SET"

// stock list
export const STOCK_SET = 'STOCK_SET';
export const STOCK_DUMMY_SET = 'STOCK_DUMMY_SET';
export const STOCKLIST_SETTINGS_SET = 'STOCKLIST_SETTINGS_SET';
export const STOCKLIST_OFFERS_SET = 'STOCKLIST_OFFERS_SET';

// preorder
export const AWARDS_SET = 'AWARDS_SET';
export const AWARDS_CLOSED_SET = 'AWARDS_CLOSED_SET';
export const IMPORT_AWARDS_SET = 'IMPORT_AWARDS_SET';

// customer actions
export const CUSTOMER_SET = 'CUSTOMER_SET';
export const CUSTOMER_ADD = 'CUSTOMER_ADD';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
// customer loading panel
export const CUSTOMER_LOADING_SET = 'CUSTOMER_LOADING_SET';
// customer status update
export const CUSTOMER_STATUS_UPDATE = 'CUSTOMER_STATUS_UPDATE';

// customer settings
export const CUSTOMER_SETTINGS = 'CUSTOMER_SETTINGS';

// customer sales rep
export const CUSTOMER_SALES_REP = 'CUSTOMER_SALES_REP';

// messages
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// pricing
export const PRICING_SET = 'PRICING_SET';
export const PRICING_UPDATE = 'PRICING_UPDATE';
export const PRICING_PUBLISH = 'PRICING_PUBLISH';
export const PRICING_SETTINGS_SET = 'PRICING_SETTINGS_SET';
export const PRICING_COUNT_UPDATE = 'PRICING_COUNT_UPDATE';
export const PRICING_LOADING_CHANGE = 'PRICING_LOADING_CHANGE';
export const PRICING_STATS_UPDATE = 'PRICING_STATS_UPDATE';

// offer clearing
export const OFFER_CLEARING_STATS_SET = 'OFFER_CLEARING_STATS_SET';
export const OFFER_CLEARING_STATS_UPDATE = 'OFFER_CLEARING_STATS_UPDATE';
export const OFFER_CLEARING_SET = 'OFFER_CLEARING_SET';
export const OFFER_CLEARING_UPDATE = 'OFFER_CLEARING_UPDATE';

//Clients
export const SET_CLIENT_FILTER = 'SET_CLIENT_FILTER'

