import React from 'react';
import { NavLink } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { IInvoiceDetails } from '../../types/IInvoice';

import { dataFormatting } from '../../_lib/lib';
import { toCapitalize } from '../../_lib/utils'

const InvoiceInfo = ({
  invoiceNumber,
  invoiceDate,
  dueDate,
  clientName,
  defaultPaymentMethod,
  billingPeriodStartDate,
  billingPeriodEndDate,
  billingPeriodOrdersInUsd,
}: IInvoiceDetails) => {
  return (
    <div className="invoice-info margin-top-2 margin-left-1">
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Invoice #:
        </Grid>
        <Grid item xs={6} data-id='invoiceNumber'>
          {invoiceNumber || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Invoice Date:
        </Grid>
        <Grid item xs={6} data-id='invoiceDate'>
          {dataFormatting('dateInEST', invoiceDate) || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Due Date:
        </Grid>
        <Grid item xs={6} data-id='dueDate'>
          {dataFormatting('dateInEST', dueDate) || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Client Name:
        </Grid>
        <Grid item xs={6} data-id='clientName'>
          {clientName || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Payment Method:
        </Grid>
        <Grid item xs={6} data-id='paymentMethod'>
          {toCapitalize(defaultPaymentMethod).replace('_',' ') || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Billing Period Start:
        </Grid>
        <Grid item xs={6} data-id='billingPeriodStartDate'>
          {dataFormatting('dateInEST', billingPeriodStartDate) || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Billing Period End:
        </Grid>
        <Grid item xs={6} data-id='billingPeriodEndDate'>
          {dataFormatting('dateInEST', billingPeriodEndDate) || '--'}
        </Grid>
      </Grid>
      <Grid container className="detail">
        <Grid item xs={4} className="faded_1">
          Billing Period Orders:
        </Grid>
        <Grid item xs={6} data-id='billingPeriodOrdersInUsd'>
          {billingPeriodOrdersInUsd ? (
            <NavLink to={`/billing-period-orders/${invoiceNumber}`}>
              {dataFormatting('currency', billingPeriodOrdersInUsd) || '--'}
            </NavLink>
          ) : (
            '--'
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceInfo;
