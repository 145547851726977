import { Grid, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { checkNullOrUndefined, dataFormatting } from '../../_lib/lib';
import { convertToTitleCase } from '../../_lib/utils';
import { ICellRendererParams } from 'ag-grid-community';
import { PrimaryButton } from '../AtomComponents';
// import { PrimaryButton } from '../AtomComponents';

type PaymentStatus = 'SUCCEEDED' | 'FAILURE' | 'REQUESTED';

const paymentStatusSetting: Record<
  PaymentStatus,
  { label: string; colorClass: string; toolTipTitle: string }
> = {
  SUCCEEDED: {
    label: 'Paid',
    colorClass: 'badge_2',
    toolTipTitle: 'Paid by',
  },
  FAILURE: {
    label: 'Failed',
    colorClass: 'badge_5',
    toolTipTitle: 'Failed',
  },
  REQUESTED: {
    label: 'Requested',
    colorClass: 'badge_1',
    toolTipTitle: 'Requested',
  },
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'RGB(250, 250, 250)',
    color: 'RGBA(0, 0, 0, 0.87)',
    width: 330,
    fontSize: '12px',
    borderRadius: 'none',
    // boxShadow: 'RGB(var(--px_color_border)) 0px 1px 5px 0px',
  },
}))(Tooltip);

interface IInvoicePaymentStatus {
  params: ICellRendererParams;
  retryCardPayment: (invoiceNumber: string[]) => void;
}

const InvoicePaymentStatus = ({ params, retryCardPayment }: IInvoicePaymentStatus) => {
  if (checkNullOrUndefined(params.value)) return <>--</>;
  const statusSetting = paymentStatusSetting[params.value as PaymentStatus];
  if (checkNullOrUndefined(statusSetting))
    return <span className={`px-grid-chips px-background-primary`}>{params.value}</span>;
  if (params.value === 'REQUESTED')
    return <PaymentStatusChip statusSetting={statusSetting} value={params.value} />;
  const {
    transactionTime,
    details,
    cardType,
    cardLast4Digits,
    cardExpiryMonth,
    cardExpiryYear,
  } = params.data.cardPaymentDetails;

  return (
    <HtmlTooltip
      interactive
      title={
        <div style={{ padding: '5px' }}>
          <h6 className={'px-bold px-fontsize-12px'}>{statusSetting.toolTipTitle} Card Payment</h6>
          <Grid container spacing={2}>
            <Grid item xs={4} className="faded_1 ">
              Date Time:
            </Grid>
            <Grid item xs={8}>
              {transactionTime ? dataFormatting('dateInESTWithTime', transactionTime) : '--'}
            </Grid>
            {params.value === 'FAILURE' && (
              <>
                <Grid item xs={4} className="faded_1">
                  Details:
                </Grid>
                <Grid item xs={6}>
                  {details || '--'}
                </Grid>
              </>
            )}
            <Grid item xs={4} className="faded_1">
              Card Type:
            </Grid>
            <Grid item xs={6}>
              {cardType ? convertToTitleCase(cardType || '') : '--'}
            </Grid>
            <Grid item xs={4} className="faded_1">
              Last 4 Digits:
            </Grid>
            <Grid item xs={6}>
              {cardLast4Digits ? cardLast4Digits.padStart(16, '*') : '--'}
            </Grid>
            <Grid item xs={4} className="faded_1">
              Exp:
            </Grid>
            <Grid item xs={6}>
              {`${cardExpiryMonth ? cardExpiryMonth.toString().padStart(2, 0) : '--'} / ${
                cardExpiryYear || '--'
              }`}
            </Grid>
          </Grid>
          {params.value === 'FAILURE' &&
          params.data.invoiceStatus === 'OVERDUE' &&
          params.data.hasCardOnFile ? (
            <PrimaryButton
              className="margin-top-1"
              onClick={() => retryCardPayment([params.data.invoiceNumber])}>
              Retry Card Payment
            </PrimaryButton>
          ) : (
            <></>
          )}
        </div>
      }>
      <span
        className={`px-grid-chips px-background-${
          statusSetting ? statusSetting.colorClass : 'primary'
        }`}>
        {statusSetting?.label ? statusSetting.label : params.value}
      </span>
    </HtmlTooltip>
  );
};

export default InvoicePaymentStatus;

interface IPaymentStatusChip {
  statusSetting: {
    label: string;
    colorClass: string;
    toolTipTitle: string;
  };
  value: string;
}

const PaymentStatusChip = ({ statusSetting, value }: IPaymentStatusChip): any => {
  return (
    <span
      className={`px-grid-chips px-background-${
        statusSetting ? statusSetting.colorClass : 'primary'
      }`}>
      {statusSetting?.label ? statusSetting.label : value}
    </span>
  );
};
