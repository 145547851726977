import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

import React, { useEffect, useState } from 'react';

import InvoiceStatus from '../ClientDetails/InvoiceStatus';
import { TertiaryButton, PrimaryButton } from '../AtomComponents';
import Dialog from '../Dialog/Dialog';
import MultipleSelected from './MultipleSelected';

import { IInvoice, IInvoiceStatus } from '../../types/IInvoice';

import { dataFormatting } from '../../_lib/lib';
import { NavLink } from 'react-router-dom';

import './InvoiceGrid.scss';
import { eMessageType } from '../../types/IMessageType';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import InvoicePaymentStatus from './InvoicePaymentStatus';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

interface IInvoiceGridProps {
  itemsState: IInvoice[];
  setTotalsAwaitingPayment?: React.Dispatch<React.SetStateAction<number>>;
  view: 'ALL' | 'SINGLE';
  bulkPaidItems?: (data: string[], status: string) => void;
  bulkRetryPayment: (data: string[]) => void;
}

const InvoiceGrid = ({
  itemsState,
  setTotalsAwaitingPayment,
  view,
  bulkPaidItems,
  bulkRetryPayment,
}: IInvoiceGridProps) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();

  const [selectedItems, setSelectedItems] = useState<Array<RowNode>>([]);
  const [bulkInvoiceUpdateDialog, setBulkInvoiceUpdateDialog] = useState(false);

  const [invoiceDialog, setInvoiceDialog] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<IInvoiceStatus>();

  const [loading, setLoading] = useState(false);

  const { openSnackBar, exportData } = useDataService();

  //#region >> grid col def and options >>
  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      setGridAPI(params.api);
    }
  };

  useEffect(() => {
    invoiceStatusUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    if (gridAPI && itemsState) {
      setSelectedItems([]);
      const columnDefs: ColDef[] | ColGroupDef[] = [
        {
          headerName: 'Invoice Number',
          field: 'invoiceNumber',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return <NavLink to={`/invoice/${params.value}`}>{params.value}</NavLink>;
          },
          width: 200,
          minWidth: 200,
          maxWidth: 200,
          checkboxSelection: view === 'ALL',
          headerCheckboxSelection: view === 'ALL',
          headerClass: view === 'ALL' ? 'px-header-invoiceNumber' : '',
        },
        {
          headerName: 'Invoice Date',
          field: 'invoiceDate',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('dateInEST', params.value);
          },
          sort: 'desc',
        },

        {
          headerName: 'Invoice Amount',
          field: 'invoicedAmount',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('currency', params.value);
          },
        },
        {
          headerName: 'Status',
          field: 'invoiceStatus',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return <InvoiceStatus label={params.value} dataId="invoice-status" />;
          },
          width: 120,
        },
        {
          headerName: 'Card Payment',
          field: 'cardPaymentStatus',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => (
            <InvoicePaymentStatus params={params} retryCardPayment={bulkRetryPayment} />
          ),

          width: 200,
          minWidth: 200,
          maxWidth: 200,
        },

        {
          headerName: 'Invoiced Owing',
          field: 'amountOwed',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('currency', params.value);
          },
          width: 140,
        },
        {
          headerName: 'Due Date',
          field: 'dueDate',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'Billing Period Start',
          field: 'billingPeriodStartDate',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'Billing Period End',
          field: 'billingPeriodEndDate',
          sortable: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return dataFormatting('dateInEST', params.value);
          },
        },
        {
          headerName: 'Billing Period Orders in USD',
          field: 'billingPeriodOrdersInUsd',
          sortable: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            return params.value ? (
              <NavLink to={`/billing-period-orders/${params.data.invoiceNumber}`}>
                {dataFormatting('currency', params.value)}
              </NavLink>
            ) : (
              dataFormatting('currency', 0)
            );
          },
        },
      ];
      if (view === 'ALL') {
        columnDefs.splice(
          1,
          0,
          {
            headerName: 'Client',
            field: 'client',
            sortable: true,
            cellRendererFramework: (params: ICellRendererParams) => {
              return !!params.value ? params.value : '--';
            },
          },
          {
            headerName: 'Referrer',
            field: 'referrer',
            sortable: true,
            cellRendererFramework: (params: ICellRendererParams) => {
              return !!params.value ? params.value : '--';
            },
          }
        );
      }
      gridAPI.setColumnDefs(columnDefs);
      gridAPI.setRowData(itemsState);
      if (setTotalsAwaitingPayment) {
        setTotalsAwaitingPayment(0);
        itemsState.forEach((clients: IInvoice) => {
          setTotalsAwaitingPayment((prev) => prev + +clients.amountOwed);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState, gridAPI]);

  const gridOptions: GridOptions = {
    headerHeight: 32,
    defaultColDef: {
      flex: 1,
      resizable: true,
      minWidth: 120,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      cellStyle: {
        wordBreak: 'break-word',
        height: '100%',
      },
    },
    getRowNodeId: function (data: IInvoice) {
      return data.invoiceNumber;
    },
    getRowHeight: () => 54,
    onSelectionChanged: (params: SelectionChangedEvent) => {
      setSelectedItems(params.api.getSelectedNodes());
    },
    animateRows: false,
    singleClickEdit: true,
    suppressAggFuncInHeader: true,
    stopEditingWhenGridLosesFocus: true,
    groupDefaultExpanded: -1,
    suppressMovableColumns: true,
    suppressContextMenu: true,
    rowSelection: 'multiple',
    // grid scroll code
    debounceVerticalScrollbar: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    rowBuffer: 5,
    loadingOverlayComponentFramework: () => (
      <div className="px-grid-loader">
        <div className="px-circular-loader"></div>
        <h3 className="text-center margin-top-1">Loading...</h3>
      </div>
    ),
  };

  const openBulkCancelDialog = () => {
    if (gridAPI) {
      setBulkInvoiceUpdateDialog(true);
      setSelectedItems(gridAPI.getSelectedNodes());
    }
  };

  const purgeBulkPaid = () => {
    setSelectedItems([]);
    setBulkInvoiceUpdateDialog(false);
    gridAPI?.deselectAll();
  };

  const bulkPaid = () => {
    if (!selectedItems?.length) {
      return purgeBulkPaid();
    }
    if (bulkPaidItems && selectedStatus)
      bulkPaidItems(
        selectedItems.map((val: RowNode) => {
          return val.data.invoiceNumber;
        }),
        selectedStatus === 'PAID' ? 'AWAITING_PAYMENT' : 'PAID'
      );

    return purgeBulkPaid();
  };

  const invoiceStatusUpdate = () => {
    const paidStatus = ['PAID', 'VOIDED', 'WRITTEN_OFF'];
    const unpaidStatus = ['AWAITING_PAYMENT', 'OVERDUE'];
    if (selectedItems?.length) {
      let totalInvoice = 0;
      let hasNoInvoiceAmount = false;
      const getAllselectedStatus = new Set(
        selectedItems.map((value: RowNode) => {
          if (value.data.invoicedAmount === 0) {
            hasNoInvoiceAmount = true;
          }
          totalInvoice += value.data.invoicedAmount;
          return value.data.invoiceStatus;
        })
      );
      const selectedStatuses = Array.from(getAllselectedStatus);
      let selected;
      if (selectedStatuses.every((item) => paidStatus.includes(item)) && !hasNoInvoiceAmount) {
        //contain only paid
        selected = 'PAID';
      } else if (
        selectedStatuses.every((item) => unpaidStatus.includes(item)) &&
        !hasNoInvoiceAmount
      ) {
        //contains only unpaid
        selected = 'AWAITING_PAYMENT';
      } else {
        selected = undefined;
      }
      setSelectedStatus(selected as IInvoiceStatus);
      if (selected && totalInvoice)
        setupInvoiceDialog(selected === 'PAID' ? selected : 'AWAITING_PAYMENT', totalInvoice);
    }
  };

  const setupInvoiceDialog = (status: IInvoiceStatus, totalInvoice: number) => {
    const content = (
      <div className="invoice-dialog-content-warpper">
        <div className="invoice-dialog-content px-main-padding px-settings-title">
          <div className="faded_1">Invoices selected</div>
          <div className="px-bold margin-left-3" data-id="invoiceSelected">
            {selectedItems?.length}
          </div>
        </div>
        <div className="invoice-dialog-content px-main-padding px-settings-title">
          <div className="faded_1">Invoice Amount Total:</div>
          <div className="margin-left-3 px-bold" data-id="invoiceTotal">
            {dataFormatting('currency', totalInvoice)}
          </div>
        </div>
      </div>
    );

    setInvoiceDialog({
      title: `Mark As ${status === 'PAID' ? 'Not Paid' : 'Paid'}`,
      content,
    });
  };

  const canShowBulkRetryPayment = () => {
    if (selectedItems?.length) {
      let totalInvoice = 0;
      let hasNoCardInHand = false;
      const getAllpaymentStatus = new Set(
        selectedItems.map((value: RowNode) => {
          totalInvoice += value.data.invoicedAmount;
          if (!value.data.hasCardOnFile) {
            hasNoCardInHand = true;
          }
          return value.data.cardPaymentStatus;
        })
      );
      const getAllselectedStatus = new Set(
        selectedItems.map((value: RowNode) => {
          totalInvoice += value.data.invoicedAmount;
          return value.data.invoiceStatus;
        })
      );
      const selectedStatuses = Array.from(getAllselectedStatus);
      const selectedPaymentStatuses = Array.from(getAllpaymentStatus);
      if (
        selectedStatuses.length === 1 &&
        selectedPaymentStatuses.length === 1 &&
        totalInvoice &&
        selectedStatuses[0] === 'OVERDUE' &&
        selectedPaymentStatuses[0] === 'FAILURE' &&
        !hasNoCardInHand
      ) {
        return true;
      } else return false;
    }
    return false;
  };

  const downloadInvoices = async () => {
    const invoiceNumbers = selectedItems.map((val: RowNode) => {
      return val.data.invoiceNumber;
    });
    try {
      setLoading(true);
      await exportData({
        url: apiToUrlMap.downloadInvoicesAsZip,
        method: 'POST',
        body: { invoiceNumbers },
      });
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong,Unable download invoices',
        eMessageType.error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBulkRetryPayment = () => {
    if (bulkRetryPayment && canShowBulkRetryPayment()) {
      const invoiceNumbers = selectedItems.map((val: RowNode) => {
        return val.data.invoiceNumber;
      });
      bulkRetryPayment(invoiceNumbers);
    }
  };

  return (
    <>
      <LoadingDialog isDialogOpen={loading} />

      {!!invoiceDialog && (
        <Dialog
          title={invoiceDialog.title}
          isDialogOpen={bulkInvoiceUpdateDialog}
          closeDialog={purgeBulkPaid}
          content={invoiceDialog.content}
          actions={
            <>
              <TertiaryButton onClick={purgeBulkPaid} className="margin-left-1">
                cancel
              </TertiaryButton>
              <PrimaryButton onClick={bulkPaid}>SAVE</PrimaryButton>
            </>
          }
        />
      )}

      <div className="cell small-12">
        <InvoiceBulkupdate
          selectedItems={selectedItems}
          canShowBulkRetryPayment={canShowBulkRetryPayment}
          selectedStatus={selectedStatus}
          handleBulkRetryPayment={handleBulkRetryPayment}
          downloadInvoices={downloadInvoices}
          openBulkCancelDialog={openBulkCancelDialog}
        />
      </div>
      <div className="ag-theme-alpine px-height-100vh px-invoice" data-id="invoicetable">
        <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
      </div>
    </>
  );
};

export default InvoiceGrid;

interface IInoviceBulkUpdate {
  selectedItems: any;
  canShowBulkRetryPayment: () => boolean;
  selectedStatus?: string;
  openBulkCancelDialog: () => void;
  handleBulkRetryPayment: () => void;
  downloadInvoices: () => void;
}

const InvoiceBulkupdate = ({
  selectedItems,
  canShowBulkRetryPayment,
  selectedStatus,
  openBulkCancelDialog,
  handleBulkRetryPayment,
  downloadInvoices,
}: IInoviceBulkUpdate) => {
  return selectedItems?.length ? (
    <>
      <span className="margin-right-1 small">{selectedItems?.length} items selected</span>
      {canShowBulkRetryPayment() && (
        <PrimaryButton data-id="bulkDownload" onClick={handleBulkRetryPayment}>
          <MultipleSelected className="margin-right-1" /> Retry Card Payment
        </PrimaryButton>
      )}
      {selectedStatus && (
        <PrimaryButton
          data-id="bulkCancel"
          className="margin-right-1 margin-left-1"
          onClick={openBulkCancelDialog}>
          <MultipleSelected className="margin-right-1" /> Mark as{' '}
          {selectedStatus === 'PAID' ? 'UNPAID' : 'PAID'}
        </PrimaryButton>
      )}
      {selectedItems?.length <= 130 && (
        <PrimaryButton data-id="bulkDownload" onClick={downloadInvoices}>
          <MultipleSelected className="margin-right-1" /> Download invoices
        </PrimaryButton>
      )}
    </>
  ) : (
    <></>
  );
};
