import React from "react";
import { BackButton } from "../../components/AtomComponents";
import { SchemaSync } from "../SchemaSync/SchemaSync";

export const MasterSchema = () => {
  return (
    <>
      <div className="cell small-6 medium-6 px-one-line-data">
        <BackButton />
        <h3 className="small-12 margin-bottom-1">Transactional Schema Sync</h3>
      </div>
      <SchemaSync syncFor="master" />
    </>
  );
};
