import React, { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';
import {
  BackButton,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '../../components/AtomComponents';
import { Autocomplete } from '@material-ui/lab';

import Progress from '../../components/Progress/Progress';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

import useDataService from '../../hooks/useDataService';

import { eMessageType } from '../../types/IMessageType';
import { ITenantList } from '../../types/IUpdateConfig';

import { isEmpty } from '../../_lib/utils';
import apiToUrlMap from '../../ApiMapping';

import './CacheManagement.scss';

const CacheManagement = () => {
  const [tenantList, setTenantList] = useState<ITenantList>({} as ITenantList);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [redisKeys, setRedisKeys] = useState<string[] | null>(null);
  const [cacheKeyPattern, setCacheKeyPattern] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { openSnackBar, fetchUrl } = useDataService();

  const history = useHistory();

  const getAllTenant = async () => {
    try {
      const result = await fetchUrl('GET', '/update-config/list-tenants');
      setTenantList(result);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to fetch tenants',
        eMessageType.error
      );
      history.push('/dashboard');
    }
  };

  const getRedisKeys = async () => {
    try {
      const keys = await fetchUrl('GET', apiToUrlMap.getRedisCache);
      setRedisKeys(keys);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to fetch redis cache keys',
        eMessageType.error
      );
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    getAllTenant();
    getRedisKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTenantChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSelectedTenant(e.target.value);
    setCacheKeyPattern('');
  };

  const validateCacheKeyPattern = () => {
    const regex = /.+\|.+/;
    return cacheKeyPattern.match(regex);
  };

  const evictCacheEntry = async () => {
    try {
      setIsLoading(true);
      if (!validateCacheKeyPattern()) {
        throw new Error('Invalid cacheKeyPattern');
      }
      const res = await fetchUrl('PUT', apiToUrlMap.evictRedisCache, {
        body: { cacheKeyPattern },
      });
      openSnackBar(res.message, eMessageType.success);
      await getRedisKeys();
      setCacheKeyPattern('');
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to Evict Cache Entry',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const cancelChanges = () => {
    setSelectedTenant('');
    setCacheKeyPattern('');
  };

  const filterOptions = (options: string[]) => {
    const newOptions: string[] = [];
    options.forEach((option) => {
      if (
        option.startsWith(selectedTenant) &&
        option.startsWith(cacheKeyPattern.replace('*', ''))
      ) {
        newOptions.push(option);
      }
    });
    return newOptions;
  };

  if (isEmpty(tenantList) || !redisKeys) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }

  return (
    <div className="px-update-config">
      <div className="cell small-6 medium-6 px-one-line-data">
        <LoadingDialog isDialogOpen={isLoading} />
        <BackButton />
        <h3 className="small-12">Cache Management</h3>
      </div>
      <div className="grid-x ">
        <Prompt
          when={!!cacheKeyPattern}
          message={'You have some unsaved changes. OK to forget these changes and leave the page?'}
        />
        <div className="cell auto margin-bottom-1 action-button">
          <SecondaryButton className="margin-top-1" onClick={cancelChanges}>
            CANCEL CHANGES
          </SecondaryButton>
          <PrimaryButton
            className="margin-left-1 margin-top-1"
            disabled={!cacheKeyPattern}
            onClick={evictCacheEntry}>
            Evict Cache Entry
          </PrimaryButton>
        </div>
      </div>
      <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
        <TextField
          select
          value={selectedTenant}
          onChange={handleTenantChange}
          label="Tenant Name"
          className="text-field"
          fullWidth
          disabled={Object.keys(tenantList.tenants).length <= 1}>
          {tenantList.tenants?.map((tenant: string) => (
            <MenuItem key={tenant} value={tenant}>
              {tenant}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
        <Autocomplete
          value={cacheKeyPattern}
          options={redisKeys}
          clearOnBlur={false}
          filterOptions={filterOptions}
          disabled={!selectedTenant}
          fullWidth
          color="primary"
          getOptionLabel={(option: string) => option}
          renderInput={(params) => (
            <TextField {...params} label={'Redis Keys'} variant="outlined" />
          )}
          onInputChange={(event, newInputValue) => {
            setCacheKeyPattern(newInputValue);
          }}
        />
      </div>
    </div>
  );
};

export default CacheManagement;
