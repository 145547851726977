import React from 'react';

interface IImageProps {
  className: string;
}

export const PreviewIcon = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24">
      <g>
        <rect fill="none" height="24" width="24" />
        <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z" />
      </g>
    </svg>
  );
};

export const FrontHand = (props: any) => {
  return (
    <svg {...props} className="MuiSvgIcon-root" viewBox="0 0 512.000000 512.000000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path d="M2463 5105 c-29 -8 -72 -24 -94 -36 -53 -27 -131 -104 -158 -157 -28 -54 -51 -145 -51 -199 0 -24 -2 -43 -4 -43 -2 0 -25 10 -52 22 -64 29 -195 31 -270 5 -113 -39 -202 -124 -245 -234 -19 -50 -23 -82 -27 -225 -2 -93 -5 -168 -7 -168 -1 0 -24 10 -51 22 -64 29 -195 31 -270 5 -113 -39 -201 -123 -246 -234 l-23 -58 -3 -1125 c-2 -705 1 -1163 7 -1227 38 -379 196 -716 462 -982 431 -431 1063 -579 1639 -384 474 160 843 529 1003 1003 83 246 88 315 85 1205 -3 728 -3 741 -24 786 -64 138 -202 202 -366 169 -57 -11 -164 -56 -195 -81 -10 -9 -13 97 -15 513 -3 521 -3 523 -26 581 -45 111 -133 195 -246 234 -75 26 -206 24 -270 -5 -27 -12 -50 -22 -51 -22 -2 0 -5 75 -7 168 -5 182 -16 230 -75 315 -87 125 -275 193 -420 152z m203 -216 c19 -12 47 -40 62 -62 l27 -41 5 -1133 5 -1133 26 -25 c14 -14 41 -28 61 -31 30 -5 41 -1 69 24 l34 30 5 835 5 834 27 39 c46 65 91 89 168 89 55 0 73 -4 107 -27 22 -15 51 -44 64 -65 l24 -38 3 -1091 2 -1090 -106 -13 c-236 -27 -440 -125 -600 -286 -93 -95 -154 -186 -154 -232 0 -49 45 -93 95 -93 41 0 66 21 158 135 153 188 370 284 644 285 93 0 104 2 130 24 l28 24 5 494 c5 478 6 494 26 539 72 156 303 238 359 128 22 -41 22 -1432 0 -1593 -41 -309 -174 -577 -399 -802 -409 -409 -1002 -524 -1538 -298 -386 163 -695 524 -801 934 -46 178 -48 241 -45 1426 l3 1111 27 39 c46 65 91 89 168 89 77 0 122 -24 168 -89 l27 -39 5 -633 5 -634 26 -25 c14 -14 41 -28 61 -31 30 -5 41 -1 69 24 l34 30 5 934 5 934 25 37 c14 21 43 50 64 65 33 23 50 27 106 27 77 0 122 -24 168 -89 l27 -39 5 -933 5 -934 26 -25 c14 -14 41 -28 61 -31 30 -5 41 -1 69 24 l34 30 5 1135 5 1134 25 36 c31 43 71 76 108 88 43 14 129 3 168 -22z" />
      </g>
    </svg>
  );
};

export const MailIcon = ({ className }: IImageProps) => {
  return (
    <svg
      className={`MuiSvgIcon-root ${className}`}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true">
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
    </svg>
  );
};

export const ImportIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      style={{ fill: 'white' }}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </svg>
  );
};
